// @ts-nocheck
import * as Formsy from 'formsy-react';
import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { Button, Confirm, Image, Popup } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import trashIconW from '../../assets/images/White/trash.svg';
import { IUser } from '../../models';
import services from '../../services';
import ErrorLabel from '../error-label/ErrorLabel';
import { NoResults } from '../no-results-placeholder/NoResults';
import QuestionFormsy from './QuestionFormsy';

interface Props {
  questions: object[];
  activeName: string;
  listId: number;
  reloadQuestions: () => void;
  setFirstAsActive: () => void;
  setActiveQuestions: (id: number) => void;
  user: IUser;
}

interface State {
  questions: object[];
  name: string;
  nameInvalid: boolean;
  showConfirm: boolean;
}

class ManageQuestions extends React.Component<Props, State> {
  public state = {
    questions: [],
    name: '',
    nameInvalid: false,
    showConfirm: false,
  };

  public componentDidMount() {
    this.setState({ questions: this.props.questions, name: this.props.activeName });
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.questions !== this.props.questions) {
      this.setState({ questions: this.props.questions, name: this.props.activeName });
    }
  }

  public deleteQuestion = (id: number) => {
    const { questions } = this.state;
    const questIndex = questions.findIndex((el: any) => el.id === id);
    questions.splice(questIndex, 1);
    const array = questions.map((el: any, index: number) => {
      el.order = index;
      return el;
    });
    this.setState({ questions: [] }, () => this.setState({ questions: array }));
  };

  public deleteList = async () => {
    const response = await services.api.settings.deleteQuestionnarie(this.props.listId);
    if (response.isSuccess) {
      this.props.setFirstAsActive();
      toast.dismiss();
      toast.success(`Questionnaire ${this.state.name} was successfully deleted!`);
      this.props.reloadQuestions();
    } else {
      toast.dismiss();
      toast.error(response.data.errors[0].msg);
    }
  };

  public editQuestion = (id: number, question: object) => {
    const { questions } = this.state;
    const questIndex = questions.findIndex((el: any) => el.id === id);
    questions[questIndex] = question;
    this.setState({ questions });
  };

  public getQuestion = (question: any) => {
    const { questions } = this.state;
    questions.push(question);
    this.setState({ questions });
  };

  public onDragEnd = result => {
    const { destination, source, draggableId } = result;
    const { questions } = this.state;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const element = questions[source.index];
    questions.splice(source.index, 1);
    questions.splice(destination.index, 0, element);

    for (let i = 0; i < questions.length; i++) {
      questions[i].order = i;
    }

    this.setState({ questions });
  };

  public render() {
    const errorLabel = <ErrorLabel />;

    Formsy.addValidationRule('hasQuestions', (values: any, value: any) => {
      if (!this.state.nameInvalid) {
        return true;
      } else {
        return false;
      }
    });

    return (
      <React.Fragment>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className="manage-questions-header">
            <div>List</div>
            <div className="questions-actions-buttons">
              <Popup
                trigger={
                  <Button
                    disabled={
                      this.props.user.role === 'Read Only Office Admin' ||
                      this.state.questions.length === 0
                    }
                    primary={true}
                    onClick={() => this.setState({ showConfirm: true })}
                  >
                    <Image src={trashIconW} />
                  </Button>
                }
                size="mini"
                basic={true}
                content="Delete"
              />

              <Button
                form="quest-form"
                style={{ width: '115px' }}
                primary={true}
                disabled={
                  this.props.user.role === 'Read Only Office Admin' ||
                  this.state.questions.length === 0
                }
                onClick={() => {
                  const inputBuff = this.state.name;
                  this.setState({ name: '' }, () => this.setState({ name: inputBuff }));

                  if (this.state.questions.length === 0) {
                    this.setState({ nameInvalid: true });
                  } else {
                    this.setState({ nameInvalid: false });
                  }
                }}
              >
                SAVE
              </Button>
            </div>
          </div>
          {this.state.questions.length !== 0 ? (
            <div className="create-questionnarie-container">
              <Form
                id="quest-form"
                onValidSubmit={async (model: any) => {
                  const finalQuestions = this.state.questions.map((el: any) => {
                    delete el.id;
                    return el;
                  });
                  const obj = Object.assign(
                    {},
                    { name: model.list_name.trim() },
                    { questions: finalQuestions }
                  );
                  const response = await services.api.settings.editQuestionnarie(
                    this.props.listId,
                    obj
                  );

                  if (response.isSuccess) {
                    toast.dismiss();
                    toast.success(
                      `Questionnaire ${model.list_name.trim()} was successfully updated!`
                    );
                    this.props.setActiveQuestions(this.props.listId);
                    this.props.reloadQuestions();
                  } else {
                    toast.dismiss();
                    toast.error(response.data.errors[0].msg);
                  }
                }}
              >
                <div className="name-input-container">
                  <div>Name</div>
                  <Form.Input
                    name="list_name"
                    maxLength={60}
                    disabled={this.props.user.role === 'Read Only Office Admin'}
                    placeholder="Please Enter..."
                    required={true}
                    instantValidation={false}
                    value={this.state.name}
                    validations={{
                      hasQuestions: true,
                      isOnlySpaces: true,
                    }}
                    validationErrors={{
                      isDefaultRequiredValue: '* Name is required field.',
                      isOnlySpaces: '* Name is required field.',
                      hasQuestions: '* At least one question is required to be defined.',
                    }}
                    errorLabel={errorLabel}
                    onChange={(e: any) => this.setState({ name: e.target.value })}
                  />
                </div>
                <Droppable droppableId={`${this.props.listId}`}>
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {this.state.questions
                        .sort((a: any, b: any) => a.order - b.order)
                        .map((question: any, index: number) => (
                          <QuestionFormsy
                            name={'question'}
                            key={index}
                            question={question}
                            deleteQuestion={this.deleteQuestion}
                            editQuestion={this.editQuestion}
                            index={index}
                            user={this.props.user}
                          />
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Form>

              <div className="add-question">
                <Button
                  disabled={this.props.user.role === 'Read Only Office Admin'}
                  type="button"
                  onClick={() =>
                    this.getQuestion({
                      text: '',
                      answerType: 'Yes/No',
                      order: this.state.questions.length,
                      id: this.state.questions.length - 1,
                    })
                  }
                >
                  + ADD QUESTION
                </Button>
              </div>
            </div>
          ) : (
            <NoResults />
          )}
        </DragDropContext>
        <Confirm
          size="tiny"
          closeIcon={
            <Image
              src={closeIcon}
              className="icon"
              onClick={() => this.setState({ showConfirm: false })}
            />
          }
          open={this.state.showConfirm}
          onCancel={() => this.setState({ showConfirm: false })}
          header="Confirm"
          content={`Are you sure you want to delete questionnaire ${this.state.name}?`}
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={() => {
            this.deleteList();
            this.setState({ showConfirm: false });
          }}
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </React.Fragment>
    );
  }
}

export default ManageQuestions;
