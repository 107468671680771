import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { IUser } from '../../models';
import { AdjusterUser } from '../../models/adjuster';
import services from '../../services';

interface Props {
  getSubmitRef: (ref: any) => void;
  user: AdjusterUser;
  admin: IUser;
}

interface State {
  experiences: object[];
  skills: object[];
  losses: object[];
  languages: string[];
  equipment: string[];
  languageOptions: object[];
  equipmentOptions: object[];
  climbing: boolean;
}

class ProfileProfessionalSkills extends React.Component<Props, State> {
  public state = {
    experiences: [],
    skills: [],
    losses: [],
    languages: [],
    languageOptions: [],
    equipment: [],
    equipmentOptions: [],
    climbing: true,
  };

  public sumbitRef = React.createRef();

  public componentDidMount = async () => {
    this.props.getSubmitRef(this.sumbitRef);

    const response = await services.api.account.getSettingsData('language');
    if (response.isSuccess) {
      const languageOptions = response.data.list.map((el: any) => ({
        label: el.name,
        checked: this.props.user.languages.includes(el.name),
      }));
      this.setState({ languageOptions });
    } else {
      console.log(response);
    }

    const response2 = await services.api.account.getSettingsData('equipment');
    if (response2.isSuccess) {
      const equipmentOptions = response2.data.list.map((el: any) => ({
        label: el.name,
        checked: this.props.user.equipments.includes(el.name),
      }));
      this.setState({ equipmentOptions });
    } else {
      console.log(response2);
    }

    this.setState({
      experiences:
        this.props.user.experiences.length === 0 ? [{ id: 0 }] : this.props.user.experiences,
      skills:
        this.props.user.softwareSkills.length === 0 ? [{ id: 0 }] : this.props.user.softwareSkills,
      losses:
        this.props.user.expertise.length === 0
          ? [
              {
                id: 0,
                type: '',
                commercialCATClaims: 0,
                residentialCATClaims: 0,
                commercialDailyClaims: 0,
                residentialDailyClaims: 0,
              },
            ]
          : this.props.user.expertise,
      climbing: this.props.user.climbRoof,
    });
  };

  public render() {
    const { user } = this.props;
    return (
      <Grid stackable={true} style={{ width: '100%' }} className="admin-adjuster-skills-page">
        <Grid.Column width={11} style={{ maxWidth: '700px' }}>
          <Form onValidSubmit={async (model: any) => console.log(model)}>
            {this.state.experiences.map((el: any, index: number) => (
              <div className="input-field-container" key={index}>
                <div className="input-field-label">{index === 0 ? 'Experience' : null}</div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <Form.Input
                    name="form-experience"
                    value={el.name}
                    readOnly={true}
                    className="form-input readonly-input experience-description"
                  />
                  <Form.Input
                    name="form-experience-years"
                    value={el.years}
                    icon={<Icon>YEARS</Icon>}
                    readOnly={true}
                    className="form-input-short form-input-years readonly-input"
                  />
                </div>
              </div>
            ))}

            <div className="input-field-container">
              <div className="input-field-label">Languages Spoken</div>
              <div style={{ width: '100%' }}>
                {user.languages.map((el: any, index: number) => {
                  return (
                    <Form.Input
                      key={index}
                      name="form-language"
                      value={el}
                      readOnly={true}
                      className="form-input readonly-input"
                    />
                  );
                })}
              </div>
            </div>
            {this.state.skills.map((el: any, index: number) => (
              <div className="input-field-container" key={index}>
                <div className="input-field-label">{index === 0 ? 'Software Skills' : null}</div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <Form.Input
                    name="form-experience"
                    value={el.name}
                    readOnly={true}
                    className="form-input readonly-input experience-description"
                  />
                  <Form.Input
                    name="form-experience-years"
                    value={el.years}
                    icon={<Icon>YEARS</Icon>}
                    readOnly={true}
                    className="form-input-short form-input-years readonly-input"
                  />
                </div>
              </div>
            ))}

            <div className="input-field-container">
              <div className="input-field-label">Equipment</div>
              <div style={{ width: '100%' }}>
                {user.equipments.map((el: any, index: number) => {
                  return (
                    <Form.Input
                      key={index}
                      name="form-equipment"
                      value={el}
                      readOnly={true}
                      className="form-input readonly-input"
                    />
                  );
                })}
              </div>
            </div>
            {this.state.losses.map((el: any, index: number) => (
              <div className="input-field-container" key={index}>
                <div className="input-field-label">{index === 0 ? 'Adjuster Expertise' : null}</div>
                <div className="expertise-group">
                  <div
                    style={{
                      alignSelf: 'flex-end',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      height: '100%',
                    }}
                  >
                    {index === 0 && (
                      <label style={{ fontSize: '12px', color: '#8497b0' }}>Loss Type</label>
                    )}
                    <Form.Input
                      key={index}
                      name="form-expertise"
                      value={el.type}
                      readOnly={true}
                      className="form-input-short readonly-input"
                    />
                  </div>
                  <div style={{ alignSelf: 'flex-start' }}>
                    {index === 0 && (
                      <label style={{ fontSize: '12px', color: '#8497b0' }}>
                        #Residential CAT Claims
                      </label>
                    )}
                    <Form.Input
                      key={index}
                      name="form-residentialCATClaims"
                      value={el.residentialCATClaims}
                      readOnly={true}
                      className="form-input-short readonly-input"
                    />
                  </div>
                  <div style={{ alignSelf: 'flex-start' }}>
                    {index === 0 && (
                      <label style={{ fontSize: '12px', color: '#8497b0' }}>
                        #Commercial CAT Claims
                      </label>
                    )}
                    <Form.Input
                      key={index}
                      name="form-commercialCATClaims"
                      value={el.commercialCATClaims}
                      readOnly={true}
                      className="form-input-short readonly-input"
                    />
                  </div>
                  <div style={{ alignSelf: 'flex-start' }}>
                    {index === 0 && (
                      <label style={{ fontSize: '12px', color: '#8497b0' }}>
                        #Residential Daily Claims
                      </label>
                    )}
                    <Form.Input
                      key={index}
                      name="form-residentialDailyClaims"
                      value={el.residentialDailyClaims}
                      readOnly={true}
                      className="form-input-short readonly-input"
                    />
                  </div>
                  <div style={{ alignSelf: 'flex-start' }}>
                    {index === 0 && (
                      <label style={{ fontSize: '12px', color: '#8497b0' }}>
                        #Commercial Daily Claims
                      </label>
                    )}
                    <Form.Input
                      key={index}
                      name="form-commercialDailyClaims"
                      value={el.commercialDailyClaims}
                      readOnly={true}
                      className="form-input-short readonly-input"
                    />
                  </div>
                </div>
              </div>
            ))}

            <div className="input-field-container">
              <div className="input-field-label" style={{ paddingRight: '25px' }}>
                Are you willing to climb roof greater that 8/12 pitch without a roof assist?
              </div>
              {/* <div style={{ display: 'flex', width: '100%' }}>
                <div className="toggle-block" style={{ width: '75px' }}>
                  <Button
                    type="button"
                    disabled={true}
                    style={{ width: '75px' }}
                    className="yes-btn"
                  >
                    {user.climbRoof ? 'YES' : 'NO'}
                  </Button>
                </div>
              </div> */}
              <div className="climbing-toogle">
                <div className="toggle-block">
                  <Button
                    type="button"
                    className={user.climbRoof ? 'yes-btn' : 'no-btn'}
                    disabled={true}
                    // onClick={() => this.setState({ climbing: true })}
                  >
                    YES
                  </Button>
                  <Button
                    type="button"
                    className={!user.climbRoof ? 'yes-btn' : 'no-btn'}
                    disabled={true}
                    // onClick={() => this.setState({ climbing: false })}
                  >
                    NO
                  </Button>
                </div>
              </div>
            </div>

            <div className="input-field-container">
              <div className="input-field-label" style={{ paddingRight: '25px' }}>
                How far away from your base location are you willing to accept claims (one way)
                without billing for mileage?
              </div>
              <div className="miles-input">
                <Form.Input
                  name="form-milesRange"
                  value={user.milesRange}
                  icon={<Icon>MILES</Icon>}
                  readOnly={true}
                  className="form-input-short form-input-years readonly-input"
                />
              </div>
            </div>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

export default ProfileProfessionalSkills;
