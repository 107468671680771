// @ts-nocheck
import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { Button, Grid, Image } from 'semantic-ui-react';
import approvedPaperworkIcon from '../../assets/images/icn-paperwork-approved.svg';
import pendingPaperworkIcon from '../../assets/images/icn-paperwork-pending.svg';
import { IUser } from '../../models';
import { AdjusterUser } from '../../models/adjuster';
import services from '../../services';
import AddPaperwork from '../register-layout/AddPaperwork';
import EditPaperwork from '../register-layout/EditPaperwork';
import PaperworkItem from '../register-layout/PaperworkItem';
import { LoadingSpiner } from './../loading-spinner/LoadingSpinner';

interface Props {
  getSubmitRef: (ref: any) => void;
  user: AdjusterUser;
  admin: IUser;
  download: (file: any) => void;
}

interface State {
  paperwork: object[];
  paperworkId: any;
  showEditModal: boolean;
  paperworkCompleted: boolean;
}

class ProfilePaperwork extends React.Component<Props, State> {
  public state = {
    paperwork: [],
    paperworkId: 0,
    showEditModal: false,
    paperworkCompleted: false,
  };

  public componentDidMount = () => {
    this.listPaperwork();
  };

  public deletePaperwork = async (id: any) => {
    const resp = await trackPromise(services.api.adjuster.deletePaperwork(id), 'paperwork-area');
    if (resp.isSuccess) {
      toast.dismiss();
      toast.success('Paperwork successfully deleted!');
      this.setState(prevState => ({
        paperwork: prevState.paperwork.filter(p => (p as any).id !== id),
      }));
    } else {
      console.log(resp);
    }
  };

  public showEditModal = (show: boolean, id?: number) => {
    if (id !== undefined) {
      this.setState({ paperworkId: id }, () => this.setState({ showEditModal: show }));
    } else {
      this.setState({ showEditModal: show });
    }
  };

  public listPaperwork = async () => {
    const response = await trackPromise(
      services.api.admin.listAdjusterPaperwork(this.props.user.id),
      'paperwork-area'
    );
    if (response.isSuccess) {
      this.setState({
        paperwork: response.data.paperworks.filter(paperwork => paperwork.files.length),
        paperworkCompleted: response.data.completed,
      });
    } else {
      console.log(response);
      toast.dismiss();
      toast.error(
        response.data.errors ? response.data.errors[0].msg : `Error ${response.data.status}`
      );
    }
  };

  public handlePaperworkFiles = async () => {
    const { user } = this.props;
    const { paperworkCompleted } = this.state;
    const response = await services.api.adjuster.completeAllPaperworks(
      user.id,
      !paperworkCompleted
    );
    if (response.isSuccess) {
      this.listPaperwork();
    } else {
      console.log(response);
    }
  };

  public render() {
    const { user, admin } = this.props;
    const { paperworkCompleted } = this.state;
    return (
      <Grid stackable={true} style={{ width: '100%' }} className="licenses-grid">
        <div className="paperwork-notification-header">
          <div className="ppw-info-block">
            <Image src={paperworkCompleted ? approvedPaperworkIcon : pendingPaperworkIcon} />
            {paperworkCompleted ? 'Completed' : 'Pending'} Paperwork Files
          </div>
          {user.status === 'Active' && admin.role !== 'Read Only Office Admin' && (
            <Button
              onClick={this.handlePaperworkFiles}
              className={paperworkCompleted ? 'primary' : 'finish-btn'}
            >
              {paperworkCompleted ? 'Edit' : 'Finish'}
            </Button>
          )}
        </div>
        <LoadingSpiner area="paperwork-area" />
        <Grid.Row columns={1} only="computer">
          <Grid.Column className="licenses-column">
            <Form>
              {this.state.paperwork.length === 0 ? (
                <Form.Input
                  name="paperwork"
                  placeholder="Nothing Added"
                  instantValidation={false}
                  className="form-input readonly-input"
                  readOnly={true}
                />
              ) : (
                <div>
                  {this.state.paperwork.map((el: any, index: number) => (
                    <PaperworkItem
                      item={el}
                      key={index}
                      id={index}
                      editLicense={this.showEditModal}
                      deleteLicense={this.deletePaperwork}
                      download={this.props.download}
                      admin={this.props.admin}
                      user={this.props.user}
                      paperworkCompleted={paperworkCompleted}
                    />
                  ))}
                </div>
              )}
              <Form.Input
                name="paperworkFile"
                hidden={true}
                style={{ display: 'none' }}
                readOnly={true}
              />
            </Form>
            {this.props.admin.role !== 'Read Only Office Admin' &&
              !this.state.paperworkCompleted && (
                <AddPaperwork user={user} getPaperwork={this.listPaperwork} />
              )}
            <EditPaperwork
              edit={this.editPaperwork}
              showModal={this.showEditModal}
              list={this.listPaperwork}
              show={this.state.showEditModal}
              item={this.state.paperwork[this.state.paperworkId]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default ProfilePaperwork;
