// @ts-nocheck
import isEqual from 'lodash/isEqual';
import React from 'react';
import { Button, Confirm, Image, Popup } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import downloadIcon from '../../assets/images/Blue/download.svg';
import editIcon from '../../assets/images/Blue/edit.svg';
import approvedPaperworkIcon from '../../assets/images/icn-paperwork-approved.svg';
import pendingPaperworkIcon from '../../assets/images/icn-paperwork-pending.svg';
import { IUser } from '../../models';
import services from '../../services';
import * as datesHelper from '../../utils/datesHelper';
import { sizeToHumanReadable } from '../../utils/helper';
import { FilePreview } from '../file-preview/FilePreview';

interface Props {
  item: object;
  editLicense: (show: boolean, id?: number) => void;
  deleteLicense: (id: number) => void;
  id: number;
  download: (file: any) => void;
  admin: IUser;
  user: any;
  paperworkCompleted: boolean;
}

interface State {
  showConfirm: boolean;
  image: any;
}

class PaperworkItem extends React.Component<Props, State> {
  public state = {
    showConfirm: false,
    image: '',
  };

  public componentDidMount = () => {
    this.setPreview();
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (!isEqual(prevProps, this.props)) {
      this.setPreview();
    }
  };

  public setPreview = async () => {
    const { item } = this.props;
    const imageTypes = ['image/jpg', 'image/png', 'image/jpeg'];

    if (item.files.length && imageTypes.includes(item.files[0].type)) {
      const resp = await services.api.account.downloadFile(item.files[0].key);
      if (resp.isSuccess) {
        this.setState({ image: resp.data.downloadURL });
      }
    }
  };

  public render() {
    const { item, paperworkCompleted } = this.props;
    const { image } = this.state;
    if (!item.files.length) {
      return null;
    }
    return (
      <>
        <div className="license-item-container">
          <div className="licenses-file-preview">
            <FilePreview type={item.files[0].type} src={image} />
          </div>
          <div className="license-item-info">
            <div
              style={{ fontSize: '16px', fontWeight: 'bold' }}
              dangerouslySetInnerHTML={{ __html: item.type }}
            />
            <div className="license-date-container">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontSize: '13px', marginRight: '10px' }}>Updated date</div>
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {datesHelper.parseDateFromApi(item.updatedAt)}
                </div>
              </div>
              {item.runDate && item.type === 'BG Check Completed' && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ fontSize: '13px' }}>Run Date</div>
                  <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {datesHelper.parseDateFromApi(item.runDate)}
                  </div>
                </div>
              )}
            </div>
            <div style={{ fontSize: '13px' }}>{sizeToHumanReadable(item.files[0].size)}</div>
          </div>
          <div className="btn-icn-container-paperwork">
            <Image src={item.allFilesReceived ? approvedPaperworkIcon : null} />
            <div className="buttons-container">
              <Popup
                trigger={
                  <Button
                    type="button"
                    className="iconic-button-custom"
                    onClick={() => this.props.download({ id: item.id, name: item.type })}
                  >
                    <Image src={downloadIcon} />
                  </Button>
                }
                size="mini"
                basic={true}
                content="Download"
              />
              {this.props.admin.role !== 'Read Only Office Admin' && !paperworkCompleted && (
                <Popup
                  trigger={
                    <Button
                      type="button"
                      className="iconic-button-custom"
                      onClick={() => this.setState({ showConfirm: true })}
                    >
                      <Image src={closeIcon} />
                    </Button>
                  }
                  size="mini"
                  basic={true}
                  content="Delete"
                />
              )}
              {this.props.admin.role !== 'Read Only Office Admin' && !paperworkCompleted && (
                <Popup
                  trigger={
                    <Button
                      type="button"
                      className="iconic-button-custom"
                      onClick={() => this.props.editLicense(true, this.props.id)}
                    >
                      <Image src={editIcon} />
                    </Button>
                  }
                  size="mini"
                  basic={true}
                  content="Edit"
                />
              )}
            </div>
          </div>
        </div>
        <Confirm
          size="tiny"
          closeIcon={
            <Image
              src={closeIcon}
              className="icon"
              onClick={() => this.setState({ showConfirm: false })}
            />
          }
          open={this.state.showConfirm}
          onCancel={() => this.setState({ showConfirm: false })}
          header="Confirm"
          content={'Are you sure you want to delete this paperwork file?'}
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={() => {
            this.props.deleteLicense(this.props.item.id);
            this.setState({ showConfirm: false });
          }}
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </>
    );
  }
}

export default PaperworkItem;
