// @ts-nocheck
import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import services from '../../services';
import * as actions from './actions';
import * as constants from './constants';

export default function*() {
  yield takeEvery(constants.GET_ADJUSTERS_LIST, getAdjustersList);
  yield takeEvery(constants.GET_MAP_ADJUSTERS, getMapAdjusters);
  yield takeEvery(constants.GET_ADMIN_ADJUSTERS, getAdminAdjusters);
}

export function* getAdjustersList(action: ActionType<typeof actions.getAdjustersList>) {
  const fetchList = yield call(
    services.api.adjuster.fetchAdjusterListCustom,
    action.payload ? action.payload.filterOptions : undefined
  );
  yield put(
    actions.submitAdjustersList(
      fetchList.data.list,
      fetchList.data.count,
      fetchList.data.eventLocation
    )
  );
  if (fetchList.isError) {
    toast.dismiss();
    toast.error(fetchList.data.errors ? fetchList.data.errors[0].msg : `Error ${fetchList.status}`);
    console.log(fetchList);
  }
}

export function* getMapAdjusters(action: ActionType<typeof actions.getMapAdjusters>) {
  try {
    const adjusters = yield call(services.api.adjuster.fetchMapAdjusters, action.payload.params);
    yield put(actions.submitAdjustersMap(adjusters));
  } catch (err) {
    console.error('fetchAllAdjustersSaga err', err);
  }
}

export function* getAdminAdjusters(action: ActionType<typeof actions.getAdminAdjusters>) {
  try {
    const adjusters = yield call(services.api.adjuster.fetchAdminAdjusters, action.payload.params);
    yield put(actions.setAdminAdjusters(adjusters));
  } catch (err) {
    console.error('fetchAllAdjustersSaga err', err);
  }
}
