// @ts-nocheck
import moment from 'moment';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Dropdown, Image, Popup } from 'semantic-ui-react';
import noteIcon from '../../assets/images/Blue/add-note.svg';
import removeUserIcon from '../../assets/images/Blue/remove-user.svg';
import selectUserIcon from '../../assets/images/Blue/select-user.svg';
import noAvatar from '../../assets/images/no-avatar.svg';
import arrowIcon from '../../assets/images/White/arrow-r.svg';
import { uploads } from '../../config';
import { accountActions } from '../../features/account';
import { standbyActions } from '../../features/standby';
import { IUser } from '../../models';
import services from '../../services';
import AddNoteModal from '../adjuster-list/AddNoteModal';
import ContactedModal from '../standby-layout/modals/ContactedModal';
import UserStatusLabel from '../standby-layout/UserStatusLabel';

interface Props {
  user: IUser;
  adjuster: object;
  standBy: object;
  match: any;
  fetchAdjusterDataAttempt: typeof accountActions.fetchAdjusterDataAttempt;
}

interface State {
  adjuster: object;
  activeList: any;
  userStatus: string;
  showNoteModal: boolean;
}

class MessageUserHeader extends React.Component<Props, State> {
  public state = {
    adjuster: {},
    activeList: { id: 0 },
    showNoteModal: false,
    userStatus: 'contacted',
  };

  public showNoteModal = (show: boolean) => {
    this.setState({ showNoteModal: show });
  };
  public onNavigate = (standById: string) => {
    services.router.goto(`/stand-by-lists/${standById}`);
  };

  public render() {
    const { adjuster, user, standBy } = this.props;
    return (
      <>
        {adjuster && (
          <div className="standby-user-info-container">
            <div className="user-main-info-content">
              <Image
                circular={true}
                src={adjuster.avatar ? uploads.s3BucketAddress + adjuster.avatar : noAvatar}
                avatar={true}
              />
              <div style={{ marginLeft: '18px', width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 700,
                    fontSize: '22px',
                    marginBottom: '1rem',
                    width: '100%',
                  }}
                >
                  {adjuster.preferredName
                    ? adjuster.preferredName
                    : adjuster.firstName
                    ? adjuster.firstName
                    : `${Object.entries(adjuster).length ? 'Anonymous' : ''}`}{' '}
                  {adjuster.lastName} <UserStatusLabel adjuster={adjuster} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ marginBottom: '0.1rem', fontSize: '13px' }}>
                      {adjuster.positions ? adjuster.positions.join(', ') : ''}
                    </div>
                    <div style={{ fontSize: '13px' }}>
                      {adjuster.city && `${adjuster.city}, ${adjuster.state} /${' '}`}
                      {adjuster.languages ? adjuster.languages.join(', ') : ''}
                    </div>
                  </div>
                  {adjuster && adjuster.firstName && (
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          textAlign: 'right',
                        }}
                      >
                        {standBy && (
                          <div>
                            {'Standby: '}
                            <Link to={`/stand-by-lists/${standBy.id}`} className="msg-item-link">
                              {standBy.title}
                            </Link>
                          </div>
                        )}
                        <div style={{ marginBottom: '0.1rem', fontSize: '13px', fontWeight: 700 }}>
                          Sent On: {moment(adjuster.updatedAt).format('MM/DD/YYYY, hh:mm A ')}
                        </div>
                        <div style={{ fontSize: '13px' }}>{adjuster.email}</div>
                      </div>
                      <div style={{ display: 'flex', marginLeft: '1rem', alignItems: 'flex-end' }}>
                        {user.role !== 'Read Only Office Admin' && (
                          <Popup
                            trigger={
                              <Button
                                onClick={() => this.setState({ showNoteModal: true })}
                                className="sbl-action-button"
                              >
                                <Image src={noteIcon} />
                              </Button>
                            }
                            basic={true}
                            size="mini"
                            content="Add Note"
                          />
                        )}
                        <Popup
                          trigger={
                            <Button
                              as={Link}
                              to={`/adjusters/${adjuster.id}`}
                              primary={true}
                              className="sbl-action-button"
                            >
                              <Image src={arrowIcon} />
                            </Button>
                          }
                          basic={true}
                          size="mini"
                          content="View Profile"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <AddNoteModal
          id={adjuster.id}
          avatar={adjuster ? adjuster.avatar : noAvatarIcon}
          username={
            adjuster
              ? `${adjuster.preferredName ? adjuster.preferredName : adjuster.firstName} ${
                  adjuster.lastName
                }`
              : ''
          }
          show={this.state.showNoteModal}
          showNoteModal={this.showNoteModal}
          mode="message"
          update={() => console.log()}
        />
      </>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  activeStandbyId: state.standby.standby.activeStandbyId,
  standbyList: state.standby.standby.standbyList,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getStandbyAdjusters: standbyActions.getStandbyAdjusters,
      fetchAdjusterDataAttempt: accountActions.fetchAdjusterDataAttempt,
    },
    dispatch
  );

export default connect<Props, State>(
  mapStateToProps,
  mapDispatchToProps
)(MessageUserHeader);
