// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Image, Popup } from 'semantic-ui-react';

const CustomIconicButton = (props: any) => {
  const { onPress, popup, image, style, disabled, link = '' } = props;
  const onLinkClick = e => {
    if (disabled) {
      e.preventDefault();
    }
  };
  const items = (
    <Button
      type="button"
      className="iconic-button-custom"
      onClick={onPress}
      style={style}
      disabled={disabled}
    >
      <Image src={image} />
    </Button>
  );
  const trigger = link ? (
    <Link onClick={onLinkClick} to={link} replace={!!onPress}>
      {items}
    </Link>
  ) : (
    items
  );
  return <Popup trigger={trigger} basic={true} size="mini" content={popup} />;
};

export default CustomIconicButton;
