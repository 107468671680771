// @ts-nocheck
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { uploads } from '../../config';
import services from '../../services';
import * as actions from './actions';
import * as constants from './constants';

export default function*() {
  yield takeEvery(constants.GET_MESSAGES_LIST, getMessagesList);
  yield takeEvery(constants.GET_ROOM_MESSAGES, getRoomMessages);
  yield takeEvery(constants.LOAD_ROOM_MESSAGES, loadRoomMessages);
}

export function* getMessagesList(action: ActionType<typeof actions.getMessagesList>) {
  const { activeTab, messagesSearch, messagesSort, activePage } = yield select(
    state => state.messages.messages
  );
  const requestList = yield call(
    services.api.messages.getGlobalMessages,
    activePage - 1,
    messagesSearch,
    messagesSort,
    activeTab.toLowerCase()
  );
  if (requestList.isSuccess) {
    yield put(actions.submitMessagesList(requestList.data.list, requestList.data.count));
  } else {
    console.log(requestList);
    toast.dismiss();
    toast.error(
      requestList.data.errors ? requestList.data.errors[0].msg : `Error ${requestList.status}`
    );
  }
}

export function* loadRoomMessages(action: ActionType<typeof actions.loadRoomMessages>) {
  const { activeRoomPage, roomMessages } = yield select(state => state.messages.messages);
  const response = yield call(
    services.api.messages.getMessagesForRoom,
    action.payload.model.id,
    action.payload.model.sbid,
    activeRoomPage
  );
  if (response.isSuccess) {
    roomMessages.unshift(...response.data.list.reverse());
    yield put(actions.setRoomMessages(roomMessages, response.data.count, response.data.adjuster));
  } else {
    console.log(response);
    toast.dismiss();
    toast.error(response.data.errors ? response.data.errors[0].msg : `Error ${response.status}`);
  }
}

export function* getRoomMessages(action: ActionType<typeof actions.getRoomMessages>) {
  const response = yield call(
    services.api.messages.getMessagesForRoom,
    action.payload.model.id,
    action.payload.model.sbid,
    0
  );
  if (response.isSuccess) {
    yield put(
      actions.setRoomMessages(
        response.data.list.reverse(),
        response.data.count,
        response.data.adjuster
      )
    );
  } else {
    console.log(response);
    toast.dismiss();
    toast.error(response.data.errors ? response.data.errors[0].msg : `Error ${response.status}`);
  }
}
