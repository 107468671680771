// @ts-nocheck
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { trainingCenterActions } from '../../../features/training-center';
import { Folder } from '../../../features/training-center/trainingCenterReducer';
import { IUser } from '../../../models';


interface Props {
  user: IUser;
  getFolderList: typeof trainingCenterActions.getFolderList;
  setFolderToggle: typeof trainingCenterActions.setFolderToggle;
  setFolderActive: typeof trainingCenterActions.setFolderActive;
  setFolderEnter: typeof trainingCenterActions.setFolderEnter;
  setFolderLeave: typeof trainingCenterActions.setFolderLeave;
  toggleCreateFolderModal: typeof trainingCenterActions.toggleCreateFolderModal;
  toggleEditFolderModel: typeof trainingCenterActions.toggleEditFolderModel;
  toggleDeleteFolderModel: typeof trainingCenterActions.toggleDeleteFolderModel;
  showAssignAccessModal: typeof trainingCenterActions.showAssignAccessModal;
  folderList: Folder[];
  showDeleteModal: boolean;
  folderToDelete: Folder;
  deleteFolder: typeof trainingCenterActions.deleteFolder;
}

interface State {
  open: boolean;
  rootFolderValue: string;
}

class MaterialFilter extends React.Component<Props, State> {
  public state = {
    open: true,
    rootFolderValue: ''
  };


  public render() {
    return (
      <React.Fragment>
      {/*TODO: Filters*/}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  folderList: state.trainingCenter.folderList,
  folderToDelete: state.trainingCenter.deleteFolder.folder,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getFolderList: trainingCenterActions.getFolderList,
      setFolderToggle: trainingCenterActions.setFolderToggle,
      setFolderActive: trainingCenterActions.setFolderActive,
      setFolderEnter: trainingCenterActions.setFolderEnter,
      setFolderLeave: trainingCenterActions.setFolderLeave,
      toggleCreateFolderModal: trainingCenterActions.toggleCreateFolderModal,
      toggleEditFolderModel: trainingCenterActions.toggleEditFolderModel,
      toggleDeleteFolderModel: trainingCenterActions.toggleDeleteFolderModel,
      deleteFolder: trainingCenterActions.deleteFolder,
      showAssignAccessModal: trainingCenterActions.showAssignAccessModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MaterialFilter);
