// @ts-nocheck
import { ActionType, createReducer } from 'typesafe-actions';

import config from '../../config';
import { getFinanceIDFromFileName } from '../../utils/helper';
import * as actions from './actions';

export type FinanceAction = ActionType<typeof actions>;

export interface State {
  finances: any[];
  uploadFiles: any[];
  fetchingList: boolean;
  fetchListError: string | null;
  totalFinances: number;
  adjusters: any[];
  admins: any[];
  deletingFinance: boolean;
  deleteFinanceError: string | null;
  matchingAdjuster: boolean;
  // adjusters to be shown initially on the dropdowns
  initialAdjusters: any[];
  saving: boolean;
  savingError: string | null;
  exporting: boolean;
}

export type Action = ActionType<typeof actions>;

export const FileFinancialIDStatus = {
  MATCHING: 'matching',
  WARNING: 'warning',
  MISSING: 'missing',
};

const defaultState: State = {
  finances: [],
  uploadFiles: [],
  fetchingList: false,
  fetchListError: null,
  totalFinances: 0,
  adjusters: [],
  admins: [],
  deletingFinance: false,
  deleteFinanceError: null,
  matchingAdjuster: false,
  initialAdjusters: [],
  saving: false,
  savingError: null,
  exporting: false,
};

const getMatchedStatusAndFinanceID = (file: any, matchedData: any): [string, string] => {
  const financeIDFromFile = getFinanceIDFromFileName(file.name);
  if (matchedData && matchedData.id && matchedData.financeID) {
    if (matchedData.financeID !== financeIDFromFile) {
      return [FileFinancialIDStatus.WARNING, financeIDFromFile];
    }

    return [FileFinancialIDStatus.MATCHING, matchedData.financeID];
  } else if (matchedData && matchedData.id) {
    return [FileFinancialIDStatus.WARNING, financeIDFromFile];
  }

  return [FileFinancialIDStatus.MISSING, financeIDFromFile];
};

export default createReducer<State, Action>(defaultState)
  .handleAction(actions.fetchFinanceList, state => {
    return {
      ...state,
      fetchingList: true,
    };
  })
  .handleAction(actions.fetchFinanceListDone, (state, { payload: { error, data } }) => {
    return {
      ...state,
      fetchingList: false,
      fetchListError: error,
      finances: error ? [] : data.list,
      totalFinances: error ? 0 : data.count,
    };
  })
  .handleAction(actions.addUploadFinancialFiles, (state, { payload: { files } }) => {
    // Adding id to file object to use it as key when the files are rendered on a list
    // this way it will improve the performance when files are search/sorted/deleted
    const lastIndexCount = state.uploadFiles.length
      ? state.uploadFiles[state.uploadFiles.length - 1].id + 1
      : 0;
    const newFiles = files.map((file, index) => ({
      id: lastIndexCount + index,
      file,
      name: file.name,
    }));
    return {
      ...state,
      uploadFiles: [...state.uploadFiles, ...newFiles],
    };
  })
  .handleAction(actions.uploadFinancialFile, (state, { payload: { index } }) => {
    const uploadFiles = state.uploadFiles.map((file, i) => {
      if (i === index) {
        return {
          ...file,
          uploading: true,
        };
      }

      return file;
    });

    return {
      ...state,
      uploadFiles,
    };
  })
  .handleAction(actions.uploadFinancialFileDone, (state, { payload: { index, error, data } }) => {
    const uploadFiles = state.uploadFiles.map((file, i) => {
      if (i === index) {
        return {
          ...file,
          uploading: false,
          error,
          data,
        };
      }

      return file;
    });

    return {
      ...state,
      uploadFiles,
    };
  })
  .handleAction(actions.removeUploadFinancialFile, (state, { payload: { index } }) => {
    const files = state.uploadFiles.map((f, i) => {
      if (i === index) {
        return {
          ...f,
          removing: true,
        };
      }

      return f;
    });
    return {
      ...state,
      uploadFiles: files,
    };
  })
  .handleAction(actions.removeUploadFinancialFileDone, (state, { payload: { index } }) => {
    const files = state.uploadFiles.filter((f, i) => i !== index);
    return {
      ...state,
      uploadFiles: files,
    };
  })
  .handleAction(actions.setFinancialFileAdjuster, (state, { payload: { index, adjuster } }) => {
    const files = state.uploadFiles.map((f, i) => {
      if (i === index) {
        const matchedStatus =
          f.matchedStatus === 'missing' && !!adjuster ? 'warning' : f.matchedStatus;
        const financeID = f.matchedAdjuster ? f.matchedAdjuster.financeID : null;
        return {
          ...f,
          matchedAdjuster: {
            ...adjuster,
            financeID,
          },
          matchedStatus,
        };
      }
      return f;
    });

    return {
      ...state,
      uploadFiles: files,
    };
  })
  .handleAction(actions.setFinancialFileId, (state, { payload: { index, financialId } }) => {
    const files = state.uploadFiles.map((f, i) => {
      if (i === index) {
        return {
          ...f,
          matchedAdjuster: {
            ...f.matchedAdjuster,
            financeID: financialId,
          },
        };
      }
      return f;
    });

    return {
      ...state,
      uploadFiles: files,
    };
  })
  .handleAction(actions.resetFinancialFiles, state => {
    return {
      ...state,
      uploadFiles: [],
    };
  })
  .handleAction(actions.fetchFinanceAdjusterListDone, (state, { payload: { adjusters } }) => {
    return {
      ...state,
      adjusters,
    };
  })
  .handleAction(actions.fetchFinanceAdminListDone, (state, { payload: { admins } }) => {
    return {
      ...state,
      admins,
    };
  })
  .handleAction(actions.deleteFinancialFile, state => {
    return {
      ...state,
      deletingFinance: true,
    };
  })
  .handleAction(actions.deleteFinancialFileDone, (state, { payload: { error } }) => {
    return {
      ...state,
      deletingFinance: false,
      deleteFinanceError: error,
    };
  })
  .handleAction(actions.matchFinancesAdjuster, state => {
    return {
      ...state,
      matchingAdjuster: true,
    };
  })
  .handleAction(actions.matchFinancesAdjusterDone, (state, { payload: { matchesData } }) => {
    const uploadFiles = state.uploadFiles.map((file: any, i: number) => {
      const [matchedStatus, financeID] = getMatchedStatusAndFinanceID(file, matchesData[i]);

      if (matchesData[i].avatar) {
        matchesData[i].avatar = config.uploads.s3BucketAddress + matchesData[i].avatar;
      }

      return {
        ...file,
        matchedAdjuster: {
          ...matchesData[i],
          financeID,
        },
        matchedStatus,
      };
    });
    return {
      ...state,
      matchingAdjuster: false,
      uploadFiles,
    };
  })
  .handleAction(actions.fetchInitialAdjustersDone, (state, { payload: { adjusters } }) => {
    if (adjusters === null) {
      return state;
    }

    return {
      ...state,
      initialAdjusters: adjusters,
    };
  })
  .handleAction(actions.saveFinances, state => {
    return {
      ...state,
      saving: true,
    };
  })
  .handleAction(actions.saveFinancesDone, (state, { payload: { error } }) => {
    return {
      ...state,
      saving: false,
      savingError: error,
    };
  })
  .handleAction(actions.exportFinanceList, state => {
    return {
      ...state,
      exporting: true,
    };
  })
  .handleAction(actions.exportFinanceListDone, state => {
    return {
      ...state,
      exporting: false,
    };
  });
