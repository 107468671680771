// @ts-nocheck
import { trackPromise } from 'react-promise-tracker';
import { request } from './base';

export const sendMessage = (model: object, id: any) => {
  return trackPromise(
    request({
      method: 'post',
      url: `/message/${id}`,
      data: {
        title: model.title,
        text: model.text,
        provider: model.provider,
        recipients: model.recipients,
        twilioNumber: model.twilio,
        to: model.to ? model.to : undefined,
        bcc: model.bcc ? model.bcc.replace(/\s+/g, '').split(',') : undefined,
        cc: model.cc ? model.cc.replace(/\s+/g, '').split(',') : undefined,
      },
    }),
    'message-sending-area'
  );
};

export const sendGlobalMessage = (model: object, groupId?: any, id?: any) => {
  return trackPromise(
    request({
      method: 'post',
      url: `/message`,
      data: {
        title: model.title,
        text: model.text,
        provider: model.provider,
        recipients: model.recipients,
        to: model.to ? model.to : undefined,
        bcc: model.bcc ? model.bcc.replace(/\s+/g, '').split(',') : undefined,
        cc: model.cc ? model.cc.replace(/\s+/g, '').split(',') : undefined,
        standbyListId: id ? id : undefined,
        groupId: groupId ? groupId : undefined,
      },
    }),
    'message-sending-area'
  );
};

export const getStandbyMessages = (id: any, page: number, search: string) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/message?standbyListId=${id}&page=${page}&pageSize=10&sort=unread,desc`,
      params: {
        search,
      },
    }),
    'details-adjusters-table'
  );
};

export const getMessagesRecipients = (id: any, page: number, search: string, pageSize?: number) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/message/room/${id}/recipients`,
      params: {
        page,
        pageSize: pageSize ? pageSize : 10,
        search,
      },
    }),
    'messages-list-area'
  );
};

export const getGlobalMessages = (page: any, search: string, sort: string, type: string) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/message?&page=${page}&pageSize=10`,
      params: {
        search,
        sort,
        type,
      },
    }),
    'messages-list-area'
  );
};

export const getGlobalMessagesForList = (page: any, search: string, sort: string, type: string) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/message?&page=${page}&pageSize=10`,
      params: {
        search,
        sort,
        type,
      },
    }),
    'messages-list-area'
  );
};

export const getMessageDetails = (id: any) => {
  return request({
    method: 'get',
    url: `/message/${id}`,
  });
};

export const getMessagesForRoom = (id: any, sbid?: any, page?: number) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/message/room/personal/${id}?page=${page}&pageSize=15`,
      params: {
        standbyListId: sbid ? sbid : undefined,
      },
    }),
    'message-sending-area'
  );
};
