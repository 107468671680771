// @ts-nocheck
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import compassLogo from '../../assets/images/compass-logo-vector.svg';
import ResetPasswordForm from '../../components/recovery-modal/ResetPasswordForm';
import { accountActions } from '../../features/account';

import { Grid, Image, Message } from 'semantic-ui-react';

interface Props {
  error: string;
  resetPasswordAttempt: typeof accountActions.resetPasswordAttempt;
  isLoading: boolean;
}

interface State {
  token: string;
}

class ResetPassword extends React.Component<Props, State> {
  public state = {
    token: '',
  };

  public componentDidMount() {
    const tokenIndex = this.props.location.search.indexOf('token=');
    const token = this.props.location.search.slice(tokenIndex + 6);
    this.setState({ token });
  }

  public render() {
    return (
      <div>
        <div className="logo-container">
          <Image src={compassLogo} />
          <h3>
            Create your account to start your work with Compass Adjusting Services. All information
            is handled as confidential by Compass Adjusting Services, Inc.
          </h3>
        </div>

        <ResetPasswordForm
          isLoading={this.props.isLoading}
          onSubmit={this.props.resetPasswordAttempt}
          token={this.state.token}
          error={this.props.error}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState, ownProps) => ({
  error: state.account.recover.error,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      resetPasswordAttempt: accountActions.resetPasswordAttempt,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
