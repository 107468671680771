// @ts-nocheck
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { uploads } from '../../config';
import services from '../../services';
import * as loadingActions from '../loading/actions';
import * as actions from './actions';
import * as constants from './constants';

export default function*() {
  yield takeEvery(constants.GET_STANDBY_LIST, getStandbyList);
  yield takeEvery(constants.GET_SELECTED_ADJUSTERS, getSelectedAdjusters);
  yield takeEvery(constants.GET_INVITE_MODAL_USERS, getInviteModalUsers);
  yield takeEvery(constants.GET_STANDBY_ADJUSTERS, getStandbyAdjusters);
  yield takeEvery(constants.GET_MAP_STANDBY_ADJUSTERS, getMapStandbyAdjusters);
  yield takeEvery(constants.GET_STANDBY_MESSAGES, getStandbyMessages);
  yield takeEvery(constants.EXPORT_STANDBY_ADJUSTERS, exportStandbyAdjusters);
  yield takeEvery(constants.EXPORT_STANDBY_ADJUSTERS_APPLIED, exportStandbyListAdjustersApplied);
}

export function* getStandbyList(action: ActionType<typeof actions.getStandbyList>) {
  const { standbySort, standbySearch, activeListPage, activeTab } = yield select(
    state => state.standby.standby
  );
  const sortArray = standbySort.split(',');
  const sortObject = Object.assign({}, { [sortArray[0]]: sortArray[1] });
  const requestList = yield call(
    services.api.standby.getStandbyList,
    { page: activeListPage - 1 },
    sortObject,
    activeTab,
    standbySearch
  );
  if (requestList.isSuccess) {
    yield put(actions.submitStandbyList(requestList.data.list, requestList.data.count));
  } else {
    console.log(requestList);
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}

export function* getSelectedAdjusters(action: ActionType<typeof actions.getSelectedAdjusters>) {
  const { selectedId } = yield select(state => state.standby.standby);
  const { adjustersList } = yield select(state => state.adjusters.adjusters);
  const { adminList } = yield select(state => state.admin.admins);

  const concItems = adjustersList.concat(adminList);
}

export function* getInviteModalUsers(action: ActionType<typeof actions.getInviteModalUsers>) {
  const { activeTab, activePageAdj, pageSize } = yield select(state => state.standby.inviteModal);

  const initialTabs = ['All', 'Admins', 'Selected', 'Active', 'Inactive', 'Pending', 'QuickApplied'];
  if (!initialTabs.includes(activeTab)) {
    const adjRequest = yield call(
      services.api.standby.getStandbyAdjusters,
      action.payload.standbyId,
      activePageAdj - 1,
      true,
      activeTab,
      {
        ...action.payload.filterOptions,
      },
      'invite-modal-users',
      pageSize
    );
    if (adjRequest.isError) {
      toast.dismiss();
      toast.error(
        adjRequest.data.errors ? adjRequest.data.errors[0].msg : `Error ${adjRequest.status}`
      );
      console.log(adjRequest);
    }

    if (adjRequest.isSuccess) {
      yield put(
        actions.setInviteModalUsers(
          adjRequest.data.list.map(el => {
            el.avatar = el.avatar ? uploads.s3BucketAddress + el.avatar : el.avatar;
            return el;
          }),
          adjRequest.data.count
        )
      );
    }
  } else {
    const apiProps: { [key: string]: any } = {
      ...action.payload.filterOptions,
      page: activePageAdj - 1,
      pageSize: pageSize,
      status: activeTab === 'All' || activeTab === 'Admins' ? '' : activeTab,
    };

    if (
      action.payload.filterOptions.hasOwnProperty('isAdmin') &&
      action.payload.filterOptions.isAdmin !== undefined
    ) {
      if (action.payload.filterOptions.isAdmin || activeTab === 'Admins') {
        apiProps.isAdmin = true;
      } else {
        apiProps.isAdmin = false;
      }
    }

    const adjRequest = yield call(
      services.api.adjuster.fetchAdjusterListCustom,
      apiProps,
      'invite-modal-users'
    );

    if (adjRequest.isError) {
      toast.dismiss();
      toast.error(
        adjRequest.data.errors ? adjRequest.data.errors[0].msg : `Error ${adjRequest.status}`
      );
      console.log(adjRequest);
    }

    if (adjRequest.isSuccess) {
      yield put(actions.setInviteModalUsers(adjRequest.data.list, adjRequest.data.count));
    }
  }
}

export function* getStandbyAdjusters(action: ActionType<typeof actions.getStandbyAdjusters>) {
  loadingActions.show();
  const { status, activePage, adjustersPerPage } = yield select(state => state.standby.adjusters);
  const getAdjusters = yield call(
    services.api.standby.getStandbyAdjusters,
    action.payload.id,
    activePage - 1,
    false,
    status,
    action.payload.filter,
    undefined,
    adjustersPerPage
  );
  if (getAdjusters.isSuccess) {
    yield put(actions.setStandbyAdjusters(getAdjusters.data.list, getAdjusters.data.count));
  } else {
    console.log(getAdjusters);
    toast.dismiss();
    toast.error(
      getAdjusters.data.errors ? getAdjusters.data.errors[0].msg : `Error ${getAdjusters.status}`
    );
  }
  loadingActions.hide();
}

export function* getMapStandbyAdjusters(action: ActionType<typeof actions.getMapStandbyAdjusters>) {
  loadingActions.show();
  const { status } = yield select(state => state.standby.adjusters);
  const getAdjusters = yield call(
    services.api.standby.getMapStandbyAdjusters,
    action.payload.id,
    status,
    action.payload.filter,
    undefined,
  );
  if (getAdjusters.isSuccess) {
    yield put(actions.setMapStandbyAdjusters(getAdjusters.data.list, getAdjusters.data.count));
  } else {
    console.log(getAdjusters);
    toast.dismiss();
    toast.error(
      getAdjusters.data.errors ? getAdjusters.data.errors[0].msg : `Error ${getAdjusters.status}`
    );
  }
  loadingActions.hide();
}


export function* getStandbyMessages(action: ActionType<typeof actions.getStandbyMessages>) {
  const { search, activePage } = yield select(state => state.standby.messages);
  const getMessages = yield call(
    services.api.messages.getStandbyMessages,
    action.payload.id,
    activePage - 1,
    search
  );
  if (getMessages.isSuccess) {
    const messagesData = getMessages.data.list;
    for (const message of messagesData) {
      const response = yield call(services.api.messages.getMessagesRecipients, message.id);
      message.recipientsList = response.data.list;
    }
    yield put(actions.setStandbyMessages(messagesData, getMessages.data.count));
  } else {
    console.log(getMessages);
    toast.dismiss();
    toast.error(
      getMessages.data.errors ? getMessages.data.errors[0].msg : `Error ${getMessages.status}`
    );
  }
}

export function* exportStandbyAdjusters(action: ActionType<typeof actions.exportStandbyAdjusters>) {
  const { status } = yield select(state => state.standby.adjusters);
  for (const key in action.payload.filter) {
    if (action.payload.filter[key] === '') {
      action.payload.filter[key] = undefined;
    }
  }
  const exportAdjusters = yield call(
    services.api.finance.exportAdjustersStandbyList,
    action.payload.id,
    status,
    action.payload.filter
  );
}

export function* exportStandbyListAdjustersApplied(
  action: ActionType<typeof actions.exportStandbyAdjustersApplied>
) {
  const { status } = yield select(state => state.standby.adjusters);
  for (const key in action.payload.filter) {
    if (action.payload.filter[key] === '') {
      action.payload.filter[key] = undefined;
    }
  }
  yield call(
    services.api.finance.exportStandbyListAdjustersExportApplied,
    action.payload.id,
    status,
    action.payload.filter
  );
}
