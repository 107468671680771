// @ts-nocheck
import get from 'lodash/get';
import moment from 'moment';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Image, Label, Pagination, Popup, Table, Dropdown, Icon, Button } from 'semantic-ui-react';
import noteIcon from '../../assets/images/Blue/add-note.svg';
import arrowIcon from '../../assets/images/Blue/arrow-r.svg';
import waitingConfirmation from '../../assets/images/Blue/deployed-blue.svg';
import messageIcon from '../../assets/images/Blue/message.svg';
import questionsIcon from '../../assets/images/Blue/questions.svg';
import removeUserIcon from '../../assets/images/Blue/remove-user.svg';
import selectUserIcon from '../../assets/images/Blue/select-user.svg';
import deployed from '../../assets/images/deployed-green.svg';
import messageLeft from '../../assets/images/message-left.svg';
import noAvatarIcon from '../../assets/images/no-avatar.svg';
import notEligible from '../../assets/images/not-eligible.svg';
import readyToRoll from '../../assets/images/ready-to-roll.svg';
import { uploads } from '../../config';
import { accountActions } from '../../features/account';
import { standbyActions } from '../../features/standby';
import { IUser } from '../../models';
import services from '../../services';
import { editStandbyDeploy } from '../../services/api/standby';
import { DeploymentStatus, DeploymentStatusToLabel } from '../../utils/deploymentStatuses';
import * as helper from '../../utils/helper';
import statesUS from '../../utils/statesUSbyKey';
import ContactedModal from '../standby-layout/modals/ContactedModal';
import CustomIconicButton from './../adjuster-list/CustomIconicButton';
import DeploymentStatusesModal from './modals/DeploymentStatusesModal';
import ViewGuestAdjusterModal from './modals/ViewGuestAdjuster';
import NotePopup from './NotePopup';
import { toast } from 'react-toastify';
import CallerModal from './modals/CallerModal';
import InfoModal from './modals/InfoModal';

const initialFilter = {
  availableForClaims: '',
  catCity: '',
  catState: '',
  certification: '',
  city: '',
  commercialTeam: '',
  deployed: '',
  equipment: '',
  experience: '',
  expertise: '',
  language: '',
  license: '',
  largeLossTeam: '',
  milesAway: '',
  name: '',
  position: '',
  softwareSkill: '',
  state: '',
  xactnetAddress: '',
  zip: '',
  authorityLevelFrom: '',
  authorityLevelTo: '',
  registrationType: '',
  sort: 'updatedAt,desc',
  allPaperworkCompleted: '',
  isAdmin: '',
  orientation: '',
  orientationAttended: '',
  licenseConfirmation: '',
  xactimateQualificationStatus: '',
  profilesInstalled: '',
  firstReviewCompleted: '',
};

interface Props {
  setActiveAdjuster: typeof standbyActions.setActiveAdjuster;
  getStandbyAdjusters: typeof standbyActions.getStandbyAdjusters;
  setStandbyAdjustersPage: (object: any) => void;
  setActiveStandby: typeof standbyActions.setActiveStandby;
  user: IUser;
  adjusters: object[];
  standbyId: any;
  activeTab: string;
  activeList: any;
  totalAdjusters: number;
  activePage: number;
  adjustersPerPage: number;
  updateData: () => void;
  setAdjustersPerPage: (perPage: number, filter: object) => void;
}

interface State {
  showDeploymentStatusesModal: boolean;
  showContactedModal: boolean;
  activeUserId: any;
  userStatus: string;
  showNotes: boolean;
  deploymentStatus: string;
  deploymentId: string;
  guestAdjuster?: object;
  showViewGuestAdjusterModal: boolean;
  selectedAdjuster: any;
  filterState: object;
  isOpen: number | null;
}

class SBDetailsTable extends React.Component<Props, State> {
  public state = {
    showDeploymentStatusesModal: false,
    showContactedModal: false,
    activeUserId: 0,
    userStatus: 'contacted',
    showNotes: false,
    deploymentStatus: '',
    deploymentId: '',
    guestAdjuster: undefined,
    showViewGuestAdjusterModal: false,
    selectedAdjuster: null,
    isOpen: null,
    callerModalOpenId: 0,
    infoModalOpenId: 0,
    filterState: {
      ...initialFilter,
    },
  };

  public getFilter() {
    const filterState =
      window.location.hash !== ''
        ? {
            ...this.state.filterState,
            ...JSON.parse(decodeURI(window.location.hash.slice(1))).filter,
          }
        : { ...this.state.filterState };
    return filterState;
  }

  public componentDidMount() {
    const activePage = get(window.history, 'state.state.activePage', 1);
    this.setState({
      filterState: this.getFilter(),
    });
    this.handlePagination({}, { activePage });
  }

  public showContactedModal = (show: boolean) => {
    this.setState({ showContactedModal: show });
  };

  public showDeploymentStatusesModal = (show: boolean) => {
    this.setState({ showDeploymentStatusesModal: show });
  };

  public setSelectedAdjuster = (adjuster: any) => {
    this.setState({ selectedAdjuster: adjuster });
  };

  public setDeploymentId = (id: string) => {
    this.setState({ deploymentId: id });
  };

  public setDeploymentStatus = (status: string) => {
    this.setState({ deploymentStatus: status });
  };

  public setAdjusterDeploymentStatus = (status: string) => {
    editStandbyDeploy(
      { deploymentStatus: this.state.deploymentStatus },
      this.props.standbyId,
      this.state.deploymentId
    ).then(() => {
      toast.success('Deployment Status changed successfully!');
    });
    this.props.updateData();
  };

  public updateData = () => {
    this.props.updateData();
  };

  public handlePagination = (e: any, data: any) => {
    this.props.setStandbyAdjustersPage(data.activePage);
    this.updateData();
  };

  public removeDuplicates = (arr: []) => {
    return arr.filter((thing, index, self) => self.findIndex(t => t.id === thing.id) === index);
  };

  public getDeploymentIcon = (status: string) => {
    switch (status) {
      case DeploymentStatus.DEPLOYED:
        return deployed;
      case DeploymentStatus.LEFT_MESSAGE:
        return messageLeft;
      case DeploymentStatus.NOT_ELIGIBLE:
        return notEligible;
      case DeploymentStatus.READY_TO_ROLL:
        return readyToRoll;
      case DeploymentStatus.WAITING_ON_CONFIRMATION:
        return waitingConfirmation;
      default:
        return waitingConfirmation;
    }
  };

  public viewSignupProfile = (signup: any) => {
    this.setState({
      showViewGuestAdjusterModal: true,
      guestAdjuster: signup,
    });
  };

  private parseFilters(params: any = {}) {
    const dataModel = Object.assign({}, params);
    for (const key in dataModel) {
      if (dataModel[key] === '' || dataModel[key] === undefined) {
        delete dataModel[key];
      } else if (key === 'standbyListDeployments' || key === 'eventDeployments') {
        dataModel[key] = dataModel[key].map(f => (f === 'exclude' ? 'exclude' : f.id));
      }
    }

    params = dataModel;

    const keys = [
      'state',
      'licenseStates',
      'certificationTypes',
      'experience',
      'position',
      'equipment',
      'expertise',
      'softwareSkill',
      'catStateProvince',
      'language',
      'registrationType',
    ];

    let filterProps = {
      ...params,
      name: params.name && params.name !== '' ? params.name : this.state.search,
    };

    for (const key of keys) {
      if (filterProps[key] && filterProps[key].length) {
        filterProps = {
          ...filterProps,
          [key]: filterProps[key],
        };
      }
    }

    return filterProps;
  }

  public handleOpen = (id: number) => {
    console.log('handleOpen', { id });
    this.setState({ showNotes: !this.state.showNotes });
    this.setState({ isOpen: id });
  };

  public handleClose = () => {
    this.setState({ isOpen: null });
  };

  public handleCloseCallerModal = (newData: any) => {
    if (newData) {
      this.props.adjusters.forEach((data) => {
        if (data.standByAdjusterId === this.state.callerModalOpenId) {
          data.callers = newData;
        }
      })
    }
    this.setState({ callerModalOpenId: 0 });
  };

  public handleCloseInfoModal = (newData: any) => {
    if (newData) {
      this.props.adjusters.forEach((data) => {
        if (data.standByAdjusterId === this.state.infoModalOpenId) {
          Object.entries(newData).forEach(([key, value]) => {
            data[key] = value
          })
        }
      })
    }
    this.setState({ infoModalOpenId: 0 });
  }

  public handleItemsPerPageChange = (e: any, { value }: any) => {
    const filter = this.parseFilters(this.getFilter());
    console.log({ filter });
    this.props.setAdjustersPerPage(value, filter);
  };

  public refresh = () => {
    const { filter } = JSON.parse(decodeURI(window.location.hash.slice(1) || '{}'));

    this.props.getStandbyAdjusters(
      this.props.standbyId,
      Object.assign({ id: this.props.user.id }, filter)
    );
  };

  public render() {
    const { adjusters, activeTab, activePage, user, totalAdjusters, adjustersPerPage } = this.props;
    const { activeUserId } = this.state;
    const totalPages = helper.getTotalPage(totalAdjusters, adjustersPerPage);
    const uniqAdjusters = this.removeDuplicates(adjusters);

    console.log({ activeTab });
    return (
      <>
        <Table
          compact={true}
          striped={true}
          stackable={true}
          singleLine={true}
          className="data-table adjusters-table"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="table-avatar" />
              <Table.HeaderCell>Last Name</Table.HeaderCell>
              <Table.HeaderCell>First Name</Table.HeaderCell>
              <Table.HeaderCell>City</Table.HeaderCell>
              <Table.HeaderCell>State</Table.HeaderCell>
              <Table.HeaderCell>Mobile</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              {activeTab !== 'QuickApplied' && <Table.HeaderCell>Compass Email</Table.HeaderCell>}
              {activeTab !== 'Invited' && <Table.HeaderCell>Signed up On</Table.HeaderCell>}
              <Table.HeaderCell>Deployment Status</Table.HeaderCell>
              <Table.HeaderCell>
                {activeTab === 'Contacted' ? 'Contacted' : 'Note'}
              </Table.HeaderCell>
              <Table.HeaderCell>Total: {totalAdjusters}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {uniqAdjusters &&
              uniqAdjusters.map((adjuster: any, i: number) => {
                const state: string = adjuster.stateName || statesUS[adjuster.state];
                const { signup } = adjuster;
                const id = adjuster.standByAdjusterId

                return (
                  <Table.Row key={adjuster.id} className={signup ? 'is-guest' : ''}>
                    <Table.Cell className="table-avatar">
                      <Image
                        circular={true}
                        src={
                          adjuster.avatar ? uploads.s3BucketAddress + adjuster.avatar : noAvatarIcon
                        }
                        avatar={true}
                      />
                    </Table.Cell>
                    <Table.Cell className="table-name">
                      {helper.truncateString(adjuster.lastName, 20)}
                    </Table.Cell>{' '}
                    <Table.Cell className="table-name">
                      {adjuster.preferredName ? adjuster.preferredName : adjuster.firstName}
                    </Table.Cell>
                    <Table.Cell className="table-city">
                      {helper.truncateString(adjuster.city, 20)}
                    </Table.Cell>
                    <Table.Cell className="table-city">
                      {helper.truncateString(adjuster.state, 20)}
                    </Table.Cell>
                    <Table.Cell className="table-phone">{adjuster.mobilePhone}</Table.Cell>
                    <Table.Cell style={{ minWidth: '250px' }} className="table-email">
                      {adjuster.email}
                    </Table.Cell>
                    {activeTab !== 'QuickApplied' && (
                      <Table.Cell style={{ minWidth: '250px' }} className="table-email">
                        {adjuster.compassEmail}
                      </Table.Cell>
                    )}
                    {activeTab !== 'Invited' && (
                      <Table.Cell className="table-date">
                        {adjuster.signedUpAt
                          ? moment(adjuster.signedUpAt).format('MM/DD/YYYY, hh:mm A')
                          : null}
                      </Table.Cell>
                    )}
                    <Table.Cell style={{ minWidth: '100px' }} className="table-deployment-status">
                      {adjuster.deploymentStatus
                        ? DeploymentStatusToLabel[adjuster.deploymentStatus.toLowerCase()]
                        : null}
                    </Table.Cell>
                    <Table.Cell style={{ width: '100px' }} className="table-email contacted-user">
                      {activeTab === 'Contacted' && adjuster.note && adjuster.note.length > 0 && (
                        <span>
                          {moment(adjuster.note[0].date).format('MM/DD/YYYY, hh:mm A')}{' '}
                          {adjuster.note[0].author}{' '}
                        </span>
                      )}
                      {adjuster.note && adjuster.note.length > 0 && (
                        <div>
                          <Popup
                            content={
                              <NotePopup
                                standbyId={this.props.standbyId}
                                adjuster={adjuster}
                                notes={adjuster.note ? adjuster.note : []}
                                open={this.state.isOpen}
                                handleClose={this.handleClose}
                                getAdjusters={this.props.getAdjusters}
                              />
                            }
                            open={adjuster.id === this.state.isOpen}
                            className="note-standby-popup"
                            trigger={
                              <span
                                className="view-note-trigger"
                                onClick={e => {
                                  e.stopPropagation(); // Stop the event propagation
                                  this.handleOpen(adjuster.id);
                                }}
                              >
                                View Note
                              </span>
                            }
                          />
                        </div>
                      )}
                    </Table.Cell>
                    <Table.Cell className="table-action">
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CustomIconicButton
                          link={{
                            pathname: `/stand-by-lists/answers/${this.props.standbyId}`,
                            hash: window.location.hash,
                            search: `adjusterId:${adjuster.id}`,
                            state: { prevPath: window.location.pathname, activePage, activeTab },
                          }}
                          disabled={
                            activeTab === 'Invited' ||
                            (activeTab === 'Contacted' && adjuster.answers.length === 0)
                          }
                          popup={'View Answers'}
                          image={questionsIcon}
                        />

                        <Popup trigger={
                          <Button
                            type="button"
                            className="iconic-button-custom popup-button-icon"
                            onClick={() => {
                              this.setState({ infoModalOpenId: id })
                            }}
                          >
                            <Icon name='info'/>
                          </Button>
                        } basic={true} size="mini" content={"Additional Attributes"} />

                        <Popup trigger={
                          <Button
                            type="button"
                            className="iconic-button-custom popup-button-icon"
                            onClick={() => {
                              this.setState({ callerModalOpenId: id })
                            }}
                          >
                            <Icon name='call'/>
                          </Button>
                        } basic={true} size="mini" content={"Caller"} />


                        {!this.props.activeList.closedAt &&
                          user.role !== 'Read Only Office Admin' &&
                          !signup && (
                            <div className="standby-list-adjuster-icon">
                              <CustomIconicButton
                                onPress={() => {
                                  this.props.setActiveStandby(this.props.standbyId)
                                  services.router.goto(
                                    `/messages/room/${adjuster.id}/${this.props.standbyId}${window.location.hash}`
                                  );
                                }}
                                link={`/messages/room/${adjuster.id}/${this.props.standbyId}${window.location.hash}`}
                                popup={'Messages'}
                                image={messageIcon}
                              />
                              {adjuster && adjuster.hasUnreadMessages && (
                                <Label color="red" floating={true} circular={true} empty={true} />
                              )}
                            </div>
                          )}

                        {!this.props.activeList.closedAt &&
                          activeTab !== 'Contacted' &&
                          user.role !== 'Read Only Office Admin' && (
                            <CustomIconicButton
                              onPress={() =>
                                this.setState({ activeUserId: i, userStatus: 'contacted' }, () =>
                                  this.showContactedModal(true)
                                )
                              }
                              popup={'Mark as Contacted'}
                              image={selectUserIcon}
                            />
                          )}

                        {!this.props.activeList.closedAt &&
                          user.role !== 'Read Only Office Admin' && (
                            <CustomIconicButton
                              onPress={() =>
                                this.setState({ activeUserId: i, userStatus: 'removed' }, () =>
                                  this.showContactedModal(true)
                                )
                              }
                              popup={'Remove from List'}
                              image={removeUserIcon}
                            />
                          )}

                        {!this.props.activeList.closedAt &&
                          user.role !== 'Read Only Office Admin' && (
                            <CustomIconicButton
                              onPress={() =>
                                this.setState({ activeUserId: i, userStatus: 'note' }, () =>
                                  this.showContactedModal(true)
                                )
                              }
                              popup={'Add Note'}
                              image={noteIcon}
                            />
                          )}

                        <CustomIconicButton
                          onPress={() => {
                            this.setDeploymentId(adjuster.id);
                            this.setSelectedAdjuster(adjuster);
                            this.showDeploymentStatusesModal(true);
                          }}
                          popup={'Set deployment status'}
                          image={this.getDeploymentIcon(adjuster.deploymentStatus)}
                        />

                        {signup ? (
                          <CustomIconicButton
                            popup={'View Profile'}
                            image={arrowIcon}
                            onPress={() => this.viewSignupProfile(signup)}
                          />
                        ) : (
                          <CustomIconicButton
                            popup={'View Profile'}
                            image={arrowIcon}
                            link={{
                              pathname: `/adjusters/${adjuster.id}`,
                              state: {
                                prevPath: window.location.pathname + window.location.hash,
                                activePage,
                                activeTab,
                              },
                            }}
                          />
                        )}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            paddingTop: '10px',
          }}
        >
          <Dropdown
            className="pagesCountDropdown"
            defaultValue={20}
            style={{ height: 'auto', minHeight: 'auto' }}
            selection={true}
            options={[
              { text: '20', value: 20 },
              { text: '50', value: 50 },
              { text: '100', value: 100 },
              { text: '200', value: 200 },
            ]}
            onChange={this.handleItemsPerPageChange}
          />
          {totalPages > 1 && (
            <Pagination
              totalPages={totalPages}
              boundaryRange={1}
              activePage={activePage}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              prevItem={
                activePage === 1
                  ? false
                  : {
                      'aria-label': 'Previous item',
                      content: 'PREVIOUS',
                    }
              }
              nextItem={
                activePage === totalPages
                  ? false
                  : {
                      'aria-label': 'Next item',

                      content: 'NEXT',
                    }
              }
              siblingRange={1}
              onPageChange={this.handlePagination}
            />
          )}
        </div>

        <CallerModal id={this.state.callerModalOpenId} onModalClose={this.handleCloseCallerModal} intialData={uniqAdjusters.find((data) => data.standByAdjusterId === this.state.callerModalOpenId)?.callers || []} />
        <InfoModal id={this.state.infoModalOpenId} onModalClose={this.handleCloseInfoModal} intialData={uniqAdjusters.find((data) => data.standByAdjusterId === this.state.infoModalOpenId) || {}} />

        <ContactedModal
          standbyId={this.props.standbyId}
          user={adjusters[activeUserId] ? adjusters[activeUserId] : []}
          show={this.state.showContactedModal}
          status={this.state.userStatus}
          showContactedModal={this.showContactedModal}
          updateData={this.updateData}
        />

        <DeploymentStatusesModal
          show={this.state.showDeploymentStatusesModal}
          showDeploymentStatusesModal={this.showDeploymentStatusesModal}
          deploymentStatus={this.state.deploymentStatus}
          setDeploymentStatus={this.setDeploymentStatus}
          selectedAdjuster={this.state.selectedAdjuster}
          setDeploymentId={this.setDeploymentId}
          setAdjusterDeploymentStatus={this.setAdjusterDeploymentStatus}
        />

        <ViewGuestAdjusterModal
          show={this.state.showViewGuestAdjusterModal}
          adjuster={this.state.guestAdjuster}
          onClose={() =>
            this.setState({ showViewGuestAdjusterModal: false, guestAdjuster: undefined })
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  totalAdjusters: state.standby.adjusters.totalAdjusters,
  activePage: state.standby.adjusters.activePage,
  adjustersPerPage: state.standby.adjusters.adjustersPerPage,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchAdjusterDataAttempt: accountActions.fetchAdjusterDataAttempt,
      setActiveAdjuster: standbyActions.setActiveAdjuster,
      getStandbyAdjusters: standbyActions.getStandbyAdjusters,
      setActiveStandby: standbyActions.setActiveStandby,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SBDetailsTable);
