import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { accountActions } from '../../features/account';

import { Header, Icon } from 'semantic-ui-react';

import ErrorListMessage from '../../components/error-list-message/ErrorListMessage';

interface Props {
  emailVerificationAttempt: typeof accountActions.emailVerificationAttempt;
  errors: string[];
}

interface State {}

class EmailVerify extends React.Component<Props, State> {
  public componentWillMount() {
    const params = new URLSearchParams(window.location.search);
    const userId = params.get('u') as string;
    const verificationToken = params.get('t') as string;

    this.props.emailVerificationAttempt({
      userId,
      verificationToken,
    });
  }

  public render() {
    return (
      <div>
        <Header as="h2" icon={true} textAlign="center" style={{ marginBottom: '60px' }}>
          <Icon name="envelope open" circular={true} />
          <Header.Content>Verify email.</Header.Content>
        </Header>

        <ErrorListMessage errors={this.props.errors} />
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  errors: state.account.emailVerification.errors,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      emailVerificationAttempt: accountActions.emailVerificationAttempt,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailVerify);
