// @ts-nocheck
import React from 'react';
import { Button, Checkbox, Image, Modal } from 'semantic-ui-react';
import closeIcon from '../../../assets/images/Blue/cancel.svg';
import deploymentStatuses from '../../../utils/deploymentStatuses';

interface Props {
  show: boolean;
  deploymentStatus: string;
  selectedAdjuster: any;
  showDeploymentStatusesModal: (show: boolean) => void;
  setDeploymentStatus: (status: string) => void;
  setDeploymentId: (id: string) => void;
  setAdjusterDeploymentStatus: () => void;
}

interface State {
  status?: string;
  changed: boolean;
}

class DeploymentStatusesModal extends React.Component<Props, State> {
  public state = {
    status: '',
    changed: false,
  };

  public onModalClose = () => {
    this.props.setDeploymentId('');
    this.props.setDeploymentStatus('');
    this.props.showDeploymentStatusesModal(false);
  };

  public render() {
    const { selectedAdjuster, deploymentStatus } = this.props;
    const { status, changed } = this.state;

    if (!selectedAdjuster) return null;

    const statusChecked = this.state.status || selectedAdjuster.deploymentStatus;

    return (
      <Modal
        className="deployment-statuses-modal"
        open={this.props.show}
        closeIcon={<Image src={closeIcon} className="icon" onClick={this.onModalClose} />}
        size="tiny"
      >
        <Modal.Header>Choose Deployment Status</Modal.Header>
        <Modal.Content>
          <div className="checkboxes-list-select" style={{ width: '100%' }}>
            {deploymentStatuses.map((status, index) => (
              <Checkbox
                value={status.value}
                label={status.text}
                checked={status.value === statusChecked}
                key={index}
                onChange={() => {
                  if (status.value === statusChecked) {
                    this.setState({ status: 'none' });
                    this.props.setDeploymentStatus(null);
                  } else {
                    this.setState({ status: status.value });
                    this.props.setDeploymentStatus(status.value);
                  }
                }}
              />
            ))}
          </div>
        </Modal.Content>
        <div className="modal-button-footer" style={{ justifyContent: 'space-between' }}>
          <Button
            type="button"
            style={{ width: '115px', height: '32px' }}
            className="secondary-button"
            secondary={true}
            onClick={this.onModalClose}
          >
            CANCEL
          </Button>
          <Button
            style={{ width: '115px' }}
            primary={true}
            onClick={() => {
              this.props.setAdjusterDeploymentStatus(this.state.status);
              this.onModalClose();
            }}
          >
            OK
          </Button>
        </div>
      </Modal>
    );
  }
}

export default DeploymentStatusesModal;
