import React from 'react';
import { Icon } from 'semantic-ui-react';

export const NoResults = ({ marginTop }: { marginTop?: string }) => {
  return (
    <div className="no-results-container" style={{marginTop: marginTop || '50px'}}>
      <div>
        <Icon name="ban" /> No Results
      </div>
    </div>
  );
};
