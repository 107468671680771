// @ts-nocheck
import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { Button } from 'semantic-ui-react';
import services from '../../services';
import ErrorLabel from '../error-label/ErrorLabel';

interface Props {
  getEducation: (item: any) => void;
}

interface State {
  showCreate: boolean;
  degree: boolean;
  schoolOptions: object[];
  typeValue: string;
}

class AddEducation extends React.Component<Props, State> {
  public state = {
    showCreate: false,
    degree: false,
    schoolOptions: [],
    typeValue: '',
    schoolValue: '',
  };

  public componentDidMount = async () => {
    const request = await services.api.account.getSettingsData('school');
    if (request.isSuccess) {
      const schoolOptions = request.data.list.map((el: any, index: any) => {
        const element = { text: el.name, value: el.name };
        return element;
      });
      this.setState({ schoolOptions });
    }
  };

  public render() {
    const errorLabel = <ErrorLabel />;
    return (
      <React.Fragment>
        {this.state.showCreate && (
          <div className="create-education-container">
            <Form
              ////////// ПЕРЕДЕЛАТЬ ПОД FORMSY ЭТУ КОМПОНЕНТУ. ПРОБЛЕМА С ВАЛИДАЦИЕЙ БЕЗ ТЕГА FORM
              ref={ref => (this.form = ref)}
              id="create-education-form"
              onValidSubmit={model => {
                this.props.getEducation(model);
                this.setState({ showCreate: false });
              }}
            >
              <div className="input-field-container">
                <div className="input-field-label">School Type</div>
                <Form.Dropdown
                  name="type"
                  search={true}
                  selectOnBlur={false}
                  placeholder="Please Select"
                  options={this.state.schoolOptions}
                  required={true}
                  validationErrors={{
                    isDefaultRequiredValue: `* Type is required field.`,
                  }}
                  className="form-input-short form-input-dropdown"
                  errorLabel={errorLabel}
                />
              </div>

              <div className="input-field-container">
                <div className="input-field-label">School</div>
                <Form.Input
                  name="school"
                  placeholder="Please Enter..."
                  required={true}
                  validationErrors={{
                    isDefaultRequiredValue: `* Place is required field.`,
                  }}
                  className="form-input"
                  errorLabel={errorLabel}
                />
              </div>
              <Form.Checkbox
                name="degreeAttained"
                label="Degree Attained"
                onChange={() => this.setState({ degree: !this.state.degree })}
                checked={this.state.degree}
              />
              <div className="education-button">
                <Button type="button" onClick={() => this.setState({ showCreate: false })}>
                  CANCEL
                </Button>
                <Button
                  type="button"
                  onClick={e => {
                    this.form.formsyForm.submit();
                  }}
                  form="create-education-form"
                >
                  SAVE
                </Button>
              </div>
            </Form>
          </div>
        )}
        {!this.state.showCreate && (
          <div className="add-question">
            <Button type="button" onClick={() => this.setState({ showCreate: true })}>
              + ADD
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default AddEducation;
