// @ts-nocheck
import { withFormsy } from 'formsy-react';
import React, { cloneElement } from 'react';
import { Checkbox } from 'semantic-ui-react';

interface Props {
  isValid: () => any;
  getValue: () => any;
  isPristine: () => any;
  isFormSubmitted: () => any;
  errorLabel: any;
  validationErrors: any;
  checkEmail: (checkEmail: boolean) => void;
  value: any;
  standby?: any;
  isAnonymous: boolean;
}

interface State {
  items: object[];
}

class ProviderCheckboxes extends React.Component<Props, State> {
  public state = {
    items: [
      { label: 'SMS', key: 'twilio' },
      { label: 'Personal Email', key: 'email' },
      { label: 'Compass Email', key: 'compassEmail' },
    ],
  };

  public componentDidMount = () => {
    this.props.setValue(undefined);
  };

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    const isEmail = this.props.getValue() && this.props.getValue()[0] === 'email';
    if (this.props.isAnonymous && !isEmail) {
      this.props.setValue(['email']);
    }
  }

  public changeCheckbox = (event: any) => {
    const key = event.currentTarget.childNodes[0].name;
    const value = this.props.getValue() || [];

    const newValue = value.includes(key) ? value.filter((val) => val !== key) : [...value, key]

    newValue.includes('email') || newValue.includes('compassEmail')
      ? this.props.checkEmail(true)
      : this.props.checkEmail(false);

    this.props.setValue(newValue.length ? newValue : undefined)
  };

  public render() {
    const { isValid, errorLabel, validationErrors, isFormSubmitted, getValue } = this.props;
    const error = isFormSubmitted() && !isValid();

    const value = getValue() || [];
    return (
      <>
        <div className="provider-group-container">
          {this.state.items.map((el: any, index: number) => (
            <Checkbox
              name={el.key}
              label={el.label}
              checked={value.includes(el.key)}
              onChange={this.changeCheckbox}
              disabled={
                el.label === 'SMS' && this.props.standby && this.props.standby.closedAt
                  ? true
                  : false
              }
              key={index}
            />
          ))}
        </div>
        {error && errorLabel && cloneElement(errorLabel, {}, validationErrors.isChecked)}
      </>
    );
  }
}

export default withFormsy(ProviderCheckboxes);
