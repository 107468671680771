// @ts-nocheck
import { Form } from 'formsy-semantic-ui-react';
import moment from 'moment';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { Button, Checkbox, Image, Modal } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import { uploads } from '../../config';
import services from '../../services';
import { parseDateToDateStringWithFormat } from '../../utils/datesHelper';
import DatePickerInput from '../datepicker-input/DatePickerInput';
import ErrorLabel from '../error-label/ErrorLabel';
import { MessageSpiner } from '../loading-spinner/MessageSpinner';
import CustomIconicButton from './../adjuster-list/CustomIconicButton';
import UploadZone from './../file-uploader/UploadZone';

interface Props {
  showModal: (show: boolean, id?: number) => void;
  list: () => void;
  show: boolean;
  item: object;
}

interface State {
  showModal: boolean;
  showConfirm: boolean;
  showDate: boolean;
  file: string | ArrayBuffer | null;
  fileError: boolean;
  filesError: boolean;
  acknowledge: boolean;
  fileType: string;
  statesOptions: object[];
  files: any[];
}

class AddLicenseModal extends React.Component<Props, State> {
  public state = {
    showModal: false,
    showConfirm: false,
    statesOptions: [],
    showDate: false,
    file: '',
    fileType: '',
    fileError: false,
    filesError: false,
    files: [],
    acknowledge: false,
  };

  public fileInputRef = React.createRef();

  public componentDidMount = async () => {
    const { item } = this.props;
    const request = await services.api.account.getSettingsData('paperwork');
    if (request.isSuccess) {
      const statesOptions = request.data.list.map((el: any, index: any) => {
        const element = { text: el.name, value: el.name };
        return element;
      });
      this.setState({
        statesOptions,
        files: item ? item.files : [],
        acknowledge: item ? item.allFilesReceived : false,
        showDate: item && item.type === 'BG Check Completed',
      });
    }
  };

  public componentDidUpdate(prevProps: Props) {
    if (prevProps !== this.props) {
      this.setState({
        files: this.props.item ? this.props.item.files : [],
        acknowledge: this.props.item ? this.props.item.allFilesReceived : false,
        showDate: this.props.item && this.props.item.type === 'BG Check Completed',
      });
    }
  }

  public deleteFile = (id: number) => {
    const { files } = this.state;
    files.splice(id, 1);
    this.setState({ files });
  };

  public handleFileDrop = (items: any) => {
    const { files } = this.state;
    items.forEach(async (file: any) => {
      if (file === undefined) {
        this.setState({ fileError: true });
      } else {
        if (file.size > uploads.maxFileSizeForPaperworks) {
          toast.warn('Please make sure the files are under 10MB.');
        } else {
          const resp = await services.api.account.getPaperworkSignedUrl({
            name: file.name,
            type: file.type,
            size: file.size,
            public: true,
          });
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = async (e: any) => {
            if (resp.isSuccess) {
              files.push({
                file: file,
                fields: resp.data.url,
              });
            }

            this.setState({
              files,
              filesError: files.length === 0,
            });
          };
        }
      }
    });
  };

  public closeModal = () => {
    this.setState({ fileError: false });
    this.props.showModal(false);
    this.props.list();
  };

  public manageDocumentType = (e: any, data: any) => {
    if (data.value === 'BG Check Completed') {
      this.setState({ showDate: true });
    } else {
      this.setState({ showDate: false });
    }
  };

  public render() {
    const errorLabel = <ErrorLabel />;
    const { item } = this.props;
    return (
      <Modal
        size="tiny"
        className="licenses-modal paperwork-file-uploader"
        open={this.props.show}
        closeIcon={<Image src={closeIcon} className="icon" onClick={this.closeModal} />}
      >
        <Modal.Header>Add document</Modal.Header>
        <Form
          onInvalidSubmit={(model: any) => {
            const { files } = this.state;
            if (files.length === 0) {
              this.setState({ filesError: true });
              return;
            }
          }}
          onValidSubmit={async (model: any) => {
            const { files } = this.state;
            if (files.length === 0) {
              this.setState({ filesError: true });
              return;
            }
            for (const file of files) {
              if (!file.fields) {
                continue;
              }
              const data = file.fields.fields;
              const obj = Object.assign({}, data, {
                file: file.file,
              });
              const formData = new FormData();
              for (const key in obj) {
                if (undefined === obj[key]) {
                  continue;
                }
                formData.append(key, obj[key]);
              }
              await trackPromise(
                services.api.file.postFile(file.fields.url, formData),
                'paperwork-area'
              );
            }
            const objectsArray = files.map((file: any) => {
              return file.fields ? file.fields.fields.key : file.key;
            });
            const editPaperwork = await trackPromise(
              services.api.adjuster.editPaperwork(
                item.id,
                model.type,
                objectsArray,
                this.state.acknowledge,
                model.runDate ? parseDateToDateStringWithFormat(model.runDate) : undefined
              ),
              'paperwork-area'
            );
            if (editPaperwork.isSuccess) {
              this.closeModal();
            } else {
              toast.dismiss();
              toast.error(
                editPaperwork.data.errors
                  ? editPaperwork.data.errors[0].msg
                  : `Error ${editPaperwork.status}`
              );
              console.log(editPaperwork);
            }
          }}
        >
          <Modal.Content style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
            <MessageSpiner area="paperwork-area" />
            <div className="input-field-container">
              <div className="input-field-label">Document Type</div>
              <Form.Dropdown
                search={true}
                name="type"
                options={this.state.statesOptions}
                placeholder="Please Select"
                instantValidation={false}
                required={true}
                value={item ? item.type : undefined}
                onChange={this.manageDocumentType}
                validationErrors={{
                  isDefaultRequiredValue: `* Document Type is required field.`,
                }}
                className="form-input-dropdown"
                errorLabel={errorLabel}
              />
            </div>

            {this.state.showDate && (
              <div className="input-field-container">
                <div className="input-field-label">Run Date</div>
                <DatePickerInput
                  name="runDate"
                  defaultValue=""
                  required={true}
                  value={item && item.runDate ? item.runDate : ''}
                  validations={{ isValidMax: moment(), isValidDate: true }}
                  validationErrors={{
                    isDefaultRequiredValue: `* Run Date is required field.`,
                    isValidMax: `* Run Date should not be later than current date.`,
                    isValidDate: '* Date format is invalid.',
                  }}
                  errorLabel={errorLabel}
                />
              </div>
            )}

            <div className="files-paperwork-zone">
              {this.state.files.map((el: any, index: number) => {
                return (
                  <div className="paperwork-uloaded-file" key={index}>
                    <span className="uploaded-file-ttl">{el.file ? el.file.name : el.key}</span>
                    <span>
                      <CustomIconicButton
                        image={closeIcon}
                        popup="Remove"
                        onPress={() => this.deleteFile(index)}
                      />
                    </span>
                  </div>
                );
              })}
              <UploadZone
                onDrop={this.handleFileDrop}
                accept="image/jpeg, image/png, application/pdf"
              />
            </div>
            <div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
              {this.state.filesError && <ErrorLabel>* Paperwork file is required.</ErrorLabel>}
            </div>
            <div style={{ marginTop: '0.5rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
              <Checkbox
                label="I acknowledged that all files were received"
                checked={this.state.acknowledge}
                onChange={() => this.setState({ acknowledge: !this.state.acknowledge })}
              />
            </div>
          </Modal.Content>

          <div className="modal-button-footer">
            <Button
              type="button"
              style={{ width: '115px' }}
              className="secondary-button"
              secondary={true}
              onClick={this.closeModal}
            >
              CANCEL
            </Button>
            <Button style={{ width: '115px' }} primary={true}>
              UPDATE
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default AddLicenseModal;
