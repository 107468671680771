// @ts-nocheck
import moment from 'moment';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Dropdown, Image, Popup } from 'semantic-ui-react';
import removeUserIcon from '../../assets/images/Blue/remove-user.svg';
import selectUserIcon from '../../assets/images/Blue/select-user.svg';
import noAvatar from '../../assets/images/no-avatar.svg';
import noteIcon from '../../assets/images/White/add-note.svg';
import { uploads } from '../../config';
import { standbyActions } from '../../features/standby';
import { IUser } from '../../models';
import services from '../../services';
import ContactedModal from './modals/ContactedModal';
import UserStatusLabel from './UserStatusLabel';

interface Props {
  user: IUser;
  adjuster: object;
  activeStandbyId: any;
  standbyList: any;
  match: any;
  getStandbyAdjusters: typeof standbyActions.getStandbyAdjusters;
}

interface State {
  adjuster: object;
  activeList: any;
  userStatus: string;
  showContactedModal: boolean;
}

class StandbyUserHeader extends React.Component<Props, State> {
  public state = {
    adjuster: {},
    activeList: { id: 0 },
    showContactedModal: false,
    userStatus: 'contacted',
  };

  public componentDidMount = () => {
    this.selectActiveList();
  };

  public selectActiveList = async () => {
    const response = await services.api.standby.getStandbyListDetails(this.props.match.params.id);
    if (response.isSuccess) {
      this.setState({ activeList: response.data });
    } else {
      console.log(response);
      toast.dismiss();
      toast.error(response.data.errors ? response.data.errors[0].msg : `Error ${response.status}`);
    }
  };

  public showContactedModal = (show: boolean) => {
    this.setState({ showContactedModal: show });
  };

  public updateData = () => {
    this.props.getStandbyAdjusters(this.props.match.params.id);
  };

  public render() {
    const { adjuster, user } = this.props;
    const { activeList } = this.state;
    return (
      <>
        <div className="standby-user-info-container">
          <div className="user-main-info-content">
            <Image
              src={adjuster.avatar ? uploads.s3BucketAddress + adjuster.avatar : noAvatar}
              avatar={true}
            />
            <div style={{ marginLeft: '18px', width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 700,
                  fontSize: '22px',
                  marginBottom: '1rem',
                  width: '100%',
                }}
              >
                {adjuster.preferredName ? adjuster.preferredName : adjuster.firstName}{' '}
                {adjuster.lastName} <UserStatusLabel adjuster={adjuster} />
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ marginBottom: '0.1rem', fontSize: '13px' }}>
                    {adjuster.positions.join(', ')}
                  </div>
                  <div style={{ fontSize: '13px' }}>
                    {adjuster.city}, {adjuster.state} {!adjuster.isGuest && '/'} {Array.isArray(adjuster.languages) && adjuster.languages.join(', ')}
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      textAlign: 'right',
                    }}
                  >
                    <div style={{ marginBottom: '0.1rem', fontSize: '13px', fontWeight: 700 }}>
                      Sent On: {moment(adjuster.updatedAt).format('MM/DD/YYYY, hh:mm A ')}
                    </div>
                    <div style={{ fontSize: '13px' }}>{adjuster.email}</div>
                  </div>
                  <div style={{ display: 'flex', marginLeft: '1rem' }}>
                    {user.role !== 'Read Only Office Admin' && (
                      <Popup
                        trigger={
                          <Button
                            disabled={activeList.closedAt}
                            onClick={() =>
                              this.setState({ userStatus: 'contacted' }, () =>
                                this.showContactedModal(true)
                              )
                            }
                            className="sbl-action-button"
                          >
                            <Image src={selectUserIcon} />
                          </Button>
                        }
                        basic={true}
                        size="mini"
                        content="Mark as Contacted"
                      />
                    )}
                    {user.role !== 'Read Only Office Admin' && (
                      <Popup
                        trigger={
                          <Button
                            disabled={activeList.closedAt}
                            onClick={() =>
                              this.setState({ userStatus: 'removed' }, () =>
                                this.showContactedModal(true)
                              )
                            }
                            className="sbl-action-button"
                          >
                            <Image src={removeUserIcon} />
                          </Button>
                        }
                        basic={true}
                        size="mini"
                        content="Remove from list"
                      />
                    )}
                    {user.role !== 'Read Only Office Admin' && !adjuster.isGuest && (
                      <Popup
                        trigger={
                          <Button
                            disabled={activeList.closedAt}
                            onClick={() => {
                              this.setState({ userStatus: 'note' }, () =>
                                this.showContactedModal(true)
                              );
                            }}
                            primary={true}
                            className="sbl-action-button"
                          >
                            <Image src={noteIcon} />
                          </Button>
                        }
                        basic={true}
                        size="mini"
                        content="Add Note"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {adjuster.isGuest ? '' : (
            <div className="standby-user-skills-info">
              <div>
                <p>Licenses</p>
                {(adjuster.licenses || [])
                  .map((el, index) => {
                    if (index === 4) {
                      return;
                    } else {
                      return el.license + ' ' + el.state;
                    }
                  })
                  .join(', ')}
              </div>
              <div>
                <p>Certifications</p>
                {(adjuster.certifications || []).map(el => el.certification + ' ' + el.type).join(', ')}
              </div>
              <div>
                <p>Experience</p>
                {(adjuster.experiences || []).map((el: any, index: number) => {
                  return (
                    <span key={index}>
                      {el.name}: {el.years} y
                    </span>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <ContactedModal
          standbyId={this.state.activeList.id}
          user={this.props.adjuster}
          show={this.state.showContactedModal}
          status={this.state.userStatus}
          showContactedModal={this.showContactedModal}
          updateData={this.updateData}
        />
      </>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  activeStandbyId: state.standby.standby.activeStandbyId,
  standbyList: state.standby.standby.standbyList,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getStandbyAdjusters: standbyActions.getStandbyAdjusters,
    },
    dispatch
  );

export default connect<Props, State>(
  mapStateToProps,
  mapDispatchToProps
)(StandbyUserHeader);
