// @ts-nocheck
import React from 'react';
import { Grid } from 'semantic-ui-react';
import { LoadingSpiner } from '../loading-spinner/LoadingSpinner';
import MaterialList from './material-list/MaterialList';
import SwitchViewMaterial from './switch-view-material/SwitchViewMaterial';
import MaterialActionMenu from './material-action-menu/MaterialActionMenu';
import FolderListRecursive from './folderListRecursive';

interface Props {}

interface State {}

export default class TrainingCenterFilesTab extends React.Component<Props, State> {
  public render() {
    return (
      <React.Fragment>
        <Grid columns="2">
          <LoadingSpiner area="training-center-files-area" />
          <Grid.Row className="files-grid-row questions-grid-row ">
            <Grid.Column className=" folder-list-column mobile-menu-material" width={3}>
              <SwitchViewMaterial />
            </Grid.Column>
            <Grid.Column className=" folder-list-column mobile-menu-material" width={13}>
              <MaterialActionMenu />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="files-grid-row questions-grid-row first">
            {/* <Grid.Column className=" folder-list-column mobile-menu-material first" width={3}>
              <FolderListTree />
            </Grid.Column> */}
            <Grid.Column className=" folder-list-column mobile-menu-material first" width={3}>
              <FolderListRecursive init={true} />
            </Grid.Column>
            {/* <Grid.Column className=" folder-list-column mobile-menu-material first" width={3}>
              <TrainingCenterFoldersList />
            </Grid.Column> */}
            <Grid.Column className=" folder-list-column" width={13}>
              <MaterialList />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}
