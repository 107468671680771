// @ts-nocheck
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid, Icon, Image, Pagination, Search } from 'semantic-ui-react';
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg';
import { standbyActions } from '../../features/standby';
import { IUser } from '../../models';
import services from '../../services';
import CreateProfDataItem from '../layout-professional-data/CreateProfDataItem';
import ProfDataItem from '../layout-professional-data/ProfDataItem';
import { NoResults } from '../no-results-placeholder/NoResults';
import CustomEventsModal from './CustomEventsModal';

interface Props {
  user: IUser;
  showCustomEventsModal: typeof standbyActions.showCustomEventsModal;
}

interface State {
  activeItems: object[];
  searchString: string;
  paginationPage: number;
  totalPageItems: number;
  showPagination: boolean;
  totalPages: number;
  itemValidationError: number | null;
}

class ProfessionalData extends React.Component<Props, State> {
  public state = {
    activeItems: [],
    searchString: '',
    paginationPage: 0,
    totalPageItems: 0,
    showPagination: false,
    totalPages: 1,
    itemValidationError: null,
  };

  public async componentDidMount() {
    this.getItems();
  }

  public getItems = async () => {
    const params = Object.assign(
      {},
      { search: this.state.searchString },
      { page: this.state.paginationPage },
      { pageSize: 20 }
    );
    const response = await services.api.admin.getCustomEvents(params);
    if (response.isSuccess) {
      // this.getPagination();
      this.setState({
        activeItems: response.data.list,
        totalPageItems: response.data.list.length,
        totalPages: Math.ceil(response.data.count / 20),
        showPagination: response.data.count > 20,
      });
    } else {
      toast.dismiss();
      toast.error(response.data.errors[0].msg);
    }
  };

  public handlePagination = (e: any, data: any) => {
    this.setState({ paginationPage: data.activePage - 1 }, () => this.getItems());
  };

  public handleSearchList = (event?: React.SyntheticEvent, data: any) => {
    this.setState({ searchString: data.value, paginationPage: 0 }, () => this.getItems());
  };

  public editItem = async (eventId, name) => {
    if (!!name.trim()) {
      const response = await services.api.admin.updateEvent(eventId, name);
      if (response.isSuccess) {
        this.getItems();
        toast.dismiss();
        toast.success(`Item was successfuly updated!`);
      } else {
        toast.dismiss();
        toast.error(response.data.errors[0].msg);
      }
    } else {
      this.setState({ itemValidationError: eventId });
    }
  };

  public deleteItem = async (eventId, name) => {
    const response = await services.api.admin.deleteEvent(eventId);
    if (response.isSuccess) {
      toast.dismiss();
      toast.success(`Item ${name} was successfully deleted!`);
      if (this.state.totalPageItems === 1) {
        this.setState({ pagginationPage: 0 }, () => this.getItems());
      } else {
        this.getItems();
        this.handleSearchList(undefined, { value: this.state.searchString });
      }
    } else {
      toast.dismiss();
      toast.error(response.data.errors[0].msg);
    }
  };

  public createItem = async (model: { name: string }, resetForm) => {
    const response = await services.api.admin.createEvent({ name: model.name });
    if (response.isSuccess) {
      resetForm();
      this.getItems();
      toast.dismiss();
      toast.success(`Item ${model.name} was successfully created!`);
    } else {
      toast.dismiss();
      toast.error(response.data.errors[0].msg);
    }
  };

  public render() {
    const { user, showCustomEventsModal } = this.props;
    const { searchString } = this.state;
    return (
      <Grid>
        <Grid.Row className="questions-grid-row">
          <Grid.Column>
            <div className="manage-questions-header">
              <div>List</div>
              <div className="questions-actions-buttons">
                <Search
                  input={{ iconPosition: 'left' }}
                  icon={
                    <Icon>
                      <Image src={searchIcon} />
                    </Icon>
                  }
                  placeholder="Search..."
                  onSearchChange={this.handleSearchList}
                  value={searchString}
                  showNoResults={false}
                />
              </div>
            </div>
            {this.props.user.role !== 'Read Only Office Admin' && (
              <CreateProfDataItem
                user={user}
                type="Custom Events"
                getItems={this.getItems}
                onCreate={this.createItem}
              />
            )}
            {this.state.activeItems.length !== 0 ? (
              this.state.activeItems.map((el: any, index: number) => {
                return (
                  <ProfDataItem
                    user={this.props.user}
                    item={el}
                    key={index}
                    index={index}
                    showAdjustersModal={() => showCustomEventsModal(true, el.id)}
                    deleteItem={() => {
                      this.deleteItem(el.id, el.name);
                    }}
                    editItem={(i, name) => this.editItem(el.id, name)}
                    clearError={() => this.setState({ itemValidationError: null })}
                    error={this.state.itemValidationError === el.id}
                  />
                );
              })
            ) : (
              <NoResults />
            )}
            {this.state.showPagination && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  marginTop: '1rem',
                }}
              >
                <Pagination
                  totalPages={this.state.totalPages}
                  boundaryRange={1}
                  activePage={this.state.paginationPage + 1}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  prevItem={
                    this.state.paginationPage === 0
                      ? false
                      : {
                          'aria-label': 'Previous item',
                          content: 'PREVIOUS',
                        }
                  }
                  nextItem={
                    this.state.paginationPage === this.state.totalPages - 1
                      ? false
                      : {
                          'aria-label': 'Next item',
                          content: 'NEXT',
                        }
                  }
                  siblingRange={1}
                  onPageChange={this.handlePagination}
                />
              </div>
            )}
            <CustomEventsModal />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showCustomEventsModal: standbyActions.showCustomEventsModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfessionalData);
