// @ts-nocheck
import { withFormsy } from 'formsy-react';
import React, { cloneElement } from 'react';
import { Dropdown } from 'semantic-ui-react';

interface Props {
  isValid: () => any;
  getValue: () => any;
  setValue: (value: any) => any;
  isPristine: () => any;
  isFormSubmitted: () => any;
  errorLabel: any;
  validationErrors: any;
  value: string;
  disabled: boolean;
  getUsers: (params?: object) => void;
  onChange: () => void;
  users: object[];
}

interface State {
  usersOpt: object[];
}

class SMToField extends React.Component<Props, State> {
  public state = {
    usersOpt: [],
  };

  public componentDidMount = () => {
    this.props.setValue(undefined);
    this.props.getUsers();
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.users !== this.props.users) {
      const modUsers = this.props.users.map(el => ({
        value: el.email,
        text: `${el.preferredName ? el.preferredName : el.firstName} ${el.lastName}`,
      }));
      this.setState({ usersOpt: modUsers });
    }
  };

  public render() {
    const {
      isValid,
      errorLabel,
      validationErrors,
      isFormSubmitted,
      disabled,
      onChange,
      value,
    } = this.props;
    const error = isFormSubmitted() && !isValid();
    return (
      <>
        <Dropdown
          search={true}
          disabled={disabled}
          selectOnBlur={false}
          placeholder="Please Select"
          className="standby-dropdown admin-adjusters-dropdown"
          options={this.state.usersOpt}
          onChange={onChange}
          value={value}
        />
        {error &&
          errorLabel &&
          cloneElement(errorLabel, {}, validationErrors.isDefaultRequiredValue)}
      </>
    );
  }
}

export default withFormsy(SMToField);
