// @ts-nocheck
import MyTypes from 'MyTypes';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Input, Popup } from 'semantic-ui-react';
import { IUser } from '../../../models/user';
import iconArrow from '../../../assets/images/ui-dropdown.svg';
import AssignFolderAdjusterContent from './AssignFolderAdjusterContent';
import { accountActions } from '../../../features/account';
import './styles.css';
import { user } from '../../../services/api-service';

interface Props {
    user: IUser;
    getAssignedFolders: typeof accountActions.getAssignedFolders;
    getAllFolders: typeof accountActions.getAllFolders;
    selectFolder: typeof accountActions.selectFolder;
    saveSelectedFolders: typeof accountActions.saveSelectedFolders;
    setSelectedFolders: typeof accountActions.setSelectedFolders;
    selectedFolders: number[];
    allFolders: Array<any>;
    assignedFolders: number[];
    adjusterId: number;
}

interface State {
    open: boolean,
    valueSearch: '',
}

class AssignFolderAdjuster extends React.Component<Props, State> {
    public state = {
        open: false,
        valueSearch: '',
    };

    public componentDidMount = () => {
        if (this.props.adjusterId) {
            this.props.getAllFolders();
            this.props.getAssignedFolders(this.props.adjusterId);
        }
    };

    public setOpen() {
        this.setState({ open: true });
    }

    public setClose() {
        this.props.setSelectedFolders(this.props.assignedFolders);
        this.setState({ open: false });
        this.setState({ valueSearch: '' })
    }

    public onChangeValueSearch(e: any) {
        this.setState({ valueSearch: e.target.value })
    }

    public selectFolder(id: number) {
        this.props.selectFolder(id);
    }

    public renderTrigger() {

        const { assignedFolders } = this.props;
        const placeHolder = assignedFolders.length >= 2 ? `${assignedFolders.length} Categories assigned` : `${assignedFolders.length} Category assigned`;
        return (<Input
            className="inputTrigger"
            onClick={() => {
                this.setOpen();
            }}
            iconPosition="left"
            placeholder={placeHolder}
            disabled={this.props.user.isReadOnlyAdmin}
            id="inputMultiplyAdjusterFolder"
            maxLength={10}
        >
            <input
                value={this.state.valueSearch}
                className="inputTr"
                onChange={e => { this.onChangeValueSearch(e) }}
                autoComplete="off"
            />
            <div className="iconArrow" onClick={() => { this.setOpen() }}>
                <img src={iconArrow} />
            </div>
        </Input>);
    }

    public save() {
        const selectedFolders = this.props.selectedFolders;
        this.props.saveSelectedFolders(selectedFolders, this.props.adjusterId);
        this.setState({ valueSearch: '', open: false })
    }

    public render() {
        const elem = document.getElementById('inputMultiplyAdjusterFolder');
        const width = elem ? elem.offsetWidth : 400;
        const options = this.props.allFolders.map(t => {
            return {
                id: t.id,
                text: `${t.parentName ? t.parentName + ' / ' : ''}${t.name}`
            };
        });
        return (
            <Popup
                content={<AssignFolderAdjusterContent
                    user={this.props.user}
                    options={options}
                    dataSource={this.props.selectedFolders}
                    selectFolder={(id) => this.props.selectFolder(id)}
                    valueSearch={this.state.valueSearch}
                    save={() => this.save()}
                    selectedFolders={this.props.selectedFolders}
                    assignedFolders={this.props.assignedFolders}
                    close={() => { this.setClose() }}
                />}
                on="click"
                position="bottom left"
                open={this.state.open}
                className={`popupmultiply`}
                basic={true}
                trigger={this.renderTrigger()}
                style={{ width, maxWidth: width, minWidth: width }}
            />
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    user: state.account.account.user,
    selectedFolders: state.account.adjusterFolder.selectedFolders,
    allFolders: state.account.adjusterFolder.allFolders,
    assignedFolders: state.account.adjusterFolder.assignedFolders,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getAllFolders: accountActions.getAllFolders,
            getAssignedFolders: accountActions.getAssignedFolders,
            selectFolder: accountActions.selectFolder,
            saveSelectedFolders: accountActions.saveSelectedFolders,
            setSelectedFolders: accountActions.setSelectedFolders,
        },
        dispatch
    );

export default connect<Props, State>(
    mapStateToProps,
    mapDispatchToProps
)(AssignFolderAdjuster);
