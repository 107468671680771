// @ts-nocheck
import moment from 'moment';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Dimmer, Image, Loader, Menu } from 'semantic-ui-react';
import adjustersIcon from '../../assets/images/SideIcons/icn-adjusters.svg';
import dashboardIcon from '../../assets/images/SideIcons/icn-dashboard.svg';
import financesIcon from '../../assets/images/SideIcons/icn-finances.svg';
import messagesIcon from '../../assets/images/SideIcons/icn-messages.svg';
import settingsIcon from '../../assets/images/SideIcons/icn-settings.svg';
import claimIcon from '../../assets/images/SideIcons/icn-claim.svg';
import trainingCenterIcon from '../../assets/images/SideIcons/icn-training-center.svg';
import staffIcon from '../../assets/images/SideIcons/icn-staff.svg';
import stbyIcon from '../../assets/images/SideIcons/icn-stby-lists.svg';
import '../../assets/styles/Sidebar.css';
import config from '../../config';
import { messagesActions } from '../../features/messages';
import { standbyActions } from '../../features/standby';
import services from '../../services';
import { IUser } from './../../models/user';

interface Props {
  pathname: string;
  user: IUser;
  resetStandbyFilter: typeof standbyActions.resetStandbyFilter;
  resetMessagesFilter: typeof messagesActions.resetMessagesFilter;
}

interface State {
  activeItem: string;
}

class Sidebar extends React.Component<Props, State> {
  public state: State = {
    activeItem: 'Dashboard',
  };

  public chooseActive = location => {
    if (location.includes('/messages')) {
      return 'Messages';
    } else if (location.includes('/stand-by-lists')) {
      return 'Standby Lists';
    } else {
      switch (location) {
        case '/':
          return 'Dashboard';
        case '/staff':
          return 'Staff';
        case '/settings':
          return 'Settings';
        case '/finances':
          return 'Finances';
        case '/adjusters':
          return 'Adjusters';
        case '/adjuster-profile':
          return 'Adjusters';
        case '/training-center':
          return 'Training Center';
        default:
          return '';
      }
    }
  };

  public componentDidMount() {
    this.setState({ activeItem: this.chooseActive(this.props.pathname) });
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.pathname !== this.props.pathname) {
      this.setState({ activeItem: this.chooseActive(this.props.pathname) });
    }
  }

  public handleItemClick = (e: any, { name }) => {
    this.props.resetStandbyFilter();
    this.props.resetMessagesFilter();

    switch (name) {
      case 'Dashboard':
        services.router.goto('/');
        break;
      case 'Staff':
        services.router.goto(services.router.url.staff);
        break;
      case 'Settings':
        services.router.goto(services.router.url.settings);
        break;
      case 'Standby Lists':
        services.router.goto(services.router.url.standby);
        break;
      case 'Adjusters':
        services.router.goto(services.router.url.adjusters);
        break;
      case 'Finances':
        services.router.goto(services.router.url.finances);
        break;
      case 'Messages':
        services.router.goto(services.router.url.messages);
        break;
      case 'Training Center':
        services.router.goto(services.router.url.training_center);
        break;
      default:
        return;
    }
  };

  public render() {
    const { user } = this.props;
    if (!user) {
      return (
        <Dimmer active={true} page={true}>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      );
    }

    const { activeItem } = this.state;
    return (
      <div className="sidebar-menu-container">
        <Menu vertical={true} className="sidebar-menu">
          <Menu.Item
            name="Dashboard"
            active={activeItem === 'Dashboard'}
            onClick={() => services.router.goto(services.router.url.homepage)}
          >
            <Image className="icon side-icon" src={dashboardIcon} />
            Dashboard
          </Menu.Item>
          <Menu.Item
            name="Standby Lists"
            active={activeItem === 'Standby Lists'}
            onClick={this.handleItemClick.bind(this)}
          >
            <Image className="icon side-icon" src={stbyIcon} />
            Standby Lists
          </Menu.Item>
          <Menu.Item
            name="Adjusters"
            active={activeItem === 'Adjusters'}
            onClick={this.handleItemClick.bind(this)}
          >
            <Image className="icon side-icon" src={adjustersIcon} />
            Adjusters
          </Menu.Item>
          <Menu.Item
            name="Messages"
            active={activeItem === 'Messages'}
            onClick={this.handleItemClick.bind(this)}
          >
            <Image className="icon side-icon" src={messagesIcon} />
            Messages
          </Menu.Item>
          {user.financeAccess && (
            <Menu.Item
              name="Finances"
              active={activeItem === 'Finances'}
              onClick={this.handleItemClick.bind(this)}
            >
              <Image className="icon side-icon" src={financesIcon} />
              Finances
            </Menu.Item>
          )}
          {user.role === 'Super Admin' && (
            <Menu.Item
              name="Staff"
              active={activeItem === 'Staff'}
              onClick={this.handleItemClick.bind(this)}
            >
              <Image className="icon side-icon" src={staffIcon} />
              Staff
            </Menu.Item>
          )}
          <Menu.Item
            name="Training Center"
            active={activeItem === 'Training Center'}
            onClick={this.handleItemClick.bind(this)}
          >
            <Image className="icon side-icon" src={trainingCenterIcon} />
            Training Center
          </Menu.Item>
          <Menu.Item
            name="Settings"
            active={activeItem === 'Settings'}
            onClick={this.handleItemClick.bind(this)}
          >
            <Image className="icon side-icon" src={settingsIcon} />
            Settings
          </Menu.Item>
          <Menu.Item
            target="_blank"
            href="https://comtrack2.compassadjusters.com/"
            name="Comtrack 2.0"
            active={activeItem === 'Comtrack 2.0'}
          >
            <Image className="icon side-icon" src={claimIcon} />
            Comtrack 2.0
          </Menu.Item>
        </Menu>
        <div>
          <p>Version: {config.common.version}</p>
          <p>Copyright © {moment().format('YYYY')}</p>
          <p>Compass Adjusting Services</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      resetStandbyFilter: standbyActions.resetStandbyFilter,
      resetMessagesFilter: messagesActions.resetMessagesFilter,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
