// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Confirm, Icon, Image, Input, Popup } from 'semantic-ui-react';
import iconClose from '../../../assets/images/Blue/cancel.svg';
import icon from '../../../assets/images/Common/icn-search-grey.svg';
import icnExcluded from '../../../assets/images/icn-excluded.svg';
import icnIncluded from '../../../assets/images/icn-included.svg';
import iconArrow from '../../../assets/images/ui-dropdown.svg';
import services from '../../../services';
import { getDeploymentsFilters } from '../../../services/api/account';
import Content from './Content';
import './styles.css';

function capitalizeWords(inputArray: string[]) {
  // Split the input string into an array of words

  const arr = inputArray.toString().split(',')
  const hasActiveCertOnly = arr.find(s => s === 'active-cert');
  const hasActiveLicenseOnly = arr.find(s => s === 'active-license');

  const words = arr.filter((s) => s !== 'active-cert' && s !== 'active-license');

  // Capitalize the first letter of each word and trim any extra whitespace
  const capitalizedWords = words.map(word => {
    const trimmedWord = word.trim();
    return trimmedWord.charAt(0).toUpperCase() + trimmedWord.slice(1);
  });

  if (hasActiveCertOnly) capitalizedWords.unshift('Active Certifications')
  if (hasActiveLicenseOnly) capitalizedWords.unshift('Active License')

  // Join the capitalized words back together with a comma and space
  const result = capitalizedWords.join(', ');

  return result;
}

const Trigger = ({
  placeholder = '',
  setOpen,
  dataSource,
  excludeIcn,
  name,
  valueSearch,
  onChangeValueSearch,
  onChangeIsFocus,
  maxLength,
}) => (
  <Input
    className="inputTrigger"
    onClick={() => {
      onChangeIsFocus();
      setOpen();
    }}
    iconPosition="left"
    placeholder={placeholder}
    id="inputMultiply"
    name={name}
    maxLength={maxLength}
  >
    <Icon className="iconSearch">
      <Image src={icon} />
    </Icon>
    <input
      value={!dataSource.length ? capitalizeWords(valueSearch) : capitalizeWords(dataSource)}
      className="inputTr"
      style={{ width: '80%' }}
      onChange={e => !dataSource.length && onChangeValueSearch(e)}
      autoComplete="off"
    />
    <div className="iconArrow" onClick={setOpen} style={{ backgroundColor: 'white' }}>
      <img src={iconArrow} />
    </div>
    {excludeIcn !== undefined && (
      <img src={excludeIcn ? icnExcluded : icnIncluded} className="iconExclude" />
    )}
  </Input>
);

const SearchMultipleInput = props => {
  const elem = document.getElementById('inputMultiply');
  const {
    name,
    filterLogs,
    value = [],
    open,
    setOpen,
    formFields,
    setField,
    resetField,
    isCustom,
    options,
    user,
    updateDeployments,
  } = props;
  const [dataSource, $dataSource] = useState(value.filter(i => i !== 'exclude').filter(Boolean));
  const [active, $active] = useState('include');
  const [activeOnly, setActiveOnly] = useState(false);
  const [width, $width] = useState('210px');
  const [valueSearch, $valueSearch] = useState('');
  const [isFocus, $isFocus] = useState(false);
  const [showConfirm, $showConfirm] = useState(false);
  const [itemToRemove, $itemToRemove] = useState({});

  const onChangeIsFocus = () => $isFocus(!isFocus);

  const onChangeValueSearch = e => $valueSearch(e.target.value);

  const excludeIcn = value.length ? (value.includes('exclude') ? true : false) : undefined;

  useEffect(() => {
    if (!formFields[name]) {
      $dataSource([]);
    }
  }, [props.filter]);

  const setWidth = () => $width(elem ? elem.offsetWidth : '210px');

  useEffect(() => {
    window.addEventListener('resize', setWidth);
    return () => window.removeEventListener('resize', setWidth);
  });

  useEffect(() => {
    if (elem) {
      $width(elem.offsetWidth);
    }
  }, [elem]);

  useEffect(() => {
    $dataSource(value.filter(i => i !== 'exclude').filter(Boolean));
    $active(value.includes('exclude') ? 'exclude' : 'include');
    setActiveOnly(value.includes('active-cert') || value.includes('active-license') ? true : false);
  }, [value]);

  const parseDeploymentsFilters = (isExclude, source) => {
    const standbyListDeployments: string[] = [];
    const eventDeployments: string[] = [];
    source.forEach((item: string) => {
      const option = options.find(o => o.value === item);
      if (option && option.isStandbyList) {
        standbyListDeployments.push({ id: option.id, value: option.value });
      } else {
        eventDeployments.push({ id: option.id, value: option.value });
      }
    });

    if (isExclude && standbyListDeployments.length) {
      standbyListDeployments.unshift('exclude');
    }

    if (isExclude && eventDeployments.length) {
      eventDeployments.unshift('exclude');
    }

    return {
      standbyListDeployments,
      eventDeployments,
    };
  };

  const onChange = item => {
    const newItemValue = item.value;
    const getItem = dataSource.find(i => i === newItemValue);

    const newDataSource = getItem
      ? dataSource.filter(i => i !== newItemValue)
      : [...dataSource, newItemValue];

    if (newDataSource.join(', ').length) {
      let changedValue = newDataSource;
      if (name === 'deploymentsFilters') {
        const { standbyListDeployments, eventDeployments } = parseDeploymentsFilters(
          active !== 'include',
          newDataSource
        );
        changedValue = {
          standbyListDeployments,
          eventDeployments,
        };
      }
      const newValue = isCustom
        ? {
            value: changedValue,
            toDeploy: options.filter(o => newDataSource.includes(o.value)),
          }
        : {
            included: active === 'include',
            value: changedValue,
          };
      setField(name, newValue);
      $dataSource(newDataSource);
    } else {
      setField(name, '');
      $dataSource([]);
    }
  };

  const onApply = isExclude => {
    const newName = isExclude ? ['exclude', ...dataSource] : dataSource;

    if (name === 'registrationType') {
      if (newName.join(',') === 'individual') {
        const dataModel = { ...Object.assign({}, props.filter), [name]: 'individual' };
        filterLogs(dataModel);
      }
      if (newName.join(',') === 'exclude,individual') {
        const dataModel = { ...Object.assign({}, props.filter), [name]: 'business' };
        filterLogs(dataModel);
      }
    } else if (name === 'deploymentsFilters') {
      const { standbyListDeployments, eventDeployments } = parseDeploymentsFilters(
        isExclude,
        dataSource
      );

      const dataModel = {
        ...Object.assign({}, props.filter),
        standbyListDeployments,
        eventDeployments,
      };
      filterLogs(dataModel);
    } else {
      const dataModel = { ...Object.assign({}, props.filter), [name]: newName };
      filterLogs(dataModel);
    }

    $valueSearch('');
  };

  const onClear = () => {
    $dataSource([]);
    setOpen();
  };

  const onStandbyClick = async item => {
    if (!item.isCustomEvent) {
      const toDeploy = options
        .filter(o => {
          if (!o.isCustomEvent) {
            if (o.id === item.id) {
              return !o.deployed;
            } else {
              return o.deployed;
            }
          }
          return false;
        })
        .map(event => ({ id: event.id, isStandbyList: true }));
      await services.api.admin.setAdjusterDeployment(user.id, toDeploy);
      const deployments = await services.api.admin.fetchAdjusterDeployment(user.id);
      updateDeployments(deployments.data);
    }
  };

  const unDeploy = async (id, eventId) => {
    await services.api.admin.unsetAdjusterDeployment(id, eventId);
    const deployments = await services.api.admin.fetchAdjusterDeployment(user.id);
    updateDeployments(deployments.data);
  };

  const attr = {
    setOpen: () => {
      $valueSearch('');
      setOpen();
    },
    onChange,
    onClear,
    onApply,
    dataSource,
    $dataSource,
    excludeIcn,
    active,
    resetField,
    value,
    $active,
    setField,
    valueSearch,
    $valueSearch,
    onChangeValueSearch,
    isFocus,
    $isFocus,
    onChangeIsFocus,
    onStandbyClick,
    $showConfirm,
    $itemToRemove,
    parseDeploymentsFilters,
    activeOnly,
    ...props,
  };

  return (
    <>
      <Popup
        content={<Content {...attr} />}
        on="click"
        position="bottom left"
        open={name === open}
        style={{ width, maxWidth: width, minWidth: width }}
        className={`popupmultiply${props.isCustom ? ' custom' : ''}`}
        basic={true}
        trigger={<Trigger {...attr} />}
      />
      {isCustom && (
        <Confirm
          size="tiny"
          closeIcon={<Image src={iconClose} className="icon" onClick={() => $showConfirm(false)} />}
          open={showConfirm}
          onCancel={() => $showConfirm(false)}
          header="Confirm"
          content={`Are you sure you want to remove the ${itemToRemove.text} deployment from adjuster ${user.firstName} ?`}
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={() => {
            if (itemToRemove.isCustomEvent) {
              unDeploy(itemToRemove.userId, itemToRemove.id);
            } else {
              onStandbyClick(itemToRemove);
            }
            $showConfirm(false);
          }}
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      )}
    </>
  );
};

export default SearchMultipleInput;
