// @ts-nocheck
import { withFormsy } from 'formsy-react';
import React, { cloneElement } from 'react';
import { Checkbox } from 'semantic-ui-react';

interface Props {
  items: object[];
  abilityType: string;
  isValid: () => any;
  getValue: () => any;
  isPristine: () => any;
  errorLabel: any;
  validationErrors: any;
}

interface State {
  items: object[];
}

class CheckboxGroup extends React.Component<Props, State> {
  public state = {
    items: [],
  };

  public componentDidMount() {
    this.setState({ items: this.props.items });
    const labelsArray = this.props.items
      .filter(el => el.checked === true)
      .map((el: any, index: number) => {
        return el.label;
      });
    if (labelsArray.length !== 0) {
      this.props.setValue(labelsArray);
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.items !== this.props.items) {
      this.setState({ items: this.props.items });
    }
  }

  public validateGroup = () => {
    const labelsArray = this.state.items
      .filter(el => el.checked === true)
      .map((el: any, index: number) => {
        return el.label;
      });
    this.props.setValue(labelsArray.length === 0 ? undefined : labelsArray);
  };

  public changeCheckbox = (event: any) => {
    const { items } = this.state;
    const item = items.find(el => el.label === event.currentTarget.childNodes[0].name);
    if (item) {
      item.checked = !event.currentTarget.childNodes[0].checked;
    }
    this.setState({ items }, this.validateGroup);
  };

  public render() {
    const { isValid, isPristine, errorLabel, validationErrors } = this.props;
    const error = !isPristine() && !isValid();
    return (
      <>
        <div className="checkbox-group-container">
          <div className="checkbox-group">
            {this.state.items.map((el: any, index: number) => (
              <Checkbox
                name={el.label}
                label={el.label}
                checked={el.checked}
                onChange={this.changeCheckbox}
                key={index}
              />
            ))}
          </div>
          {error && errorLabel && cloneElement(errorLabel, {}, validationErrors.isChecked)}
        </div>
      </>
    );
  }
}

export default withFormsy(CheckboxGroup);
