// @ts-nocheck
export const getTotalPage = (total: number, pageSize: number): number => {
  const i = Math.floor(total / pageSize);
  const m = total % pageSize;
  return m === 0 ? i : i + 1;
};

export const sizeToHumanReadable = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const formatPhone = (value: string, pattern: string) => {
  if (value) {
    let i = 0;
    const v = value.toString().replace(/[^0-9]/g, '');
    return pattern.replace(/#/g, () => {
      if (v[i]) {
        return v[i++];
      } else {
        return '';
      }
    });
  }
};

export const htmlEncode = (str: string) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString('<!doctype html><body>' + str, 'text/html');
  return dom.body.textContent === 'null' ? undefined : dom.body.textContent;
};

export const snakeToCamelCaseKeys = (obj: object) => {
  return Object.keys(obj)
    .map(k => ({
      [k.replace(/(-\w)/g, m => m[1].toUpperCase())]: obj[k],
    }))
    .reduce((a, b) => ({ ...a, ...b }), {});
};

export const getFinanceIDFromFileName = (filename: string) => {
  let parts = filename.trim().split('-');
  parts = parts.length >= 3 ? parts : filename.split('_');
  parts = parts.length >= 3 ? parts : filename.split(' ');

  if (parts.length >= 3) {
    const financeID = parts[2].trim();
    if (financeID !== '') {
      return financeID;
    }
  }

  return '';
};


export function truncateString(str: string, num: number) {
  if (str && str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}