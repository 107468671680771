import React from 'react';
import { Button, Image, Modal, Table } from 'semantic-ui-react';
import closeIcon from '../../../assets/images/Blue/cancel.svg';

interface Props {
  show: boolean;
  onClose: () => void;
  adjuster: any;
}

interface State {}

class ViewGuestAdjusterModal extends React.Component<Props, State> {
  public onModalClose = () => {
    this.props.onClose();
  };

  public render() {
    console.log({});
    return (
      <Modal
        className="deployment-statuses-modal"
        open={this.props.show}
        closeIcon={<Image src={closeIcon} className="icon" onClick={this.onModalClose} />}
        size="tiny"
      >
        <Modal.Header>View Adjuster Details</Modal.Header>

        <Modal.Content>
          {this.props.adjuster ? (
            <Table
              compact={true}
              striped={true}
              stackable={true}
              singleLine={true}
              className="data-table adjusters-table"
            >
              <Table.Body>
                <Table.Row>
                  <Table.Cell className="table-name">First Name</Table.Cell>
                  <Table.Cell className="table-name">{this.props.adjuster.firstName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="table-name">Middle Name</Table.Cell>
                  <Table.Cell className="table-name">
                    {this.props.adjuster.middleName || '-'}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="table-name">Last Name</Table.Cell>
                  <Table.Cell className="table-name">{this.props.adjuster.lastName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="table-name">Email</Table.Cell>
                  <Table.Cell className="table-name">
                    <a href={`mailto:${this.props.adjuster.email}`} target="blank">
                      {this.props.adjuster.email}
                    </a>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="table-name">Phone</Table.Cell>
                  <Table.Cell className="table-name">
                    <a href={`mailto:${this.props.adjuster.phone}`} target="blank">
                      {this.props.adjuster.phone}
                    </a>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="table-name">Address</Table.Cell>
                  <Table.Cell className="table-name">{this.props.adjuster.address}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="table-name">State</Table.Cell>
                  <Table.Cell className="table-name">{this.props.adjuster.state}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="table-name">City</Table.Cell>
                  <Table.Cell className="table-name">{this.props.adjuster.city}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          ) : (
            <div>Detail Not Available</div>
          )}
        </Modal.Content>

        <div className="modal-button-footer" style={{ justifyContent: 'space-between' }}>
          <Button
            type="button"
            style={{ width: '115px', height: '32px' }}
            className="secondary-button"
            secondary={true}
            onClick={this.onModalClose}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  }
}

export default ViewGuestAdjusterModal;
