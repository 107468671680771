// @ts-nocheck
import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import { IUser } from '../../models';
import { AdjusterUser } from '../../models/adjuster';
import * as actions from './actions';
import * as constants from './constants';

export interface Account {
  isAuthenticationChecked: boolean;
  isAuthenticated: boolean;
  accessToken?: string;
  user?: IUser;
  adjuster?: AdjusterUser;
  adjusterFolder?: AdjusterFolder;
}

export const AccountDefaultState: Account = {
  isAuthenticationChecked: false,
  isAuthenticated: false,
};

export interface AdjusterFolder {
  showModal: boolean;
  assignedFolders: number[];
  allFolders: Array<any>;
  selectedFolders: number[];
  adjusterId: number | null;
}
export const AdjusterDefaultState: AdjusterFolder = {
  showModal: false,
  assignedFolders: [],
  allFolders: [],
  selectedFolders: [],
  adjusterId: null,
};
export interface Login {
  error: string;
}

export const LoginDefaultState: Login = {
  error: '',
};

export interface Recover {
  error: string;
}

export const RecoverDefaultState: Recover = {
  error: '',
};

export interface Register {
  errors: string[];
}

export const RegisterDefaultState: Register = {
  errors: [],
};

export interface EmailVerification {
  errors: string[];
}

export const EmailVerificationDefaultState: EmailVerification = {
  errors: [],
};

export interface State {
  account: Account;
  login: Login;
  recover: Recover;
  register: Register;
  emailVerification: EmailVerification;
}

export type Action = ActionType<typeof actions>;


function isAdmin(role: string) {
  return {
    isReadOnlyAdmin: ['Read Only Office Admin'].indexOf(role) >= 0,
    isAdmin: ['Super Admin', 'Office Admin', 'Read Only Office Admin'].indexOf(role) >= 0,
    isSuperAdmin: ['Super Admin'].indexOf(role) >= 0,
  }
}
export default combineReducers<State, Action>({
  account: (state = AccountDefaultState, action) => {
    switch (action.type) {
      case constants.CHECK_AUTHENTICATION_FINISH:
        return {
          ...state,
          isAuthenticationChecked: true,
        };

      case constants.UPDATE_USER_DATA:
        return {
          ...state,
          user: { ...action.payload, ...isAdmin(action.payload.role) },
        };

      case constants.LOGIN_SUCCESS:
        return {
          ...state,
          accessToken: action.payload.accessToken,
          isAuthenticated: true,
          user: { ...action.payload.user, ...isAdmin(action.payload.user.role) },
          isAuthenticationChecked: true,
        };

      case constants.FETCH_ADJUSTER_DATA:
        return {
          ...state,
          adjuster: action.payload,
        };

      case constants.CLEAR_ADJUSTER_DATA:
        return {
          ...state,
          adjuster: {},
        };

      case constants.FETCH_ADJUSTER_DATA_ATTEMPT:
        return { ...state };

      case constants.LOGOUT:
        return {
          ...state,
          accessToken: undefined,
          isAuthenticated: false,
          user: undefined,
        };
      default:
        return state;
    }
  },
  login: (state = LoginDefaultState, action) => {
    switch (action.type) {
      case constants.LOGIN_ATTEMPT:
        return { ...state };

      case constants.LOGIN_ATTEMPT_FAILED:
        return { ...state, error: action.payload.error };

      default:
        return state;
    }
  },

  recover: (state = RecoverDefaultState, action) => {
    switch (action.type) {
      case constants.RECOVER_ATTEMPT:
        return { ...state };
      case constants.RECOVER_ATTEMPT_FAILED:
        return { ...state, error: action.payload.error };
      case constants.RESET_PASSWORD_ATTEMPT:
        return { ...state };
      default:
        return state;
    }
  },
  register: (state = RegisterDefaultState, action) => {
    switch (action.type) {
      case constants.REGISTER_ATTEMPT:
        return { ...state };

      case constants.REGISTER_ATTEMPT_FAILED:
        return { ...state, errors: action.payload.errors };

      default:
        return state;
    }
  },
  emailVerification: (state = EmailVerificationDefaultState, action) => {
    switch (action.type) {
      case constants.EMAIL_VERIFICATION_ATTEMPT_FAILED:
        return { ...state, errors: action.payload.errors };

      default:
        return state;
    }
  },
  adjusterFolder: (state = AdjusterDefaultState, action) => {
    switch (action.type) {
      case constants.GET_ASSIGNED_FOLDERS:
        return { ...state, folderId: action.payload.folderId };
      case constants.SET_ALL_FOLDERS:
        return { ...state, allFolders: action.payload.data };
      case constants.SET_ASSIGNED_FOLDERS:
        return { ...state, assignedFolders: action.payload.data };
      case constants.SET_SELECTED_FOLDERS:
        return { ...state, selectedFolders: action.payload.data };
      case constants.SELECT_FOLDER:
        if (state.selectedFolders.find(t => t === action.payload.folderId)) {
          state.selectedFolders = state.selectedFolders.filter(t => t !== action.payload.folderId);
        }
        else {
          state.selectedFolders.push(action.payload.folderId);
        }

        return { ...state, selectedFolders: state.selectedFolders.concat() };
      default:
        return state;
    }
  },
});
