// @ts-nocheck
import { trackPromise } from 'react-promise-tracker';
import config from '../../config';
import * as aws from './aws';
import { request } from './base';

export interface AdjusterListFetchParam {
  page?: number;
  pageSize?: number;
  name?: string;
  city?: string;
  state?: string;
  zip?: string;
  milesAway?: number;
  certification?: string;
  experience?: string;
  language?: string;
  softwareSkill?: string;
  position?: string;
  equipment?: string;
  expertise?: string;
  xactnetAddress?: string;
  availableForClaims?: boolean;
  largeLossTeam?: boolean;
  commercialTeam?: boolean;
  deployed?: boolean;
  authorityLevelFrom?: number;
  authorityLevelTo?: number;
  catCity?: string;
  catStateProvince?: string;
  status?: string;
  sort?: string;
  isAdmin?: boolean;
}

export const fetchMapAdjuster = async (params: AdjusterListFetchParam) => {
  const response = await request({
    method: 'GET',
    url: '/adjuster/list',
    params,
  });

  return response;
};

export const fetchMapAdjusters = async (params?: AdjusterListFetchParam) => {
  let page = 0;
  const pageSize = 25;
  let adjusters: any[] = [];
  let distances = {};
  let eventLocation = {};

  while (true) {
    const response = await trackPromise(
      fetchMapAdjuster({
        page,
        pageSize,
        ...params,
      }),
      'adjusters-map'
    );
    if (!response.isSuccess || response.data.count === 0) {
      if (response.data.eventLocation) {
        return { eventLocation: response.data.eventLocation };
      }
      return [];
    }

    if (!response.data.list || response.data.list.length === 0) {
      break;
    }

    if (response.data.adjustersDistanceToLocation) {
      distances = { ...distances, ...response.data.adjustersDistanceToLocation };
    }

    if (response.data.eventLocation) {
      eventLocation = { ...response.data.eventLocation };
    }

    adjusters = [...adjusters, ...response.data.list];
    if (adjusters.length === response.data.count) {
      break;
    }

    page++;
  }

  return { adjusters, distances, eventLocation };
};

export const fetchAdjuster = async (params: AdjusterListFetchParam) => {
  const response = await request({
    method: 'GET',
    url: '/adjuster/list',
    params,
  });

  if (response.isSuccess && response.data.list.length > 0) {
    response.data.list.forEach((adjuster: any) => {
      if (adjuster.avatar) {
        adjuster.avatar = config.uploads.s3BucketAddress + adjuster.avatar;
      }
    });
  }

  return response;
};

export const fetchAdjustersNoPagination = async (params: object) => {
  return await trackPromise(
    request({
      method: 'GET',
      url: '/adjuster/list',
      params,
    }),
    'invite-modal-users'
  );
};

export const fetchAdjusterListCustom = async (
  params?: AdjusterListFetchParam,
  fetchArea?: string
) => {
  if (params && params.status === '') {
    delete params.status;
  }
  const response = await trackPromise(
    request({
      method: 'GET',
      url: '/adjuster/list',
      params: {
        ...params,
      },
    }),
    fetchArea ? fetchArea : 'adjusters-list'
  );

  if (response.isSuccess && response.data.list.length > 0) {
    response.data.list.forEach((adjuster: any) => {
      if (adjuster.avatar) {
        adjuster.avatar = config.uploads.s3BucketAddress + adjuster.avatar;
      }
    });
  }

  return response;
};

export interface AdjusterDetailData {
  financeID?: string;
  status?: string;
  isAdmin: boolean;
  availableForClaims?: boolean;
  largeLossTeam?: boolean;
  commercialTeam?: boolean;
  deployed?: boolean;
  authorityLevel?: string;
  compassEmail?: string;
  email: string;
}

export const updateAdjusterDetails = async (id: any, data: AdjusterDetailData) => {
  return await request({
    method: 'POST',
    url: `/adjuster/${id}`,
    data,
  });
};

export const sendActivationMail = (email: string) => {
  return request({
    method: 'POST',
    url: '/account/adjuster/resend-email-verification',
    data: {
      email,
    },
  });
};

export const changeAdjusterStatus = (adjusterId: any, status: any, userType: any) => {
  return request({
    method: 'patch',
    url: '/adjuster/status',
    data: {
      adjusterId,
      status,
      userType,
    },
  });
};

export const addNote = (adjusterId: any, note: any) => {
  return request({
    method: 'post',
    url: '/adjuster/note',
    data: {
      adjusterId,
      note,
    },
  });
};

export const deleteNote = (adjusterId: any, noteIndex: number) => {
  return request({
    method: 'post',
    url: `/adjuster/note/`,
    data: {
      adjusterId,
      noteIndex,
    },
  });
};

export const addPaperwork = (
  adjusterId: any,
  type: any,
  allFilesReceived: boolean,
  fileObjectKeys: any,
  runDate?: string
) => {
  return request({
    method: 'post',
    url: '/paperwork',
    data: {
      adjusterId,
      type,
      allFilesReceived,
      fileObjectKeys,
      runDate,
    },
  });
};

export const editPaperwork = (
  id: any,
  type: any,
  fileObjectKeys: any,
  allFilesReceived: boolean,
  runDate: any
) => {
  return request({
    method: 'patch',
    url: `/paperwork/${id}`,
    data: {
      type,
      fileObjectKeys,
      allFilesReceived,
      runDate,
    },
  });
};

export const deletePaperwork = (id: any) => {
  return request({
    method: 'delete',
    url: `paperwork/${id}`,
  });
};

export const exportAdjusterList = (params?: any) => {
  return request({
    method: 'GET',
    url: '/adjuster/list/export?exportType=excel',
    data: {
      ...params,
    },
  });
};

export const loginAsAdjuster = (id: any) => {
  return request({
    method: 'GET',
    url: `/admin/adjuster-login/${id}`,
  });
};

export const getLogsUploaderAvatarUrl = async (logs: any[]) => {
  const tasks = logs.map(async log => {
    if (log.user && log.user.avatar) {
      const url = await aws.getDownloadFileUrl(log.user.avatar);
      if (url) {
        log.user.avatar = url;
      }
    }
  });

  return await Promise.all(tasks);
};

export const createNewAdjuster = (model: any) => {
  return trackPromise(
    request({
      method: 'post',
      url: `/adjuster/create`,
      data: {
        ...model,
      },
    }),
    'create-adjuster-page'
  );
};

export const completeAllPaperworks = (id: any, completed: boolean) => {
  return trackPromise(
    request({
      method: 'post',
      url: `/adjuster/${id}/complete-paperworks`,
      data: {
        completed,
      },
    }),
    'paperwork-area'
  );
};

export const fetchAdminAdjuster = async (params?: AdjusterListFetchParam) => {
  const response = await request({
    method: 'GET',
    url: '/adjuster/list',
    params: {
      ...params,
      isAdmin: true,
    },
  });

  return response;
};

export const fetchAdminAdjusters = async (params?: AdjusterListFetchParam) => {
  let page = 0;
  const pageSize = 25;
  let adjusters: any[] = [];

  while (true) {
    const response = await trackPromise(
      fetchAdminAdjuster({
        page,
        pageSize,
        ...params,
      }),
      'adjusters-admin-field'
    );
    if (!response.isSuccess || response.data.count === 0) {
      return [];
    }

    if (!response.data.list || response.data.list.length === 0) {
      break;
    }

    adjusters = [...adjusters, ...response.data.list];
    if (adjusters.length === response.data.count) {
      break;
    }

    page++;
  }

  return adjusters;
};
