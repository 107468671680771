import React from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {} from 'semantic-ui-react';

interface Props {
  progress: number;
}

interface State {}

class RegisterProgress extends React.Component<Props, State> {
  public state = {};

  public defineColor = () => {
    switch (true) {
      case this.props.progress <= 10:
        return '#ac0000';
      case this.props.progress > 10 && this.props.progress <= 20:
        return '#e10000';
      case this.props.progress > 20 && this.props.progress <= 30:
        return '#cb9d18';
      case this.props.progress > 30 && this.props.progress <= 40:
        return '#dabb00';
      case this.props.progress > 40 && this.props.progress <= 50:
        return '#1378bb';
      case this.props.progress > 50 && this.props.progress <= 60:
        return '#3112c6';
      case this.props.progress > 60 && this.props.progress <= 70:
        return '#2e358d';
      case this.props.progress > 70 && this.props.progress <= 80:
        return '#124d13';
      case this.props.progress > 80 && this.props.progress <= 90:
        return '#1c8027';
      case this.props.progress > 90:
        return '#1ca902';
      default:
        return '#1ca902';
    }
  };

  public render() {
    const { progress } = this.props;

    return (
      <div className="profile-completeness">
        <div className="progress-title">Profile Completeness Score</div>
        <div className="header-circular-progress">
          <CircularProgressbarWithChildren
            value={progress}
            styles={buildStyles({
              pathColor: this.defineColor(),
              backgroundColor: '#ffffff',
            })}
          >
            <div className="progress-value-text">
              <div style={{ fontSize: '20px', fontWeight: 'bold', color: this.defineColor() }}>
                {Number(progress).toFixed()}
              </div>
              <div style={{ fontSize: '8px' }}>out of 100</div>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    );
  }
}

export default RegisterProgress;
