// @ts-nocheck
import * as Formsy from 'formsy-react';
import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { toast } from 'react-toastify';
import { Dropdown, Image, Popup } from 'semantic-ui-react';
import infoIcon from '../../../assets/images/Common/icn-info.svg';
import ErrorLabel from '../../../components/error-label/ErrorLabel';
import services from '../../../services';
import ProviderCheckboxes from './ProviderCheckboxes';
import SMToField from './SMToField';

interface Props {
  adjusters: object[];
  standbyId: any;
  standby: any;
  global: boolean;
  showTwilioModal: (show: boolean) => void;
  setMsgObj: (obj: object) => void;
  inviteModalMode: string;
  updateData: () => void;
  adminAdjusters: object[];
  getAdminAdjusters: (params?: object) => void;
  onSubmitMessage: (fn: () => boolean) => void;
}

interface State {
  isEmailSelected: boolean;
  templateOptions: object[];
  templates: object[];
  templateTitle: string;
  templateMessage: string;
  to: string;
}

Formsy.addValidationRule('isChecked', (values: any, value: any) => {
  return value !== undefined && value !== null && value !== '';
});

Formsy.addValidationRule('hasMoreEmails', (values: any, value: any) => {
  const emailsString = values.bcc + values.cc;
  if (values.bcc || values.cc) {
    if ((emailsString.match(/@/g) || []).length > 20) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
});

class SendMessageContainer extends React.Component<Props, State> {
  public state = {
    isEmailSelected: false,
    templateOptions: [],
    templates: [],
    templateTitle: '',
    templateMessage: '',
    to: '',
  };

  public componentDidMount = async () => {
    const response = await services.api.settings.getAllMsgTemplates();
    if (response.isSuccess) {
      const optionsArray = response.data.map((el: any) => {
        return { text: el.name, value: el.id };
      });
      this.setState({
        templateOptions: optionsArray,
        templates: response.data,
      });
    } else {
      console.log(response);
    }
  };

  public onTemplateChange = (e: any, data: any) => {
    const { templates } = this.state;
    const activeTemplate = templates.find((el: any) => el.id === data.value);
    this.setState({
      templateTitle: activeTemplate.title,
      templateMessage: activeTemplate.text,
    });
  };

  public hasEmail = (hasEmail: boolean) => {
    this.setState(
      { isEmailSelected: hasEmail },
      () => !this.state.isEmailSelected && this.setState({ to: '' })
    );
  };
  public onChangeTo = (event: React.SyntheticEvent, data: object) => {
    event.preventDefault();
    this.setState({ to: data.value });
  };

  public render() {
    const { isEmailSelected, templateOptions, templateTitle, templateMessage } = this.state;
    const { onSubmitMessage } = this.props;
    const errorLabel = <ErrorLabel />;
    return (
      <div className="send-message-container active">
        <Form
          id="send-message-form"
          onValidSubmit={async (model: any) => {
            await onSubmitMessage(async () => {
              console.log('onSubmitMessage', )

              let mainResponse = false;
              const { adjusters = [], standby, global } = this.props;
              const numbers = standby.numbers || [];
              const isNumbersEnough = numbers.length * 200 > adjusters.length;
              const obj = Object.assign(
                {},
                model,
                {
                  recipients: {
                    adjusters: adjusters.filter(el => !el.signupId).map((el) => el.id),
                    quickApplied: adjusters.filter(el => el.signupId).map((el) => el.signupId),
                   },
                },
                { twilio: this.props.twilioNumber }
              );
              if (
                this.props.standby &&
                (!numbers.length || (numbers.length && !isNumbersEnough)) &&
                model.provider.includes('twilio')
              ) {
                if (adjusters.filter(adj => adj.mobilePhone).length === 0) {
                  toast.dismiss();
                  toast.error('At least one recipient should have phone number defined.');
                } else {
                  this.props.setMsgObj(obj);
                  this.props.showTwilioModal(true);
                }
              } else {
                if (this.props.inviteModalMode === 'edit' && !global) {
                  const responseGlobal = await services.api.messages.sendGlobalMessage(
                    obj,
                    undefined,
                    standby.id
                  );
                  if (responseGlobal.isSuccess) {
                    this.props.updateData();
                    mainResponse = true;
                    toast.dismiss();
                    toast.success(
                      'The message to the selected recipient(s) was sent successfully!'
                    );
                  } else {
                    toast.dismiss();
                    toast.error(
                      responseGlobal.data.errors
                        ? responseGlobal.data.errors[0].msg
                        : `Error ${responseGlobal.status}`
                    );
                  }
                } else if (this.props.inviteModalMode === 'edit' && global) {
                  const responseGlobal = await services.api.messages.sendGlobalMessage(obj);
                  if (responseGlobal.isSuccess) {
                    toast.dismiss();
                    toast.success(
                      'The message to the selected recipient(s) was sent successfully!'
                    );
                    return true;
                  } else {
                    toast.dismiss();
                    toast.error(
                      responseGlobal.data.errors
                        ? responseGlobal.data.errors[0].msg
                        : `Error ${responseGlobal.status}`
                    );
                  }
                } else if (this.props.inviteModalMode === 'create') {
                  const response = await services.api.messages.sendMessage(obj, standby.id);
                  if (response.isSuccess) {
                    this.props.updateData();
                    mainResponse = true;
                    toast.dismiss();
                    toast.success(
                      'The message to the selected recipient(s) was sent successfully!'
                    );
                  } else {
                    toast.dismiss();
                    toast.error(
                      response.data.errors
                        ? response.data.errors[0].msg
                        : `Error ${response.status}`
                    );
                  }
                }
              }
              return mainResponse;
            });
          }}
        >
          <div className="send-message-form-container">
            <div className="input-field-container">
              <div className="input-field-label">Message Template</div>
              <div className="field-input-container">
                <Dropdown
                  search={true}
                  selectOnBlur={false}
                  placeholder="Please Select"
                  className="standby-dropdown"
                  options={templateOptions}
                  onChange={this.onTemplateChange}
                />
              </div>
            </div>

            <div className="input-field-container">
              <div className="input-field-label">Title</div>
              <div className="field-input-container">
                <Form.Input
                  name="title"
                  maxLength={128}
                  placeholder="Enter please..."
                  instantValidation={false}
                  required={true}
                  validationErrors={{
                    isDefaultRequiredValue: '* Title is required field.',
                  }}
                  errorLabel={errorLabel}
                  className="form-input"
                  value={templateTitle}
                />
              </div>
            </div>

            <div className="input-field-container">
              <div className="input-field-label">Message</div>
              <div className="field-input-container">
                <Form.TextArea
                  name="text"
                  maxLength={1024}
                  placeholder="Enter please..."
                  instantValidation={false}
                  required={true}
                  validationErrors={{
                    isDefaultRequiredValue: '* Message is required field.',
                  }}
                  errorLabel={errorLabel}
                  className="form-input"
                  value={templateMessage}
                />
              </div>
            </div>
            <div className="input-field-container">
              <div className="input-field-label">Send via</div>
              <div className="field-input-container">
                <ProviderCheckboxes
                  name="provider"
                  validations={{
                    isChecked: true,
                  }}
                  value=""
                  validationErrors={{
                    isChecked: '* Send via is required field.',
                  }}
                  errorLabel={errorLabel}
                  checkEmail={this.hasEmail}
                  standby={this.props.standby}
                />
              </div>
            </div>
            <div className="input-field-container">
              <div className="input-field-label registration-type-label">
                To
                <Popup
                  trigger={<Image src={infoIcon} />}
                  size="mini"
                  basic={true}
                  content="Please select an admin from the list for the TO option. All the adjusters/recipients will be added as bcc fields and the email requires a TO field."
                />
              </div>
              <div className="field-input-container">
                <SMToField
                  name="to"
                  required={isEmailSelected}
                  disabled={!isEmailSelected}
                  instantValidation={false}
                  validationErrors={{
                    isDefaultRequiredValue: '* TO is required field.',
                  }}
                  errorLabel={errorLabel}
                  getUsers={this.props.getAdminAdjusters}
                  users={this.props.adminAdjusters}
                  onChange={this.onChangeTo}
                  value={this.state.to}
                />
              </div>
            </div>
            <div className="input-field-container">
              <div className={`input-field-label ${isEmailSelected ? '' : 'disabled'}`}>CC</div>
              <div className="field-input-container">
                <Form.Input
                  name="cc"
                  disabled={!isEmailSelected}
                  placeholder="Enter please..."
                  instantValidation={false}
                  validations="hasMoreEmails"
                  validationErrors={{
                    hasMoreEmails: 'Only 20 emails are allowed in CC + BCC.',
                  }}
                  errorLabel={errorLabel}
                  className="form-input"
                />
              </div>
            </div>
            <div className="input-field-container">
              <div className={`input-field-label ${isEmailSelected ? '' : 'disabled'}`}>BCC</div>
              <div className="field-input-container">
                <Form.Input
                  name="bcc"
                  disabled={!isEmailSelected}
                  placeholder="Enter please..."
                  instantValidation={false}
                  validations="hasMoreEmails"
                  validationErrors={{
                    hasMoreEmails: 'Only 20 emails are allowed in CC + BCC.',
                  }}
                  errorLabel={errorLabel}
                  className="form-input"
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default SendMessageContainer;
