// @ts-nocheck
import MyTypes from 'MyTypes';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Confirm, Grid, Image } from 'semantic-ui-react';
import { trainingCenterActions } from '../../../features/training-center';
import {
  MaterialModel,
  PaginationInfo,
} from '../../../features/training-center/trainingCenterReducer';
import { IUser } from '../../../models';
import services from '../../../services';
import { LoadingSpiner } from '../../loading-spinner/LoadingSpinner';
import { NoResults } from '../../no-results-placeholder/NoResults';
import MaterialFilter from '../material-filter/MaterialFilter';
import MaterialListView from './MaterialListView';
import MaterialPagination from './MaterialPagination';
import closeIcon from '../../../assets/images/Blue/cancel.svg';
import MaterialGridView from './MaterialGridView';
import MaterialDetails from '../material-details/MaterialDetails';

interface Props {
  user: IUser;
  searchMaterial: typeof trainingCenterActions.searchMaterial;
  pagingInfo: PaginationInfo;
  viewType?: 'grid' | 'list';
  selectedFolderId?: number;
  selectedMaterials: Array<number>;
  setSelectedMaterial: typeof trainingCenterActions.setSelectedMaterial;
  removeSelectedMaterials: typeof trainingCenterActions.removeSelectedMaterials;
  removeAllSelectedMaterials: typeof trainingCenterActions.removeAllSelectedMaterials;
  editMaterial: typeof trainingCenterActions.toggleEditMaterialModal;
  deleteMaterial: typeof trainingCenterActions.deleteMaterial;
  selectedMaterial: number[];
  allMaterials: MaterialModel[];
}

interface State {
  deleteMaterial: MaterialModel | null;
  deletedIds: number[];
  materialDetails: MaterialModel | null;
}

class MaterialList extends React.Component<Props, State> {
  public state = {
    deleteMaterial: null,
    deletedIds: [],
    materialDetails: null,
  };

  public handlePagination = (e: any, data: any) => {
    const activePage = data.activePage;
    const { pagingInfo } = this.props;

    this.props.removeAllSelectedMaterials();
    this.props.searchMaterial({
      ...pagingInfo,
      activePage: activePage,
      resetSelect: false,
    });
  };

  public handlePageSize = (e: any, data: any) => {
    const { pagingInfo } = this.props;
    const pageSize = data.pageSize;

    this.props.searchMaterial({
      ...pagingInfo,
      pageSize,
      activePage: 1,
    });
  };

  public componentDidMount = () => {
    const { pagingInfo } = this.props;
    this.props.searchMaterial({
      ...pagingInfo,
      activePage: 1,
      resetSelect: true,
    });
  };

  public renderNoResult() {
    const { pagingInfo } = this.props;

    if (!pagingInfo || pagingInfo.records.length === 0) {
      return <NoResults />;
    }
    return null;
  }

  public selectMaterial(material: { id: number }) {
    const isSelected = this.props.selectedMaterials.find(el => el === material.id);
    if (!isSelected) {
      this.props.setSelectedMaterial(material.id);
    } else {
      this.props.removeSelectedMaterials(material.id);
    }
  }

  public deleteMaterial(material: any) {
    this.setState({ deleteMaterial: material });
  }

  public submitConfirmDelete() {
    this.props.deleteMaterial(this.state.deleteMaterial);
    this.setState({ deleteMaterial: null, materialDetails: null });
  }

  public deleteMaterialDetails() {
    if (this.state.materialDetails) {
      this.setState({ deleteMaterial: this.state.materialDetails });
    }
  }

  public assignAccessMaterial(material: any) {}

  public editMaterial(material: any) {
    this.props.editMaterial(material);
  }

  public async downloadMaterial(material: MaterialModel) {
    if (!material.file) {
      return;
    }
    const fileKey = material.file.key;
    const downloadUrl = await trackPromise(
      services.api.file.getDownloadFileUrl(fileKey),
      'material-list'
    );
    if (downloadUrl) {
      services.api.file.downloadFile(downloadUrl, material.fileName, material.name);
    }
  }

  public onMaterialDetailsClose() {
    this.setState({ materialDetails: null });
  }

  public selectAllMaterial(id: number) {}

  public bulkAssignAccessMaterial(ids: number[]) {}

  public viewMaterialDetails(material: MaterialModel) {
    this.setState({ materialDetails: material });
  }

  public findParent(arr: any, entityId: number): any {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id == entityId) {
        return [];
      } else if (arr[i].children && arr[i].children.length) {
        const t = this.findParent(arr[i].children, entityId);

        if (t !== false) {
          t.push(arr[i].name);

          return t;
        }
      }
    }

    return false;
  }

  public isSelectAll() {
    const { allMaterials, selectedMaterial } = this.props;

    if (
      selectedMaterial.length === 0 ||
      allMaterials.length === 0 ||
      selectedMaterial.length !== allMaterials.length
    ) {
      return false;
    }

    for (const selectedId of selectedMaterial) {
      const foundRecord = allMaterials.find(t => t.id === selectedId);
      if (!foundRecord) {
        return false;
      }
    }
    return true;
  }

  public render() {
    const { pagingInfo, viewType, folderList } = this.props;
    const { deleteMaterial } = this.state;

    let deleteMessage = `Are you sure you want to delete selected file? \n`;

    if (deleteMaterial && deleteMaterial.materialFolders.length > 1) {
      deleteMessage += `It is currently shared between multiple Folders / Subfolders: \n \n`;

      for (let i = 0; i < deleteMaterial.materialFolders.length; i++) {
        const material = deleteMaterial.materialFolders[i];
        let parentsName = this.findParent(folderList, material['folder']['id']);

        parentsName.push(material['folder']['name']);

        let path = '- ';
        // make path
        parentsName.forEach((folderName: string, i: number) => {
          path += `${i === 0 ? 'Folder' : 'Subfolder'} ${folderName}`;
          path += `${i === parentsName.length - 1 ? '\n' : ' / '}`;
        });

        deleteMessage += path;
      }

      console.log({ deleteMessage });
    }

    return (
      <React.Fragment>
        <LoadingSpiner area="material-list" />
        <Grid columns="1">
          <Grid.Row>
            <Grid.Column className=" folder-list-column" width={16}>
              <MaterialFilter />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className=" folder-list-column" width={16}>
              {this.renderNoResult()}
              {viewType === 'list' && (
                <MaterialListView
                  selectMaterial={(data: any) => {
                    this.selectMaterial(data);
                  }}
                  editMaterial={(data: any) => this.editMaterial(data)}
                  downloadMaterial={(data: any) => this.downloadMaterial(data)}
                  deleteMaterial={(data: any) => this.deleteMaterial(data)}
                  assignAccessMaterial={(data: any) => this.assignAccessMaterial(data)}
                  onMaterialClick={(data: any) => this.viewMaterialDetails(data)}
                />
              )}
              {viewType === 'grid' && (
                <MaterialGridView
                  selectMaterial={(data: any) => {
                    this.selectMaterial(data);
                  }}
                  editMaterial={(data: any) => this.editMaterial(data)}
                  downloadMaterial={(data: any) => this.downloadMaterial(data)}
                  deleteMaterial={(data: any) => this.deleteMaterial(data)}
                  assignAccessMaterial={(data: any) => this.assignAccessMaterial(data)}
                  onMaterialClick={(data: any) => this.viewMaterialDetails(data)}
                />
              )}
              <MaterialPagination
                pagingInfo={pagingInfo}
                handlePagination={this.handlePagination}
                handlePageSize={this.handlePageSize}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Confirm
          size="tiny"
          closeIcon={
            <Image
              src={closeIcon}
              className="icon"
              onClick={() => this.setState({ deleteMaterial: null })}
            />
          }
          open={this.state.deleteMaterial !== null}
          onCancel={() => this.setState({ deleteMaterial: null })}
          header="Confirm"
          content={deleteMessage}
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={() => this.submitConfirmDelete()}
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
          style={{
            whiteSpace: 'pre-wrap',
          }}
        />
        {this.state.materialDetails !== null && (
          <MaterialDetails
            material={this.state.materialDetails as MaterialModel}
            onClose={() => this.onMaterialDetailsClose()}
            onEdit={() => {
              this.editMaterial(this.state.materialDetails);
              this.onMaterialDetailsClose();
            }}
            onDelete={() => this.deleteMaterialDetails()}
            onDownload={() => {
              this.downloadMaterial(this.state.materialDetails as MaterialModel);
              this.onMaterialDetailsClose();
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  pagingInfo: state.trainingCenter.material.pagingInfo,
  selectedMaterials: state.trainingCenter.material.pagingInfo.selected,
  viewType: state.trainingCenter.material.viewType,
  selectedFolderId: state.trainingCenter.material.selectedFolderId,
  folderList: state.trainingCenter.folderList,
  selectedMaterial: state.trainingCenter.material.pagingInfo.selected,
  allMaterials: state.trainingCenter.material.pagingInfo.records,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      searchMaterial: trainingCenterActions.searchMaterial,
      setSelectedMaterial: trainingCenterActions.setSelectedMaterial,
      deleteMaterial: trainingCenterActions.deleteMaterial,
      removeSelectedMaterials: trainingCenterActions.removeSelectedMaterials,
      removeAllSelectedMaterials: trainingCenterActions.removeAllSelectedMaterials,
      editMaterial: trainingCenterActions.toggleEditMaterialModal,
      getFolderList: trainingCenterActions.getFolderList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MaterialList);
