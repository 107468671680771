import { trackPromise } from 'react-promise-tracker';
import { request } from './base';

export class ApiResponse {
  public status: number;
  public data: any;

  public isSuccess: boolean;
  public isError: boolean;

  constructor(status: number, data: any) {
    this.status = status;
    this.data = data;

    this.isSuccess = status >= 200 && status <= 299;
    this.isError = !this.isSuccess;
  }

  public get(x: string) {
    return this.data[x];
  }

  public getErrorList() {
    return this.data.errors.map((errorData: any) => {
      return errorData.msg;
    });
  }

  public getError() {
    return this.getErrorList().join(' ');
  }

  public isServerError() {
    return this.status >= 500 && this.status < 600;
  }
}

export const requestCoord = async (zip: object) => {
  console.log({zip})
  return request({
    method: 'GET',
    url: `/location/${zip}`,
  });
};

export const requestCoordsForAll = async (objects: any[]) => {
  return await trackPromise(
    Promise.all(
      objects.map(async obj => {
        const coords = await requestCoord(obj.zip);
        if (coords.isSuccess && coords.data.records.length !== 0) {
          obj.position = coords.data.records[0].fields.geopoint;
        }
        return obj;
      })
    ),
    'adjusters-map'
  );
};
