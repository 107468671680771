import { Button, Checkbox, Image, Input, Modal } from "semantic-ui-react";
import closeIcon from '../../../assets/images/Blue/cancel.svg';
import { getAdminList } from "../../../services/api/admin";
import { useEffect, useState } from "react";
import ListPagination from "../../list-pagination/ListPagination";
import { NoResults } from "../../no-results-placeholder/NoResults";
import { updateCaller } from "../../../services/api/standby";
import { LoadingSpiner } from "../../loading-spinner/LoadingSpinner";
import { toast } from "react-toastify";

enum Role {
  SuperAdmin = 'Super Admin',
  OfficeAdmin = 'Office Admin',
  ReadOnlyOfficeAdmin = 'Read Only Office Admin',
}

type Admin = {
  createdAt: string,
  updatedAt: string,
  id: number,
  avatar: string,
  firstName: string,
  lastName: string,
  role: Role,
  phone: string,
  financeAccess: boolean,
  activeStatus: boolean,
  email: string,
  emailConfirmed: boolean
}

type Caller = {
  id: number,
  adminId: number,
  caller: {
    avatar: string,
    email: string,
    financeAccess: boolean,
    firstName: string,
    id: number,
    lastName: string,
    phone: string,
    role: Role,
  }
}

function CallerModal({ id, onModalClose, intialData }: { id: number, onModalClose: (newData?: Caller[]) => {}, intialData: Caller[] }) {
  const [selected, setSelected] = useState<Caller[]>([]);
  const [adminList, setAdminList] = useState<Admin[]>([])
  const [search, setSearch] = useState("")
  const [selectSuperAdmins, setSelectSuperAdmins] = useState(true)
  const [selectOfficeAdmins, setSelectOfficeAdmins] = useState(true)
  const [selectReadOnly, setSelectReadOnly] = useState(true)
  const [edit, setEdit] = useState(false)

  console.log('id', id)

  const show = Boolean(id)

  const showRole = [
    selectSuperAdmins && Role.SuperAdmin,
    selectOfficeAdmins && Role.OfficeAdmin,
    selectReadOnly && Role.ReadOnlyOfficeAdmin,
  ]

  const filteredAdminList = adminList
    .filter((a) => !selected.find((s) => s.caller.id === a.id))
    .filter((a) => showRole.includes(a.role))
    .filter((a) => `${a.firstName} ${a.lastName} ${a.phone} ${a.email}`.toLowerCase().includes(search.toLowerCase()))

  const filterSelected = selected
    .filter((a) => showRole.includes(a.caller.role))
    .filter((a) => `${a.caller.firstName} ${a.caller.lastName} ${a.caller.phone} ${a.caller.email}`.toLowerCase().includes(search.toLowerCase()))

  function reset() {
    setEdit(false);
    setSearch('');
    setSelectSuperAdmins(true);
    setSelectOfficeAdmins(true);
    setSelectReadOnly(true);
  }

  function onCancel() {
    setEdit(false);
    setSelected(intialData)
  }

  useEffect(() => {
    getAdminList({
      pageSize: 100000
    }).then((list) => setAdminList(list.data.result))
  }, [])

  useEffect(() => {
    setSelected(intialData)
  }, [intialData])

  useEffect(() => {
    reset();
  }, [show])

  function addCaller(data: Admin) {
    setSelected((current) => {
      return [
        ...current,
        {
          id: -1,
          adminId: data.id,
          caller: data
        },
      ]
    })
  }

  function removeCaller(adminId: number) {
    setSelected((current) => current.filter((data) => data.adminId !== adminId))
  }

  async function save() {
    const data = await updateCaller(id, selected.map((data) => ({ id: data.id, adminId: data.adminId })))
    if (data.isError) {
      toast.error(`Failed to update caller`);
      return
    }
    onModalClose(selected)
  }

  return (
    <Modal
      className="caller-modal"
      open={show}
      closeIcon={<Image src={closeIcon} className="icon" onClick={() => onModalClose()} />}
      size={edit ? "large" : "small"}
    >
      <Modal.Header>Caller</Modal.Header>
      <Modal.Content>
        <LoadingSpiner area="standby-list-caller" />
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '1rem',
         }}>
          <Checkbox label="Super Admins" checked={selectSuperAdmins} onChange={(event, data) => setSelectSuperAdmins(data.checked || false)}/>
          <Checkbox label="Office Admins" checked={selectOfficeAdmins} onChange={(event, data) => setSelectOfficeAdmins(data.checked || false)}/>
          <Checkbox label="Read Only" checked={selectReadOnly} onChange={(event, data) => setSelectReadOnly(data.checked || false)}/>
          <Input icon='search' placeholder='Search...' value={search} onChange={(event) => setSearch(event.target.value)}/>
        </div>
        <div style={{
          display: 'grid',
          alignItems: 'flex-start',
          justifyContent: 'center',
          position: 'relative',
          gap: '2rem',
          marginTop: '1rem',
          gridTemplateColumns: edit ? '1fr auto 1fr' : '1fr',
        }}>
          <div style={{ flex: '1 1 100%', height: '100%' }}>
            {filterSelected.length === 0 ? <NoResults marginTop="0" /> : (
              <ListPagination<Caller>
                itemPerPage={5}
                stickToBottom={true}
                paginationStyle={{marginTop: '1rem'}}
                data={filterSelected}
                Item={({ data }) => {
                  return (
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '0.5rem',
                      background: 'white',
                      borderRadius: '0.5rem',
                      marginBottom: '0.5rem',
                    }}>
                      <div style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        flexFlow: edit ? 'column' : 'row',
                      }}>
                        <div>
                          <div style={{ fontWeight: 'bold' }}>{data.caller.firstName}{' '}{data.caller.lastName}</div>
                          <div style={{
                             fontSize: edit ? '0.8rem' : '1rem'
                          }}>
                            {data.caller.role}{' '}
                            {data.caller.financeAccess
                              ? '(Finance Access Rights)'
                              : '(No Finance Access Rights)'}
                          </div>
                        </div>
                        <div style={{ textAlign: edit ? 'left' : 'right', fontSize: edit ? '0.8rem' : '1rem' }}>
                          <div style={{ fontWeight: 'bold' }}>{data.caller.phone}</div>
                          <div>{data.caller.email}</div>
                        </div>
                      </div>
                      {edit && <div><Button color="red" onClick={() => removeCaller(data.adminId)}>Remove</Button></div>}
                    </div>
                  )
                }}
              />
            )}
          </div>
          {edit && (
            <>
              <div>
                <div style={{
                  position: 'absolute',
                  height: '100%',
                  borderRight: '1px solid #dbdbdb',
                }}></div>
              </div>
              <div style={{ flex: '1 1 100%', height: '100%' }}>
                {filteredAdminList.length === 0 ? <NoResults marginTop="0" /> : (
                  <ListPagination
                    itemPerPage={5}
                    stickToBottom={true}
                    paginationStyle={{marginTop: '1rem'}}
                    data={filteredAdminList}
                    Item={({ data }) => {
                      return (
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: '0.5rem',
                          background: 'white',
                          borderRadius: '0.5rem',
                          marginBottom: '0.5rem',
                        }}>
                          <div style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            flexFlow: 'column',
                          }}>
                            <div>
                              <div style={{ fontWeight: 'bold' }}>{data.firstName}{' '}{data.lastName}</div>
                              <div style={{
                                fontSize: '0.8rem'
                              }}>
                                {data.role}{' '}
                                {data.financeAccess
                                  ? '(Finance Access Rights)'
                                  : '(No Finance Access Rights)'}
                              </div>
                            </div>
                            <div style={{ textAlign: 'left', fontSize: '0.8rem' }}>
                              <div style={{ fontWeight: 'bold' }}>{data.phone}</div>
                              <div>{data.email}</div>
                            </div>
                          </div>
                          {edit && <div><Button primary={true} onClick={() => addCaller(data)}>Add</Button></div>}
                        </div>
                      )
                    }}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </Modal.Content>
      <div className="modal-button-footer" style={{ justifyContent: 'space-between' }}>
        {edit ? (
          <>
            <Button
              type="button"
              style={{ width: '115px', height: '32px' }}
              className="secondary-button"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="button"
              style={{ width: '115px', height: '32px' }}
              primary={true}
              onClick={save}
            >
              Save
            </Button>
          </>
        ): (
          <>
            <Button
              type="button"
              style={{ width: '115px', height: '32px' }}
              className="secondary-button"
              onClick={() => onModalClose()}
            >
              Close
            </Button>
            <Button
              type="button"
              style={{ width: '115px', height: '32px' }}
              primary={true}
              onClick={() => setEdit(true)}
            >
              Edit
            </Button>
          </>
        )}
      </div>
    </Modal>
  )
}

export default CallerModal;