// @ts-nocheck
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import SBList from '../../components/standby-layout/SBList';
import StandByHeader from '../../components/standby-layout/StandByHeader';
import { IUser } from '../../models';

interface Props {
  user: IUser;
}

interface State {}

class StandbyLists extends React.Component<Props, State> {
  public state = {};

  public render() {
    return (
      <div>
        <StandByHeader />
        <SBList />
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<Props, State>(
  mapStateToProps,
  mapDispatchToProps
)(StandbyLists);
