import React from 'react';
import {Button, Image, Modal} from "semantic-ui-react";
import closeIcon from "../../../assets/images/Blue/cancel.svg";
import {Form} from "formsy-semantic-ui-react";
import services from "../../../services";
import {toast} from "react-toastify";
import ErrorLabel from "../../error-label/ErrorLabel";

interface AddProps {
  setModalsStatus: (status: string | null) => void;
  getCustomEventList(): void;
}

const Add = (props: AddProps) => {
  const onSave = async (model: any) => {
    const response = await services.api.folder.createCustomEvent(model.name);
    if (response.isSuccess) {
      toast.dismiss();
      toast.success('Custom event was successfully added!');
      props.setModalsStatus(null)
      props.getCustomEventList()
    } else {
      console.log(response);
      toast.dismiss();
      toast.error(
        response.data ? response.data.errors[0].msg : `Error ${response.status}`
      );
    }
  }
  return (
    <Modal
      className="create-custom-event-modal"
      open={true}
      closeIcon={
        <Image
          src={closeIcon}
          className="icon"
          onClick={() => props.setModalsStatus(null)}
        />
      }
    >
      <Modal.Header>Add new</Modal.Header>
      <Form
        id='create-custom-event-form'
        onValidSubmit={onSave}
      >
        <Modal.Content>
          <Form.Input
            name="name"
            placeholder="Please Enter..."
            className="message-template-input"
            required={true}
            validationErrors={{
              isDefaultRequiredValue: '* Name is required field.',
            }}
            errorLabel={<ErrorLabel/>}
          />
        </Modal.Content>
        <div className="modal-button-footer">
          <Button
            type="button"
            style={{width: '115px'}}
            className="secondary-button"
            secondary={true}
            onClick={() => props.setModalsStatus(null)}
          >
            CANCEL
          </Button>
          <Button style={{width: '115px'}} primary={true} form="create-custom-event-form">
            SAVE
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
export default Add;
