// @ts-nocheck
import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as constants from './constants';

export interface Standby {
  standbyList: object[];
  totalLists: number;
  activeListPage: number;
  activeTab: string;
  standbySort: any;
  standbySearch: string | undefined;
  activeStandbyId: any;
  activeAdjuster: any;
  editStandby: boolean;
  showInviteMessage: boolean;
  showInviteModal: boolean;
  inviteModalMode: string;
  showCustomEventsModal: boolean;
  customEventModalId: number | null;
  showTwilioModal: boolean;
  selectedId: object[];
}

export interface StandbyAdjusters {
  adjusters: object[];
  mapAdjusters: object[];
  status: string;
  totalAdjusters: number;
  totalMapAdjusters: number;
  activePage: number;
  adjustersPerPage: number;
}

export interface StandbyMessages {
  messages: object[];
  search: string;
  totalMessages: number;
  activePage: number;
  standbyHeader: string;
}

export interface InviteModal {
  adjusters: object[];
  totalAdjusters: number;
  admins: object[];
  totalAdmins: number;
  selectedAdmins: object[];
  selectedAdjusters: object[];
  activeTab: string;
  activePageAdm: number;
  activePageAdj: number;
  activeStandbyTwilio: any;
  msgObj: object;
  admSearch: string;
  pageSize: number;
}

export const createStandbyState: Standby = {
  standbyList: [],
  totalLists: 0,
  activeListPage: 1,
  activeTab: 'Active',
  standbySort: 'createdAt,DESC',
  standbySearch: undefined,
  activeStandbyId: undefined,
  activeAdjuster: {},
  editStandby: false,
  showInviteMessage: false,
  showInviteModal: false,
  showCustomEventsModal: false,
  customEventModalId: null,
  inviteModalMode: 'create',
  showTwilioModal: false,
  selectedId: [],
};

export const createStandbyAdjustersState: StandbyAdjusters = {
  adjusters: [],
  mapAdjusters: [],
  status: 'Applied',
  totalAdjusters: 0,
  totalMapAdjusters: 0,
  activePage: 1,
  adjustersPerPage: 20,
};

export const createStandbyMessagesState: StandbyMessages = {
  messages: [],
  search: '',
  totalMessages: 0,
  activePage: 1,
  standbyHeader: '',
};

export const createInviteModalState: InviteModal = {
  adjusters: [],
  totalAdjusters: 0,
  admins: [],
  totalAdmins: 0,
  selectedAdmins: [],
  selectedAdjusters: [],
  activeTab: 'Active',
  activePageAdj: 1,
  activePageAdm: 1,
  activeStandbyTwilio: undefined,
  msgObj: {},
  admSearch: '',
  pageSize: 15,
};

export interface State {
  standby: Standby;
  adjusters: StandbyAdjusters;
  messages: StandbyMessages;
  inviteModal: InviteModal;
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
  standby: (state = createStandbyState, action) => {
    switch (action.type) {
      case constants.GET_STANDBY_LIST:
        return { ...state };
      case constants.SUBMIT_STANDBY_LIST:
        return { ...state, standbyList: action.payload.list, totalLists: action.payload.count };
      case constants.EDIT_STANDBY_LIST:
        return { ...state, editStandby: action.payload.edit };
      case constants.SET_STANDBY_TAB:
        return { ...state, activeTab: action.payload.tab };
      case constants.SET_STANDBY_SORT:
        return { ...state, standbySort: action.payload.sort };
      case constants.SET_STANDBY_SEARCH:
        return { ...state, standbySearch: action.payload.search };
      case constants.SET_ACTIVE_STANDBY:
        return { ...state, activeStandbyId: action.payload.id };
      case constants.SET_ACTIVE_ADJUSTER:
        return { ...state, activeAdjuster: action.payload.user };
      case constants.SHOW_INVITE_MESSAGE:
        return { ...state, showInviteMessage: action.payload.show };
      case constants.SHOW_INVITE_MODAL:
        return {
          ...state,
          showInviteModal: action.payload.show,
          inviteModalMode: action.payload.mode,
        };
      case constants.SHOW_CUSTOM_EVENTS_MODAL:
        return {
          ...state,
          showCustomEventsModal: action.payload.show,
          customEventModalId: action.payload.id,
        };
      case constants.SHOW_TWILIO_MODAL:
        return { ...state, showTwilioModal: action.payload.show };
      case constants.SET_ACTIVE_PAGE:
        return { ...state, activeListPage: action.payload.page };
      case constants.RESET_STANDBY_FILTER:
        return {
          ...state,
          standbySearch: undefined,
          standbySort: 'createdAt,DESC',
          activeTab: 'Active',
          activeListPage: 1,
        };

      default:
        return state;
    }
  },
  adjusters: (state = createStandbyAdjustersState, action) => {
    switch (action.type) {
      case constants.GET_STANDBY_ADJUSTERS:
        return { ...state };
      case constants.GET_MAP_STANDBY_ADJUSTERS:
        return { ...state };
      case constants.SET_STANDBY_ADJUSTERS:
        return { ...state, adjusters: action.payload.list, totalAdjusters: action.payload.count };
      case constants.SET_MAP_STANDBY_ADJUSTERS:
        return { ...state, mapAdjusters: action.payload.list, totalMapAdjusters: action.payload.count };
      case constants.SET_STANDBY_ADJUSTERS_STATUS:
        return { ...state, status: action.payload.status };
      case constants.SET_STANDBY_ADJUSTERS_PAGE:
        return { ...state, activePage: action.payload.page };
      case constants.SET_STANDBY_ADJUSTERS_PER_PAGE:
        return { ...state, adjustersPerPage: action.payload.perPage };

      default:
        return state;
    }
  },
  messages: (state = createStandbyMessagesState, action) => {
    switch (action.type) {
      case constants.GET_STANDBY_MESSAGES:
        return { ...state };
      case constants.SET_STANDBY_MESSAGES:
        return { ...state, messages: action.payload.list, totalMessages: action.payload.count };
      case constants.SET_STANDBY_MESSAGES_SEARCH:
        return { ...state, search: action.payload.search };
      case constants.SET_STANDBY_MESSAGES_PAGE:
        return { ...state, activePage: action.payload.page };
      case constants.SET_STANDBY_HEADER:
        return { ...state, standbyHeader: action.payload.title };

      default:
        return state;
    }
  },
  inviteModal: (state = createInviteModalState, action) => {
    switch (action.type) {
      case constants.GET_INVITE_MODAL_USERS:
        return { ...state };
      case constants.SET_INVITE_MODAL_USERS:
        return {
          ...state,
          adjusters: action.payload.adjusters,
          admins: action.payload.admins,
          totalAdmins: action.payload.adminsCount,
          totalAdjusters: action.payload.adjustersCount,
        };
      case constants.SET_INVITE_MODAL_TAB:
        return { ...state, activeTab: action.payload.tab };
      case constants.SET_ACTIVE_STANDBY_TWILIO:
        return { ...state, activeStandbyTwilio: action.payload.phone };
      case constants.SET_SELECTED_ADMINS:
        return { ...state, selectedAdmins: action.payload.admins };
      case constants.SET_SELECTED_ADJUSTERS:
        return { ...state, selectedAdjusters: action.payload.adjusters };
      case constants.SET_SELECTED_ADJUSTERS:
        return { ...state, selectedAdjusters: [], selectedAdmins: [] };
      case constants.SET_ACTIVE_PAGE_ADM:
        return { ...state, activePageAdm: action.payload.page };
      case constants.SET_ACTIVE_PAGE_ADJ:
        return { ...state, activePageAdj: action.payload.page };
      case constants.SET_TEMP_MSG_OBJ:
        return { ...state, msgObj: action.payload.obj };
      case constants.SET_INVITE_MODAL_ADM_SEARCH:
        return { ...state, admSearch: action.payload.search, activePageAdm: 1 };
      case constants.RESET_SELECTED_USERS:
        return { ...state, selectedAdmins: [], selectedAdjusters: [] };
      case constants.SET_PAGE_SIZE:
        return { ...state, pageSize: action.payload.size };

      default:
        return state;
    }
  },
});
