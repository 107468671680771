// @ts-nocheck
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Checkbox, Confirm, Image, Modal } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import { standbyActions } from '../../features/standby';
import { IUser } from '../../models';
import services from '../../services';
import { MessageSpiner } from '../loading-spinner/MessageSpinner';
import { NoResults } from '../no-results-placeholder/NoResults';
import BuyTwilioNumber from './../../components/twilio-layout/BuyNumberModal';

interface Props {
  user: IUser;
  show: boolean;
  showModal: (show: boolean) => void;
  showInviteModal: (show: boolean) => void;
  updateData: () => void;
  stbId: any;
  msgObj: object;
  setActiveStandbyTwilio: typeof standbyActions.setActiveStandbyTwilio;
  resetSelectedUsers: typeof standbyActions.resetSelectedUsers;
  selectedTwilioNumbers: number[];
  showMessage: boolean;
}

interface State {
  showModal: boolean;
  value: object[];
  phoneList: object[];
  numberId: any;
  openConfirmModal: boolean;
  disabledNumbers: object[];
}

class TwilioModal extends React.Component<Props, State> {
  public state = {
    showModal: false,
    value: [],
    numberId: [],
    phoneList: [],
    openConfirmModal: false,
    disabledNumbers: [],
  };

  public componentDidMount = async () => {
    this.getNumbers();
  };

  public async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (!prevProps.show && this.props.show) {
      this.setState({ value: [], numberId: [] });
      this.getNumbers();
      this.getDisabledNumbers();
    }
    if (this.props.stbId !== prevProps.stbId) {
      this.getDisabledNumbers();
    }
  }

  public getNumbers = async () => {
    const response = await services.api.settings.getAvailableForListTwilio({
      page: 0,
      pageSize: null,
    });
    if (response.isSuccess) {
      this.setState({ phoneList: response.data.list });
    } else {
      toast.dismiss();
      toast.error(response.errors[0].msg);
    }
  };

  public getDisabledNumbers = async () => {
    const response = await services.api.standby.getStandbyListDetails(this.props.stbId);
    if (response.isSuccess) {
      const disabledNumbers = response.data.numbers.map(n => ({ ...n, isDisabled: true }));
      this.setState({ disabledNumbers });
    }
  };

  public handleChange = (e, data) => {
    const numberId = this.state.phoneList.find(el => el.phoneNumber === data.value).id;

    const updatedValue = [...this.state.value];
    const updatedNumberId = [...this.state.numberId];

    if (!data.checked) {
      const valueIndex = updatedValue.findIndex(v => v === data.value);
      const numberIdIndex = updatedNumberId.findIndex(id => id === numberId);
      updatedValue.splice(valueIndex, 1);
      updatedNumberId.splice(numberIdIndex, 1);
    } else {
      updatedValue.push(data.value);
      updatedNumberId.push(numberId);
    }

    this.setState(
      {
        value: updatedValue,
        numberId: updatedNumberId,
      },
      () => this.props.setActiveStandbyTwilio(this.state.numberId)
    );
  };

  public assignNumber = async () => {
    const { value = [], disabledNumbers = [] } = this.state;
    if (!value.length && !disabledNumbers.length) {
      toast.dismiss();
      toast.error('Phone number is required. Please select one from available list or buy one.');
    } else {
      let response = {
        isSuccess: true,
      };
      if (value.length) {
        response = await services.api.standby.assignNumber(this.state.numberId, this.props.stbId);

        if (response.isSuccess && this.props.showMessage) {
          toast.success('Selected number(s) was successfully added!');
        }
      }

      if (window.location.hash === '#reopen') {
        if (response.isSuccess) {
          this.props.showModal(false);
          services.router.goto('/stand-by-lists');
        } else {
          toast.dismiss();
          toast.error(
            response.data.errors ? response.data.errors[0].msg : `Error ${response.status}`
          );
        }
      } else {
        if (response.isSuccess) {
          if (this.props.msgObj) {
            const sendMsg = await services.api.messages.sendMessage(
              this.props.msgObj,
              this.props.stbId
            );
            if (sendMsg.isSuccess) {
              this.props.showModal(false);
              this.props.showInviteModal(false);
              this.props.resetSelectedUsers();
              this.props.updateData();
              toast.dismiss();
              toast.success('The message to the selected recipient(s) was sent successfully!');
            } else {
              toast.dismiss();
              toast.error(
                sendMsg.data.errors ? sendMsg.data.errors[0].msg : `Error ${sendMsg.status}`
              );
            }
          } else {
            this.props.showTwilioModalFunc(false);
          }
        } else {
          toast.dismiss();
          toast.error(
            response.data.errors ? response.data.errors[0].msg : `Error ${response.status}`
          );
        }
      }
    }
  };

  public handleOk = () => {
    const { value, disabledNumbers } = this.state;
    const { selectedAdjusters } = this.props;

    if ((value.length + disabledNumbers.length) * 200 < selectedAdjusters.length) {
      this.setState({ openConfirmModal: true });
    } else {
      this.assignNumber();
    }
  };

  public render() {
    const { value, openConfirmModal, disabledNumbers } = this.state;
    const { selectedTwilioNumbers = [] } = this.props;
    const showWarning =
      (value.length + disabledNumbers.length) * 200 < this.props.selectedAdjusters.length;
    const phoneList = [...this.state.phoneList, ...disabledNumbers];

    return (
      <div>
        <Modal
          open={this.props.show}
          size="small"
          closeOnDimmerClick={false}
          closeIcon={
            <Image src={closeIcon} className="icon" onClick={() => this.props.showModal(false)} />
          }
          className="standby-twilio-modal"
        >
          <Modal.Header>Twilio Number</Modal.Header>
          <Modal.Content>
            <MessageSpiner area="message-sending-area" />
            <div>
              This standby list has {value.length + disabledNumbers.length} Twilio Number selected.
              Please select from available phone numbers or buy a new one if there are no available
              numbers.
            </div>
            <div className="input-field-container standby-twilio-content">
              <div>Available numbers</div>
              <div className="checkboxes-list-select">
                {phoneList.filter(el => !el.standbyList).length === 0 ? (
                  <NoResults />
                ) : (
                  phoneList
                    .filter(el => !el.standbyList)
                    .map((el: any, index: number) => {
                      return (
                        <Checkbox
                          disabled={!!el.isDisabled}
                          value={el.phoneNumber}
                          label={el.phoneNumber}
                          checked={value.includes(el.phoneNumber) || !!el.isDisabled}
                          key={index}
                          onChange={this.handleChange}
                        />
                      );
                    })
                )}
              </div>
            </div>
            {showWarning && (
              <div className="warning-label-custom">
                * It is recommended to have at least 1 number for each 200 selected adjusters to
                avoid delays
              </div>
            )}
            <BuyTwilioNumber user={this.props.user} reloadPurchasedNumbers={this.getNumbers} />
          </Modal.Content>
          <div className="modal-button-footer">
            <Button
              type="button"
              style={{ width: '115px' }}
              className="secondary-button"
              secondary={true}
              onClick={() => this.props.showModal(false)}
            >
              CANCEL
            </Button>
            <Button
              style={{ width: '115px' }}
              primary={true}
              onClick={this.handleOk}
              disabled={!disabledNumbers.length && selectedTwilioNumbers.length === 0}
            >
              OK
            </Button>
          </div>
        </Modal>
        <Confirm
          size="tiny"
          closeIcon={
            <Image src={closeIcon} className="icon" onClick={() => this.props.showModal(false)} />
          }
          open={openConfirmModal}
          onCancel={() => this.setState({ openConfirmModal: false })}
          header="Confirm"
          content={`You have selected ${value.length +
            disabledNumbers.length} number(s), the recommended amount of numbers is ${Math.ceil(
            this.props.selectedAdjusters.length / 200
          )}. Do you want to proceed with the current selected number(s)?`}
          cancelButton={{
            content: 'NO',
          }}
          confirmButton={{
            content: 'YES',
          }}
          onConfirm={() => {
            this.setState({ openConfirmModal: false }, () => this.assignNumber());
          }}
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  selectedAdjusters: state.standby.inviteModal.selectedAdjusters,
  selectedTwilioNumbers: state.standby.inviteModal.activeStandbyTwilio,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setActiveStandbyTwilio: standbyActions.setActiveStandbyTwilio,
      showTwilioModalFunc: standbyActions.showTwilioModal,
      resetSelectedUsers: standbyActions.resetSelectedUsers,
    },
    dispatch
  );

export default connect<Props, State>(
  mapStateToProps,
  mapDispatchToProps
)(TwilioModal);
