// @ts-nocheck
import React from 'react';
import ConfirmModal from "../../confirm-modal/ConfirmModal";
import services from "../../../services";
import {toast} from "react-toastify";
import MyTypes from "MyTypes";
import {bindActionCreators, Dispatch} from "redux";
import {trainingCenterActions} from "../../../features/training-center";
import {connect} from "react-redux";

interface CustomEvent {
  id: number;
  name: string
}

interface DeleteProps {
  setModalsStatus: (status: string | null) => void;

  getCustomEventList(): void;

  currentEvent: CustomEvent
}

const Delete = (props: DeleteProps) => {

  const doDelete = async () => {
    const response = await services.api.folder.deleteCustomEvent(props.currentEvent.id);
    if (response.isSuccess) {
      toast.dismiss();
      toast.success('Custom event was successfully removed!');
      props.setModalsStatus(null)
      props.getCustomEventList()
    } else {
      console.log(response);
      toast.dismiss();
      toast.error(
        response.data ? response.data.errors[0].msg : `Error ${response.status}`
      );
    }
  }
  return (
    <ConfirmModal
      visible={true}
      onClose={() => props.setModalsStatus(null)}
      onConfirm={doDelete}
      message={`Are you sure you want to remove the custom event "${
        props.currentEvent ? props.currentEvent.name : ''
      }"?`}
    />
  );
};

const mapStateToProps = (state: MyTypes.RootState) => ({
  customEventList: state.trainingCenter.customEventList

})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCustomEventList: trainingCenterActions.getCustomEventList
    }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Delete);
