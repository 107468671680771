// @ts-nocheck
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Image, Label, Menu, Popup } from 'semantic-ui-react';
import gearIcon from '../../assets/images/White/gear.svg';
import messageIcon from '../../assets/images/White/message.svg';
import { api } from '../../config';
import { AdjusterUser, IUser } from '../../models';
import services from '../../services';
import StatusActions from './../adjuster-list/StatusActions';
import SetEmailModal from './SetEmailModal';

interface Props {
  getTabName: (name: string) => void;
  submitRef: any;
  user: AdjusterUser;
  update: (id: any) => void;
  admin: IUser;
}

interface State {
  activeTab: string;
  showSection: boolean;
  showEmailModal: boolean;
}

class SettingsHeader extends React.Component<Props, State> {
  public state = {
    activeTab: 'Profile',
    showSection: false,
    showEmailModal: false,
  };

  public handleStatus = async (status: number, adjusterId: any, name: string) => {
    if (status === 1) {
      const response = await services.api.adjuster.changeAdjusterStatus(adjusterId, 'Active');
      if (response.isSuccess) {
        this.setState({ showEmailModal: false });
        toast.dismiss();
        toast.success(`Adjuster ${name} status was successfully activated!`);
        this.updateProfileStatus();
      } else {
        toast.dismiss();
        toast.error(response.data ? response.data.errors[0].msg : `Error ${response.status}`);
      }
    }
  };

  public handleItemClick = (e: any, { name }: any) => {
    this.props.getTabName(name);
    this.setState({ activeTab: name, showSection: false });
  };

  public showEmailModal = (status: boolean) => {
    this.setState({ showEmailModal: status });
  };

  public handleLoginInAsAdjuster = async (id: any) => {
    const response = await services.api.adjuster.loginAsAdjuster(id);

    if (response.isSuccess) {
      window.open(`${api.app_endpoint}admin-login/${response.data.token}`, '_blank');
      toast.dismiss();
      toast.success(
        `Logged in successfully as ${this.props.user.firstName} ${this.props.user.lastName}.`
      );
    } else {
      toast.dismiss();
      toast.error(response.data ? response.data.errors[0].msg : `Error ${response.status}`);
    }
  };

  public updateProfileStatus = () => {
    this.props.update(this.props.user.id);
  };

  public render() {
    const { activeTab } = this.state;
    const { user, admin } = this.props;
    return (
      <div className="settings-header-container">
        <Menu text={true} className="tabs-menu" style={{ margin: 0 }}>
          <Menu.Item name="Profile" active={activeTab === 'Profile'} onClick={this.handleItemClick}>
            Profile
          </Menu.Item>
          <Menu.Item
            name="Professional Skills"
            active={activeTab === 'Professional Skills'}
            onClick={this.handleItemClick}
          >
            Professional Skills
          </Menu.Item>
          <Menu.Item
            name="Licenses"
            active={activeTab === 'Licenses'}
            onClick={this.handleItemClick}
          >
            Licenses
          </Menu.Item>
          <Menu.Item
            name="Certifications"
            active={activeTab === 'Certifications'}
            onClick={this.handleItemClick}
          >
            Certifications
          </Menu.Item>
          <Menu.Item name="Resumes" active={activeTab === 'Resumes'} onClick={this.handleItemClick}>
            Resumes
          </Menu.Item>
          <Menu.Item
            name="Paperwork"
            active={activeTab === 'Paperwork'}
            onClick={this.handleItemClick}
          >
            Paperwork
          </Menu.Item>
          {admin.financeAccess && (
            <Menu.Item
              name="Finances"
              active={activeTab === 'Finances'}
              onClick={this.handleItemClick}
              disabled={!this.props.admin.financeAccess}
            >
              Finances
            </Menu.Item>
          )}
          <Menu.Item name="Logs" active={activeTab === 'Logs'} onClick={this.handleItemClick}>
            Logs
          </Menu.Item>
        </Menu>
        {admin.role !== 'Read Only Office Admin' && (
          <div className="adjuster-header-actions">
            <Popup
              trigger={
                <Button
                  primary={true}
                  className="adjuster-action-button"
                  onClick={() => services.router.goto(`/messages/room/${this.props.user.id}`)}
                >
                  <Image src={messageIcon} />
                  {user && user.hasUnreadMessages && (
                    <Label color="red" floating={true} circular={true} empty={true} />
                  )}
                </Button>
              }
              basic={true}
              size="mini"
              content={'Send Message'}
            />

            <StatusActions
              showEmailModal={this.showEmailModal}
              trigger={
                <Popup
                  trigger={
                    <Button primary={true} className="adjuster-action-button">
                      <Image src={gearIcon} />
                    </Button>
                  }
                  basic={true}
                  size="mini"
                  content={'Actions'}
                />
              }
              user={this.props.user}
              activate={this.handleStatus}
              callback={this.updateProfileStatus}
            />

            {this.state.activeTab === 'Profile' && (
              <Button
                className="add-admin-button"
                onClick={() => this.props.submitRef.current.ref.current.click()}
              >
                Update
              </Button>
            )}
            {user && user.status !== 'Deleted' && user.status !== 'Archived' && (
              <Button
                className="add-admin-button"
                onClick={() => this.handleLoginInAsAdjuster(user ? user.id : 0)}
              >
                Log In
              </Button>
            )}
            <SetEmailModal
              name={this.props.user ? this.props.user.firstName : ''}
              show={this.state.showEmailModal}
              showModal={this.showEmailModal}
              handleStatus={this.handleStatus}
              id={this.props.user ? this.props.user.id : 0}
            />
          </div>
        )}
      </div>
    );
  }
}

export default SettingsHeader;
