// @ts-nocheck
import moment from 'moment';
import React from 'react';
import { Button } from 'semantic-ui-react';
import { deleteNote, deleteStandbyNote } from '../../services/api/standby';
import ConfirmModal from '../confirm-modal/ConfirmModal';
import { toast } from 'react-toastify';

interface Props {
  item: string;
  user: any;
  update: any;
  standbyId?: number;
}

interface State {}

class InternalNoteBlock extends React.Component<Props, State> {
  public state = {
    confirmOpen: false,
    selectedIndex: null,
  };

  openConfirmModal = async (index: number) => {
    this.setState({ confirmOpen: true, selectedIndex: index });
  };

  closeConfirmModal = () => {
    this.setState({ confirmOpen: false });
  };

  handleDelete = async () => {
    try {
      const userId = this.props.user.id;
      const index = this.state.selectedIndex;

      if (typeof index === 'number') {
        if (this.props.standbyId) {
          await deleteStandbyNote(this.props.standbyId, userId, index);
        } else {
          await deleteNote(userId, index);
        }

        this.props.update(userId);

        this.closeConfirmModal();

        toast.success('Note was successfully deleted!');
      }
    } catch (e) {
      toast.error(e);
    }
  };

  public render() {
    const { item } = this.props;
    return (
      <div className="internal-note-block">
        {item.map((el: any, index: any) => (
          <div key={index}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <p className="note-name">{el.author}</p>
              <p className="note-date">{moment(el.date).format('MM/DD/YYYY, hh:mm A')}</p>
            </div>
            <div className="note-description">{el.note}</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                width: '100%',
              }}
            >
              <Button
                type="button"
                className="iconic-button-custom"
                onClick={() => this.openConfirmModal(index)}
                style={{
                  width: '20%',
                  marginLeft: 'auto',
                }}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.93112 19L7.07398 7H14.926L14.0689 19H7.93112Z"
                    stroke="#2259A1"
                    stroke-width="2"
                  />
                  <path d="M4.13281 6H18.1417" stroke="#2259A1" stroke-width="2" />
                  <path d="M7.99891 3H13.9989" stroke="#2259A1" stroke-width="2" />
                </svg>
              </Button>
            </div>
            {index !== item.length - 1 && <hr />}
          </div>
        ))}
        <ConfirmModal
          visible={this.state.confirmOpen}
          onConfirm={this.handleDelete}
          onClose={this.closeConfirmModal}
          message={'Are you sure you want to delete this note?'}
        />
      </div>
    );
  }
}

export default InternalNoteBlock;
