// @ts-nocheck
import React from 'react';
import { Button, Confirm, Grid, Image, Popup } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import trashIcon from '../../assets/images/Blue/trash.svg';
import { IUser } from '../../models';

interface Props {
  item: object;
  deleteNumber: (id: number, name: string) => void;
  user: IUser;
}

interface State {
  isGlobal: boolean;
  standbyList: any;
  phoneNumber: string;
  showConfirm: boolean;
}

class TwilioNumber extends React.Component<Props, State> {
  public state = {
    isGlobal: false,
    standbyList: null,
    phoneNumber: '',
    showConfirm: false,
  };

  public componentDidMount() {
    this.setState({
      isGlobal: this.props.item.global,
      standbyList: this.props.item.standbyList,
      phoneNumber: this.props.item.phoneNumber,
    });
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.item !== this.props.item) {
      this.setState({
        isGlobal: this.props.item.global,
        standbyList: this.props.item.standbyList,
        phoneNumber: this.props.item.phoneNumber,
      });
    }
  }

  public renderNumberText() {
    if (this.state.isGlobal) {
      return 'Global';
    }

    if (this.state.standbyList) {
      return this.state.standbyList.title;
    }

    return 'Available';
  }

  public render() {
    const isAssignedOrGlobal = !!this.state.standbyList || this.state.isGlobal;

    return (
      <React.Fragment>
        <div className={`prof-item-container${isAssignedOrGlobal ? ' assigned' : ''}`}>
          <Grid style={{ width: '100%' }}>
            <Grid.Row>
              <Grid.Column width={7} className="twilio-number-part">
                {this.state.phoneNumber}
              </Grid.Column>
              <Grid.Column width={7} className="twilio-number-part">
                {this.renderNumberText()}
              </Grid.Column>
              <Grid.Column width={2} style={{ paddingRight: 0 }}>
                <Popup
                  trigger={
                    <Button
                      className="iconic-button-custom"
                      disabled={
                        isAssignedOrGlobal ||
                        this.props.user.role === 'Read Only Office Admin' ||
                        this.props.user.role === 'Office Admin'
                      }
                      style={{ float: 'right' }}
                      onClick={() => {
                        if (
                          this.props.user.role === 'Read Only Office Admin' ||
                          this.props.user.role === 'Office Admin'
                        ) {
                          return;
                        }
                        this.setState({ showConfirm: true });
                      }}
                    >
                      <Image src={trashIcon} />
                    </Button>
                  }
                  disabled={isAssignedOrGlobal}
                  size="mini"
                  basic={true}
                  content="Delete"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Confirm
          size="tiny"
          closeIcon={
            <Image
              src={closeIcon}
              className="icon"
              onClick={() => this.setState({ showConfirm: false })}
            />
          }
          open={this.state.showConfirm}
          onCancel={() => this.setState({ showConfirm: false })}
          header="Confirm"
          content={`Are you sure you want to release number ${this.state.phoneNumber}?`}
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={() => {
            this.props.deleteNumber(this.props.item.id, this.state.phoneNumber);
            this.setState({ showConfirm: false });
          }}
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </React.Fragment>
    );
  }
}

export default TwilioNumber;
