// @ts-nocheck
import moment from 'moment';
import Types from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Container } from 'semantic-ui-react';

import FinanceFilter from '../../components/finance-list/FinanceFilter';
import FinanceListHeader from '../../components/finance-list/FinanceListHeader';
import FinanceTable from '../../components/finance-list/FinanceTable';
import FinanceFilesUploader from '../../components/finance-list/uploader/FinanceFilesUploader';
import ListLoadingSpinner from '../../components/list-loading-spinner/ListLoadingSpinner';

import { financeActions } from '../../features/finance';
import { FinanceListFetchParam } from '../../services/api/finance';

interface Props {
  fetchingList: boolean;
  user: any;
  finances: any[];
  adjusters: any[];
  admins: any[];
  totalFinances: number;
  deletingFinance: boolean;
  deleteFinanceError: string | null;
  exporting: boolean;
  fetchFinanceList: typeof financeActions.fetchFinanceList;
  exportFinanceList: (params: FinanceListFetchParam, sort?: any) => void;
  deleteFinancialFile: (id: number) => void;
  downloadFinance: (index: number) => void;
}

interface State {
  filterVisible: boolean;
  uploaderVisible: boolean;
  filter: any;
  page: number;
  pageSize: number;
}

const initialFilter = {
  from: '',
  to: '',
  uploaderId: '',
  adjusterId: '',
  name: '',
  sort: 'createdAt',
};

class FinanceList extends React.Component<Props, State> {
  public state = {
    filterVisible: false,
    uploaderVisible: false,
    page: 1,
    pageSize: 20,
    filter: {
      ...initialFilter,
    },
  };

  public componentDidMount() {
    this.fetchFinances();
  }

  public handleFilterPress = () => {
    this.setState({
      filterVisible: !this.state.filterVisible,
    });
  };

  public handleExportPress = () => {
    const { params, sort } = this.buildRequestData();
    this.props.exportFinanceList(params, sort);
  };

  public handleUploadPress = () => {
    this.setState({
      uploaderVisible: true,
    });
  };

  public handleUploaderClose = () => {
    this.setState({
      uploaderVisible: false,
    });
  };

  public handleFilterReset = () => {
    this.setState(
      {
        filter: {
          ...initialFilter,
        },
        page: 1,
      },
      () => this.fetchFinances()
    );
  };

  public handleFilterChange = (data: any) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          ...data,
        },
        page: 1,
      },
      () => this.fetchFinances()
    );
  };

  public handlePagination = (nextPage: number) => {
    this.changePageAndRefresh(nextPage);
  };

  public handleSaveFinancesDone = () => {
    this.setState(
      {
        uploaderVisible: false,
        page: 1,
      },
      () => this.fetchFinances()
    );
  };

  public handleOnDeletedFinanceDone = () => {
    this.changePageAndRefresh(1);
  };

  public render() {
    const { uploaderVisible, filter, page, pageSize } = this.state;
    const {
      fetchingList,
      finances,
      adjusters,
      admins,
      totalFinances,
      deleteFinancialFile,
      deletingFinance,
      deleteFinanceError,
      downloadFinance,
      exporting,
      user,
    } = this.props;

    return (
      <Container className="finance-container">
        <FinanceListHeader
          user={user}
          exporting={exporting}
          onExportPress={this.handleExportPress}
          onFilterPress={this.handleFilterPress}
          onUploadPress={this.handleUploadPress}
        />
        {this.state.filterVisible && (
          <FinanceFilter
            filter={filter}
            adjusters={adjusters}
            admins={admins}
            onFilterReset={this.handleFilterReset}
            onFilterChange={this.handleFilterChange}
          />
        )}
        {fetchingList ? (
          <ListLoadingSpinner className="loading" />
        ) : (
          <FinanceTable
            finances={finances}
            totalFinances={totalFinances}
            page={page}
            user={user}
            pageSize={pageSize}
            onPageChange={this.handlePagination}
            onDeleteFinance={deleteFinancialFile}
            deletingFinance={deletingFinance}
            deleteFinanceError={deleteFinanceError}
            onDeletedFinanceDone={this.handleOnDeletedFinanceDone}
            onDownloadFinance={downloadFinance}
          />
        )}
        <FinanceFilesUploader
          visible={uploaderVisible}
          onClose={this.handleUploaderClose}
          onSaveDone={this.handleSaveFinancesDone}
        />
      </Container>
    );
  }

  private changePageAndRefresh(page: number) {
    this.setState(
      {
        page,
      },
      () => this.fetchFinances()
    );
  }

  private buildRequestData() {
    const { filter, page, pageSize } = this.state;
    const params: FinanceListFetchParam = {
      page: page - 1,
      pageSize,
    };
    if (filter.from) {
      params.from = filter.from;
    }
    if (filter.to) {
      params.to = filter.to;
    }
    if (filter.from && !filter.to) {
      params.to = moment()
        .add(1, 'd')
        .format();
    }
    if (filter.to && !filter.from) {
      params.from = moment('2000/01/01').format();
    }
    if (filter.name) {
      params.name = filter.name;
    }
    if (filter.adjusterId) {
      params.adjusterId = filter.adjusterId;
    }
    if (filter.uploaderId) {
      params.uploaderId = filter.uploaderId;
    }

    const sort: any = {};
    switch (filter.sort) {
      case 'createdAt':
        sort['finance.createdAt'] = 'DESC';
        break;
      case 'adjuster':
        sort['adjuster.firstName'] = 'ASC';
        sort['adjuster.lastName'] = 'ASC';
        break;
      case 'name':
        sort['file.name'] = 'ASC';
        break;
      case 'uploadedBy':
        sort['uploader.firstName'] = 'ASC';
        sort['uploader.lastName'] = 'ASC';
        break;
    }

    return {
      params,
      sort,
    };
  }

  private fetchFinances() {
    const { fetchFinanceList } = this.props;
    const { params, sort } = this.buildRequestData();

    fetchFinanceList(params, sort);
  }
}

const mapStateToProps = (state: Types.RootState) => ({
  fetchingList: state.finance.fetchingList,
  finances: state.finance.finances,
  adjusters: state.finance.adjusters,
  admins: state.finance.admins,
  totalFinances: state.finance.totalFinances,
  deletingFinance: state.finance.deletingFinance,
  deleteFinanceError: state.finance.deleteFinanceError,
  exporting: state.finance.exporting,
  user: state.account.account.user,
});

const mapDispatchToProps = (dispatch: Dispatch<Types.RootAction>) =>
  bindActionCreators(
    {
      fetchFinanceList: financeActions.fetchFinanceList,
      exportFinanceList: financeActions.exportFinanceList,
      deleteFinancialFile: financeActions.deleteFinancialFile,
      downloadFinance: financeActions.downloadFinance,
    },
    dispatch
  );

export default connect<Props, State>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(FinanceList);
