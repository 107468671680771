import { bind } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';

export type AdjustersFilterType = {
  gender: string[],
  race: string[],
  state: string[],
  licenseStates: string[],
  certificationTypes: string[],
  experience: string[],
  position: string[],
  equipment: string[],
  expertise: string[],
  softwareSkill: string[],
  catStateProvince: string[],
  language: string[],
  registrationType: string[],
  standbyListLocations: string[],
  deploymentsFilters: string[],
  standbyListDeployments: string[],
  eventDeployments: string[],
  deploymentStatus: string[],
  orientation: string[],
  orientationAttended: string[],
  deployed?: boolean,
  availableForClaims?: boolean,
  largeLossTeam?: boolean,
  commercialTeam?: boolean,
  isAdmin?: boolean,
  allPaperworkCompleted?: boolean,
  sort: string,
  name: string,
  email: string,
  phone: string,
  city: string,
  zip: string,
  xactnetAddress: string,
  catCity: string,
  catZip: string,
  eventLocation: string,
  milesRange: string,
  certificationScore: string,
  fieldInterviewScore: string,
  deskInterviewScore: string,
  qaScore: string,
  licenseConfirmation: string,
  xactimateQualificationStatus: string,
  profilesInstalled: string,
  firstReviewCompleted: string,
  authorityLevelFrom: string,
  authorityLevelTo: string,
  stateQuick: string,
  apply?: AdjustersFilterType,
}

const defaultValue = {
  gender: [],
  race: [],
  state: [],
  licenseStates: [],
  certificationTypes: [],
  experience: [],
  position: [],
  equipment: [],
  expertise: [],
  softwareSkill: [],
  catStateProvince: [],
  language: [],
  registrationType: [],
  standbyListLocations: [],
  deploymentsFilters: [],
  standbyListDeployments: [],
  eventDeployments: [],
  deploymentStatus: [],
  orientation: [],
  orientationAttended: [],
  sort: "updatedAt,desc",
  name: '',
  email: '',
  phone: '',
  city: '',
  zip: '',
  xactnetAddress: '',
  catCity: '',
  catZip: '',
  eventLocation: '',
  milesRange: '',
  certificationScore: '',
  fieldInterviewScore: '',
  deskInterviewScore: '',
  qaScore: '',
  licenseConfirmation: '',
  xactimateQualificationStatus: '',
  profilesInstalled: '',
  firstReviewCompleted: '',
  authorityLevelFrom: '',
  authorityLevelTo: '',
  stateQuick: '',
}

const [adjustersFilterChange, setAdjustersFilter] =
  createSignal<AdjustersFilterType>();

const [useAdjustersFilter, adjustersFilter] = bind<AdjustersFilterType>(
  adjustersFilterChange,
  defaultValue,
);

function resetAdjustersFilter(name?: keyof AdjustersFilterType ) {
  const currentValue = adjustersFilter.getValue();

  if (!name) {
    setAdjustersFilter(defaultValue)
    return;
  }

  if (Array.isArray(currentValue[name])) {
    setAdjustersFilter({
      ...currentValue,
      [name]: []
    })
  } else {
    delete currentValue[name]
    setAdjustersFilter({
      ...currentValue,
    })
  }
}

function getCleanedAdjustersFilter() {
  const currentValue = adjustersFilter.getValue();

  const result = {} as AdjustersFilterType

  Object.entries(currentValue).forEach(([key, value]) => {
    if (key === 'apply') return
    if (Array.isArray(value)) {
      if (value.length) {
        (result[key as keyof AdjustersFilterType] as string[]) = value as string[]
      }
    } else {
      (result[key as keyof AdjustersFilterType] as boolean | string) = value as boolean | string
    }
  })

  return result;
}

export {
  getCleanedAdjustersFilter,
  resetAdjustersFilter,
  adjustersFilter,
  setAdjustersFilter,
  useAdjustersFilter,
};
