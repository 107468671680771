// @ts-nocheck
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid } from 'semantic-ui-react';
import { adjustersActions } from '../../features/adjusters';
import { dashboardActions } from '../../features/dashboard';
import AdjustersToReview from './../../components/layout-dashboard/AdjustersToReview';
import DashboardHeader from './../../components/layout-dashboard/dashboardHeader';
import PendingAdjusters from './../../components/layout-dashboard/PendingAdjusters';
import UnreadMessages from './../../components/layout-dashboard/UnreadMessages';
import { LoadingSpiner } from './../../components/loading-spinner/LoadingSpinner';

interface Props {
  getDashboardAdjusters: typeof dashboardActions.getDashboardAdjusters;
  setActiveTab: typeof adjustersActions.setActiveListTab;
  activeAdj: object[];
  activeAdjCount: number;
  inactiveAdj: object[];
  inactiveAdjCount: number;
  pendingAdj: object[];
  pendingAdjCount: number;
  toReviewAdj: object[];
  toReviewAdjCount: number;
  unreadMessages: object[];
}

interface State {
  activeAdj: object[];
  pendingAdj: object[];
  inactiveAdj: object[];
  toReviewAdj: object[];
  activeAdjCount: number;
  inactiveAdjCount: number;
  pendingAdjCount: number;
  toReviewAdjCount: number;
}

class Dashboard extends React.Component<Props, State> {
  public state = {
    activeAdj: [],
    pendingAdj: [],
    inactiveAdj: [],
    toReviewAdj: [],
    activeAdjCount: 0,
    inactiveAdjCount: 0,
    pendingAdjCount: 0,
    toReviewAdjCount: 0,
  };

  public componentDidMount = () => {
    this.props.getDashboardAdjusters();
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.pendingAdj !== this.props.pendingAdj) {
      this.setState({
        pendingAdj: this.props.pendingAdj,
        pendingAdjCount: this.props.pendingAdjCount,
      });
    }
    if (prevProps.toReviewAdj !== this.props.toReviewAdj) {
      this.setState({
        toReviewAdj: this.props.toReviewAdj,
        toReviewAdjCount: this.props.toReviewAdjCount,
      });
    }
  };

  public render() {
    return (
      <div className="dashboard">
        <DashboardHeader
          setTab={this.props.setActiveTab}
          activeAdj={this.props.activeAdjCount}
          pendingAdj={this.state.pendingAdjCount}
          inactiveAdj={this.props.inactiveAdjCount}
        />
        <LoadingSpiner area="dashboard-page" />
        <Grid stackable={true} className="dashboard-content-grid">
          <Grid.Column width={8}>
            <PendingAdjusters setTab={this.props.setActiveTab} pendingAdj={this.state.pendingAdj} />
            <AdjustersToReview
              setTab={this.props.setActiveTab}
              toReviewAdj={this.state.toReviewAdj}
            />
          </Grid.Column>
          <Grid.Column width={8} className="unread-messages-column">
            <UnreadMessages messages={this.props.unreadMessages} />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  activeAdj: state.dashboard.adjusters.activeAdj,
  activeAdjCount: state.dashboard.adjusters.activeCount,
  inactiveAdj: state.dashboard.adjusters.inactiveAdj,
  inactiveAdjCount: state.dashboard.adjusters.inactiveCount,
  pendingAdj: state.dashboard.adjusters.pendingAdj,
  pendingAdjCount: state.dashboard.adjusters.pendingCount,
  toReviewAdj: state.dashboard.adjusters.toReviewAdj,
  toReviewAdjCount: state.dashboard.adjusters.toReviewCount,
  unreadMessages: state.dashboard.adjusters.unreadMessages,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getDashboardAdjusters: dashboardActions.getDashboardAdjusters,
      setActiveTab: adjustersActions.setActiveListTab,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
