import { useEffect, useState } from "react";
import { LoadingSpiner } from "../loading-spinner/LoadingSpinner";
import { NoResults } from "../no-results-placeholder/NoResults";
import { Pagination, PaginationProps } from "semantic-ui-react";

function ListPagination<T extends object & { id?: number }>({
  data,
  Item,
  paginationStyle,
  stickToBottom,
  itemPerPage = 10
}: {
  data: T[],
  Item: React.FC<{ data: T }>,
  paginationStyle?: React.CSSProperties,
  stickToBottom?: boolean,
  itemPerPage?: number,
}) {
  const [activePage, setActivePage] = useState(1)
  const totalPages = Math.ceil(data.length / itemPerPage)
  const page = Math.min(totalPages, activePage)

  function handlePagination(event: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) {
    setActivePage(Number(data.activePage || 1))
  }

  useEffect(() => {
    if (activePage > totalPages) {
      setActivePage(totalPages)
    }
  }, [totalPages, activePage])

  return (
    <div className="message-group-recipients"
      style={stickToBottom ? {
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
      } : {}}
    >
      <LoadingSpiner area="messages-list-area" />
      {data.length === 0 && <NoResults />}
      {data.slice((page - 1) * itemPerPage, (page - 1) * itemPerPage + itemPerPage).map((el, index: number) => {
        return <Item data={el} key={JSON.stringify(el) || index} />
      })}
      {totalPages > 1 && (
        <>
          {stickToBottom && <div style={{ flex: '1 1 auto' }}/>}
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', ...paginationStyle }}>
            <Pagination
              totalPages={totalPages}
              boundaryRange={1}
              activePage={page}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              prevItem={
                page === 1
                  ? false
                  : {
                      'aria-label': 'Previous item',
                      content: 'PREVIOUS',
                    }
              }
              nextItem={
                page === totalPages
                  ? false
                  : {
                      'aria-label': 'Next item',

                      content: 'NEXT',
                    }
              }
              siblingRange={1}
              onPageChange={handlePagination}
            />
          </div>
        </>
      )}
    </div>
  )
}


export default ListPagination;