// @ts-nocheck
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Image, Popup, Table } from 'semantic-ui-react';
import cancelIconB from '../../../assets/images/Blue/cancel.svg';
import checkIconB from '../../../assets/images/Blue/check.svg';
import noAvatarIcon from '../../../assets/images/no-avatar.svg';
import checkIconW from '../../../assets/images/White/check.svg';
import { uploads } from '../../../config';
import { standbyActions } from '../../../features/standby';
import { IUser } from '../../../models';
import { fetchAdjustersNoPagination } from '../../../services/api/adjuster';
import { getStandbyAdjustersNoPagination } from '../../../services/api/standby';
import * as helper from '../../../utils/helper';
import statesUS from '../../../utils/statesUSbyKey';
import CustomIconicButton from '../../adjuster-list/CustomIconicButton';

interface Props {
  setSelectedAdjusters: typeof standbyActions.setSelectedAdjusters;
  getSelectedAdjusters: typeof standbyActions.getSelectedAdjusters;
  adjusters: object[];
  selectedAdjusters: object[];
  user: IUser;
  selectedTab: boolean;
  adjustersCount: number;
  adjustersFilter: object;
  selectedTabs: any;
  activeTab: string;
  standbyId: string;
  removeItem: (id: any, name: string) => void;
  handleSelectedTabs: (selected: any) => void;
}

interface State {
  adjusters: object[];
  selectedAdjusters: any[];
  prevSelectedAdjusters: { [tabName: string]: number[] };
}

class SendMessageTableAdj extends React.Component<Props, State> {
  public state = {
    adjusters: [],
    selectedAdjusters: [],
    prevSelectedAdjusters: {} as { [tabName: string]: number[] },
  };

  public componentDidMount = async () => {
    this.setState({
      adjusters: this.props.adjusters,
      selectedAdjusters: this.props.selectedAdjusters,
    });
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.adjusters !== this.props.adjusters) {
      this.setState({ adjusters: this.props.adjusters });
    }

    if (prevProps.selectedAdjusters !== this.props.selectedAdjusters) {
      this.setState({ selectedAdjusters: this.props.selectedAdjusters });
    }
  };

  public handleSelection = (adjuster: any) => {
    const { selectedAdjusters } = this.state;
    const clonedArray: any = selectedAdjusters.map(el => el);
    const index = clonedArray.findIndex(
      (el: any) => el.id === adjuster.id && el.email === adjuster.email
    );
    if (index === -1) {
      clonedArray.push(adjuster);
      this.onAddLocalSelected(adjuster.id);
    } else {
      this.onRemoveLocalSelected(adjuster.id);
      clonedArray.splice(index, 1);
    }
    this.props.setSelectedAdjusters(clonedArray);
  };

  public selectAll = async () => {
    const { prevSelectedAdjusters } = this.state;
    const {
      selectedTabs,
      handleSelectedTabs,
      setSelectedAdjusters,
      selectedAdjusters,
      activeTab,
      standbyId,
    } = this.props;
    if (!this.isActive()) {
      if (this.isIncludesIds()) {
        return;
      }

      const multipleFieldsFilters = [
        'state',
        'licenseStates',
        'certificationTypes',
        'experience',
        'position',
        'equipment',
        'expertise',
        'softwareSkill',
        'catStateProvince',
        'language',
        'registrationType',
      ];

      const status = activeTab;
      let params: any = { noPagination: true };
      Object.entries(this.props.adjustersFilter).forEach(item => {
        const [key, value] = item;
        if (value) {
          if (multipleFieldsFilters.includes(key) && !Array.isArray(value)) {
            params[key] = value.split(',');
          } else if (key === 'standbyListDeployments' || key === 'eventDeployments') {
            params[key] = value.map((f: any) => (f === 'exclude' ? 'exclude' : f.id));
          } else {
            params[key] = value;
          }
        }
      });
      let fetchData;
      switch (status) {
        case 'Pending':
        case 'Inactive':
        case 'Active':
        case 'Selected':
        case 'QuickApplied':
          params = { ...params, status };
          fetchData = fetchAdjustersNoPagination;
          break;
        case 'Applied':
        case 'Invited':
        case 'Contacted':
          params = { ...params, status, id: standbyId };
          fetchData = getStandbyAdjustersNoPagination;
          break;
        case 'Admins':
          params = { ...params, isAdmin: true };
          fetchData = fetchAdjustersNoPagination;
          break;
        default:
          fetchData = fetchAdjustersNoPagination;
          break;
      }

      const { data = {} } = await fetchData(params);
      if (data.list) {
        const adjusterDataList = data.list
          .filter((el: any) => {
            if (['Admins', 'All'].includes(activeTab)) {
              return true;
            }

            return !el.isAdmin;
          })
          .map((el: any) => {
            el.avatar = el.avatar ? uploads.s3BucketAddress + el.avatar : el.avatar;
            return el;
          });

        handleSelectedTabs(_uniq([...selectedTabs, activeTab]));
        this.setState(
          {
            prevSelectedAdjusters: {
              ...prevSelectedAdjusters,
              [activeTab]: adjusterDataList.map((a: any) => a.id),
            },
          },
          () => {
            const unique = [...this.state.selectedAdjusters, ...adjusterDataList].filter((value, index, array) => {
              return array.findIndex((f) => `${f.id}-${f.signupId || 0}` === `${value.id}-${value.signupId || 0}`) === index;
            })

            setSelectedAdjusters(
              unique
            );
          }
        );
      }
    } else {
      handleSelectedTabs(selectedTabs.filter((selected: string) => selected !== activeTab));
      const filteredTabs = _omit(prevSelectedAdjusters, activeTab);
      this.setState({ prevSelectedAdjusters: filteredTabs });
      setSelectedAdjusters(
        selectedAdjusters.filter(a => !prevSelectedAdjusters[activeTab].find(e => {
          if (activeTab === 'QuickApplied') {
            return e === a.id && a.signupId
          }

          return e === a.id && !a.signupId
        }))
      );
    }
  };

  public getObjIds = arr =>
    arr.reduce((acc, i) => {
      acc[i.id] = true;
      return acc;
    }, {});

  public handleDeselect = (id: string) => {
    this.onRemoveLocalSelected(id);
    this.props.removeItem(id, 'adjusters');
  };

  public onAddLocalSelected = (id: number) => {
    const prevSelectedAdjusters = { ...this.state.prevSelectedAdjusters };

    if (!prevSelectedAdjusters[this.props.activeTab]) {
      prevSelectedAdjusters[this.props.activeTab] = [];
    }

    prevSelectedAdjusters[this.props.activeTab].push(id);
    this.setState({ prevSelectedAdjusters });
  };

  public onRemoveLocalSelected = (id: string) => {
    const prevSelected: any = this.state.prevSelectedAdjusters;

    const prevSelectedAdjusters: any = {};
    Object.keys(prevSelected).forEach((key: string) => {
      const newArray = prevSelected[key].filter((adj: any) => adj !== id);
      if (!newArray.length) {
        return;
      }
      prevSelectedAdjusters[key] = newArray;
    });
    this.setState({ prevSelectedAdjusters });
  };

  public isActive = () => {
    const { prevSelectedAdjusters = {} } = this.state;
    const { selectedTabs = [], activeTab, adjustersCount } = this.props;
    return (
      selectedTabs.includes(activeTab) &&
      (prevSelectedAdjusters[activeTab] || []).length === adjustersCount
    );
  };

  // TODO: refactor/improve this logic
  public isIncludesIds = () => {
    const {
      adjusters = [],
      selectedAdjusters,
      activeTab,
      selectedTabs,
      handleSelectedTabs,
      adjustersCount,
    } = this.props;
    const includesAdj: any = adjusters.filter(adj =>
      selectedAdjusters.find((a: any) => a.id === adj.id)
    );
    const adjustersPageSize = 15;
    if (adjustersCount <= adjustersPageSize && includesAdj.length === adjusters.length) {
      this.setState({
        prevSelectedAdjusters: {
          ...this.state.prevSelectedAdjusters,
          [activeTab]: includesAdj.map((a: any) => a.id),
        },
      });
      handleSelectedTabs(_uniq([...selectedTabs, activeTab]));
      return true;
    } else {
      return false;
    }
  };

  public renderSelectAllCheckbox = () => {
    // TODO: refactor and improve this select all logic
    const prevSelectedAdjusters: number[] =
      this.state.prevSelectedAdjusters[this.props.activeTab] || [];
    const isAllSelected =
      prevSelectedAdjusters.length !== 0 &&
      (prevSelectedAdjusters.length === this.props.adjustersCount ||
        this.props.adjustersCount === this.props.selectedAdjusters.length ||
        this.props.adjustersCount < this.props.selectedAdjusters.length);

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {!this.props.selectedTab && (
          <span style={{ marginRight: 5 }}>{`Total:  ${this.props.adjustersCount} `}</span>
        )}
        {!this.props.selectedTab && (
          <Popup
            trigger={
              <Button
                type="button"
                className={
                  isAllSelected
                    ? 'check-button active iconic-button-custom'
                    : 'check-button iconic-button-custom'
                }
                onClick={this.selectAll}
              >
                <Image src={isAllSelected ? checkIconW : checkIconB} />
              </Button>
            }
            basic={true}
            size="mini"
            content="Select All"
          />
        )}
      </div>
    );
  };

  public render() {
    const { adjusters, selectedAdjusters } = this.state;

    return (
      <Table
        compact={true}
        striped={true}
        stackable={true}
        singleLine={true}
        className="data-table adjusters-table"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="table-avatar" />
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>City</Table.HeaderCell>
            <Table.HeaderCell>State</Table.HeaderCell>
            <Table.HeaderCell>Mobile</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Compass Email</Table.HeaderCell>
            <Table.HeaderCell>Last Updated On</Table.HeaderCell>
            <Table.HeaderCell style={{ width: 100 }}>
              {this.renderSelectAllCheckbox()}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {adjusters &&
            adjusters.map((adjuster: any, i: number) => (
              <Table.Row key={adjuster.id}>
                <Table.Cell className="table-avatar">
                  <Image
                    circular={true}
                    src={adjuster.avatar ? adjuster.avatar : noAvatarIcon}
                    avatar={true}
                  />
                </Table.Cell>
                <Table.Cell style={{ maxWidth: '50px' }}>
                  {helper.truncateString(adjuster.lastName, 20)}
                </Table.Cell>
                <Table.Cell style={{ maxWidth: '50px' }}>
                  {adjuster.preferredName ? adjuster.preferredName : adjuster.firstName}
                </Table.Cell>
                <Table.Cell style={{ maxWidth: '100px' }}>
                  {helper.truncateString(adjuster.city, 20)}
                </Table.Cell>
                <Table.Cell style={{ maxWidth: '50px' }}>
                  {helper.truncateString(adjuster.state, 20)}
                </Table.Cell>
                <Table.Cell style={{ maxWidth: '100px' }}>
                  {helper.formatPhone(adjuster.mobilePhone, '(###) ###-####')}
                </Table.Cell>
                <Table.Cell style={{ maxWidth: '200px' }}>{adjuster.email}</Table.Cell>
                <Table.Cell style={{ maxWidth: '200px' }}>{adjuster.compassEmail}</Table.Cell>
                <Table.Cell className="table-date">
                  {moment(adjuster.updatedAt).format('MM/DD/YYYY, hh:mm A')}
                </Table.Cell>
                <Table.Cell className="table-action">
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {!this.props.selectedTab && (
                      <Popup
                        trigger={
                          <Button
                            type="button"
                            className={
                              selectedAdjusters.findIndex(
                                el => el.id === adjuster.id && el.email === adjuster.email
                              ) !== -1
                                ? 'check-button active iconic-button-custom'
                                : 'check-button iconic-button-custom'
                            }
                            onClick={() => this.handleSelection(adjuster)}
                          >
                            <Image
                              src={
                                selectedAdjusters.findIndex(
                                  el => el.id === adjuster.id && el.email === adjuster.email
                                ) !== -1
                                  ? checkIconW
                                  : checkIconB
                              }
                            />
                          </Button>
                        }
                        basic={true}
                        size="mini"
                        content={
                          selectedAdjusters.findIndex(
                            el => el.id === adjuster.id && el.email === adjuster.email
                          ) !== -1
                            ? 'Deselect'
                            : 'Select'
                        }
                      />
                    )}
                    {this.props.selectedTab && (
                      <CustomIconicButton
                        image={cancelIconB}
                        popup="Remove"
                        onPress={() => this.handleDeselect(adjuster.id)}
                      />
                    )}
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  selectedAdjusters: state.standby.inviteModal.selectedAdjusters,
  adjustersCount: state.standby.inviteModal.totalAdjusters,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setSelectedAdjusters: standbyActions.setSelectedAdjusters,
      getSelectedAdjusters: standbyActions.getSelectedAdjusters,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SendMessageTableAdj);
