// @ts-nocheck
import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { Button } from 'semantic-ui-react';
import ErrorLabel from '../error-label/ErrorLabel';
import PhoneInput from '../layout-input/PhoneInput';

interface Props {
  getReference: (item: any) => void;
}

interface State {
  showCreate: boolean;
}

class AddEducation extends React.Component<Props, State> {
  public state = {
    showCreate: false,
  };

  public render() {
    const errorLabel = <ErrorLabel />;
    return (
      <React.Fragment>
        {this.state.showCreate && (
          <div className="create-education-container">
            <Form
              ref={ref => (this.form = ref)}
              id="create-reference-form"
              onValidSubmit={model => {
                this.props.getReference(model);
                this.setState({ showCreate: false });
              }}
            >
              <div className="input-field-container">
                <div className="input-field-label">Reference Name</div>
                <Form.Input
                  name="name"
                  placeholder="Please Select"
                  required={true}
                  validationErrors={{
                    isDefaultRequiredValue: `* Reference Name is required field.`,
                  }}
                  className="form-input"
                  errorLabel={errorLabel}
                />
              </div>

              <div className="input-field-container">
                <div className="input-field-label">Reference Phone</div>
                <PhoneInput
                  name="phone"
                  placeholder="Please Enter..."
                  instantValidation={false}
                  required={true}
                  validationErrors={{
                    isDefaultRequiredValue: `* Phone is required field.`,
                  }}
                  errorLabel={errorLabel}
                />
              </div>
              <div className="input-field-container">
                <div className="input-field-label">Reference Relationship</div>
                <Form.Input
                  name="relation"
                  placeholder="Please Select"
                  required={true}
                  validationErrors={{
                    isDefaultRequiredValue: `* Reference Relationship is required field.`,
                  }}
                  className="form-input"
                  errorLabel={errorLabel}
                />
              </div>
              <div className="education-button">
                <Button type="button" onClick={() => this.setState({ showCreate: false })}>
                  CANCEL
                </Button>
                <Button
                  type="button"
                  onClick={e => {
                    this.form.formsyForm.submit();
                  }}
                  form="create-education-form"
                >
                  SAVE
                </Button>
              </div>
            </Form>
          </div>
        )}
        {!this.state.showCreate && (
          <div className="add-question">
            <Button type="button" onClick={() => this.setState({ showCreate: true })}>
              + ADD
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default AddEducation;
