// @ts-nocheck
import { Button, Image, Modal } from 'semantic-ui-react';
import closeIcon from '../../../assets/images/Blue/cancel.svg';
import React from 'react';
import { MaterialModel } from '../../../features/training-center/trainingCenterReducer';
import DocumentDetails from './DocumentDetails';
import EmbeddedVideoDetails from './EmbeddedVideoDetails';
import './MaterialDetails.css';

interface Props {
  material: MaterialModel;
  onClose: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onDownload: () => void;
}

interface State {}

export default class MaterialDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public renderMaterial = () => {
    const { material } = this.props;
    if (material.type === 'document') {
      return (
        <DocumentDetails
          name={material.name}
          description={material.description}
          lastUpdatedOn={material.updatedAt as string}
          folders={material.materialFolders.map(f => f.folder.name)}
          fileType={material.type}
          fileKey={material.file.key}
          mimetype={material.mimetype}
        />
      );
    }

    return (
      <EmbeddedVideoDetails
        url={material.videoUrl}
        description={material.description}
        name={material.name}
        lastUpdatedOn={material.updatedAt as string}
        folders={material.materialFolders.map(f => f.folder.name)}
      />
    );
  };

  public render() {
    const { material } = this.props;
    return (
      <Modal
        open
        className="material-details-modal"
        size="small"
        closeIcon={<Image src={closeIcon} className="icon" onClick={this.props.onClose} />}
      >
        <Modal.Header>File details</Modal.Header>
        <Modal.Content>{this.renderMaterial()}</Modal.Content>
        <div className="modal-button-footer">
          <Button
            type="button"
            style={{ width: '115px' }}
            className="secondary-button"
            secondary={true}
            onClick={this.props.onDelete}
          >
            Delete
          </Button>
          <div className="modal-buttons-right">
            <Button
              type="button"
              style={{ width: '115px' }}
              className="secondary-button"
              secondary={true}
              onClick={this.props.onEdit}
            >
              Edit
            </Button>
            {material.type === 'document' ? (
              <Button primary={true} onClick={this.props.onDownload}>
                Download
              </Button>
            ) : (
              <Button
                primary={true}
                onClick={() => window.open(material.videoUrl, '_blank').focus()}
              >
                Go to Video URL
              </Button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
