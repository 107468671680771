// @ts-nocheck
import React from 'react';

interface Props {
  adjuster: object;
}

interface State {}

class UserStatusLabel extends React.Component<Props, State> {
  public render() {
    const { adjuster } = this.props;
    let background;
    let status;

    if (adjuster.isGuest) {
      background = '#f5a9b4';
      status = 'NOT REGISTERED';
    } else {
      if (adjuster.status === 'Inactive') {
        background = '#e10000';
      } else {
        background = '#1cb800';
      }

      status = adjuster.status || 'Inactive';
    }

    return (
      <div
        className="user-status-label"
        style={{ background }}
      >
        {status}
      </div>
    );
  }
}

export default UserStatusLabel;
