// @ts-nocheck
import { Form } from 'formsy-semantic-ui-react';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Grid, Image, Modal } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import '../../assets/styles/Staff.css';
import { adminActions } from '../../features/admin';
import ErrorLabel from '../error-label/ErrorLabel';
import { LoadingSpiner } from '../loading-spinner/LoadingSpinner';
import { trainingCenterActions } from '../../features/training-center';
import { EditFolderModel, Folder } from '../../features/training-center/trainingCenterReducer';

interface Props {
  toggleEditFolderModel: typeof trainingCenterActions.toggleEditFolderModel;
  editFolder: typeof trainingCenterActions.editFolder;
  showModal: boolean;
  formData: any;
  folderList: Folder[],
}

interface State {
}


class EditFolderModal extends React.Component<Props, State> {
  public form: any;

  public state = {};

  public renderEditForm(errorLabel: any) {
    const { formData } = this.props;
    return <Form.Input
      name="name"
      maxLength={60}
      placeholder={ formData.parentId ? "Please Enter Subfolder Name" :"Please Enter Folder Name" }
      instantValidation={false}
      required={true}
      validations={{
        isOnlySpaces: true,
      }}
      validationErrors={{
        isDefaultRequiredValue: formData.parentId ? '* Subfolder Name is required field.' : '* Folder Name is required field.',
        isOnlySpaces: formData.parentId ? '* Subfolder Name is required field.': '* Folder Name is required field.',
      }}
      className="form-input"
      errorLabel={errorLabel}
      value={formData.name}
    />;
  }

  public render() {
    const errorLabel = <ErrorLabel />;
    const { showModal } = this.props;

    return (
      <Modal
        className="create-category-modal"
        open={showModal}
        closeIcon={
          <Image
            src={closeIcon}
            className="icon"
            onClick={() => this.props.toggleEditFolderModel()}
          />
        }
      >
        <Modal.Header>Edit</Modal.Header>
        <Form
          onValidSubmit={async (model: EditFolderModel) => {
            model.name = model.name.trim();
            const { formData } = this.props;
            const obj = Object.assign({}, formData, model);
            this.props.editFolder(obj)
          }}
          ref={(ref: any) => (this.form = ref)}
        >
          <Modal.Content>
            <LoadingSpiner area="create-admin-modal-area" />
            <Grid>
              <Grid.Column style={{ padding: "35px" }}>
                {this.renderEditForm(errorLabel)}
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <div className="modal-button-footer">
            <Button
              type="button"
              style={{ width: '115px' }}
              className="secondary-button"
              secondary={true}
              onClick={() => this.props.toggleEditFolderModel()}
            >
              CANCEL
            </Button>
            <Button style={{ width: '115px' }} primary={true}>
              SAVE
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  showModal: state.trainingCenter.editFolder.showModal,
  formData: state.trainingCenter.editFolder.formData,
  folderList: state.trainingCenter.folderList,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAdminList: adminActions.getAdminList,
      resetAdminList: adminActions.resetAdminList,
      toggleEditFolderModel: trainingCenterActions.toggleEditFolderModel,
      editFolder: trainingCenterActions.editFolder,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditFolderModal);
