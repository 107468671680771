// @ts-nocheck
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Dropdown, Icon, Image, Menu, MenuItemProps, Search } from 'semantic-ui-react';
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg';
import { messagesActions } from '../../features/messages';
import { standbyActions } from '../../features/standby';
import services from '../../services';
import StandbyInviteModal from '../standby-layout/modals/StandbyInviteModal';

interface Props {
  setMessagesTab: typeof messagesActions.setMessagesTab;
  getMessagesList: typeof messagesActions.getMessagesList;
  setMessagesSearch: typeof messagesActions.setMessagesSearch;
  setMessagesSort: typeof messagesActions.setMessagesSort;
  showInviteModal: typeof standbyActions.showInviteModal;
  setInviteMode: typeof standbyActions.setInviteMode;
  setActivePage: typeof messagesActions.setActivePage;
  inviteModalMode: string;
  inviteModal: boolean;
  activeTab: string;
  sort: string;
  search: string;
  user: any;
}

interface State {}

const sortOptions = [
  {
    key: 'Unread Messages',
    text: 'Unread Messages',
    value: 'unread,DESC',
  },
  {
    key: 'Creation Date',
    text: 'Creation Date',
    value: 'createdAt,DESC',
  },
  {
    key: 'Title Asc',
    text: 'Title',
    value: 'title,ASC',
  },
];

class MessagesListHeader extends React.Component<Props, State> {
  public componentDidMount = () => {
    this.props.getMessagesList();
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.activeTab !== this.props.activeTab) {
      this.props.getMessagesList();
    }
    if (prevProps.sort !== this.props.sort) {
      this.props.getMessagesList();
    }
    if (prevProps.search !== this.props.search) {
      this.props.getMessagesList();
    }
    if (prevProps.inviteModal !== this.props.inviteModal) {
      this.props.getMessagesList();
    }
  };

  public handleItemClick = (e: React.MouseEvent<HTMLAnchorElement>, { name }: MenuItemProps) => {
    this.props.setMessagesTab(name);
    this.props.setActivePage(1);
  };

  public handleSort = (e: any, data: any) => {
    this.props.setMessagesSort(data.value);
    this.props.setActivePage(1);
  };

  public handleSearch = (e: any, data: any) => {
    this.props.setMessagesSearch(data.value);
    this.props.setActivePage(1);
  };

  public updateData = async () => {
    this.props.setActivePage(1);
  };

  public render() {
    const { activeTab, user } = this.props;

    return (
      <>
        <div className="adjusters-list-header sbl-details-table-header msg-list-table-header">
          <Menu text={true} className="tabs-menu">
            <Menu.Item name="All" active={activeTab === 'All'} onClick={this.handleItemClick}>
              All
            </Menu.Item>
            <Menu.Item name="Groups" active={activeTab === 'Groups'} onClick={this.handleItemClick}>
              Groups
            </Menu.Item>
            <Menu.Item
              name="Personal"
              active={activeTab === 'Personal'}
              onClick={this.handleItemClick}
            >
              Personal
            </Menu.Item>
          </Menu>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span className="sort-field">
              Sort by:{' '}
              <Dropdown
                inline={true}
                options={sortOptions}
                value={this.props.sort}
                onChange={this.handleSort}
              />
            </span>
            <Search
              input={{ iconPosition: 'left' }}
              icon={
                <Icon>
                  <Image src={searchIcon} />
                </Icon>
              }
              placeholder="Search..."
              onSearchChange={this.handleSearch}
              showNoResults={false}
              value={this.props.search}
            />
            {user.role !== 'Read Only Office Admin' && (
              <Button
                className="add-admin-button"
                onClick={() => {
                  this.props.showInviteModal(true, 'edit');
                }}
              >
                + Create
              </Button>
            )}
          </div>
        </div>
        <StandbyInviteModal standby={false} global={true} updateData={this.updateData} />
      </>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  activeTab: state.messages.messages.activeTab,
  sort: state.messages.messages.messagesSort,
  search: state.messages.messages.messagesSearch,
  inviteModalMode: state.standby.standby.inviteModalMode,
  user: state.account.account.user,
  inviteModal: state.standby.standby.showInviteModal,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setMessagesTab: messagesActions.setMessagesTab,
      setMessagesSearch: messagesActions.setMessagesSearch,
      setMessagesSort: messagesActions.setMessagesSort,
      getMessagesList: messagesActions.getMessagesList,
      showInviteModal: standbyActions.showInviteModal,
      setInviteMode: standbyActions.setInviteMode,
      setActivePage: messagesActions.setActivePage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesListHeader);
