// @ts-nocheck
import L from 'leaflet';
import 'leaflet-routing-machine';
import { MapLayer } from 'react-leaflet';
import { withLeaflet } from 'react-leaflet';

class Routing extends MapLayer {
  public createLeafletElement() {
    const { map } = this.props;

    const leafletElement = L.Routing.control({
      waypoints: [],
      createMarker: () => null,
      addWaypoints: false,
      draggableWaypoints: false,
      fitSelectedRoutes: false,
      showAlternatives: true,
    }).addTo(map.leafletElement);
    L.DomEvent.on(map.leafletElement, 'click', this.clickRoute.bind(this));
    return leafletElement.getPlan();
  }

  public componentWillUnmount() {
    L.DomEvent.off(this.props.map.leafletElement, 'click', this.clickRoute.bind(this));
  }

  public clickRoute(e) {
    const { showPopup } = this.props;
    if (e.originalEvent.srcElement.classList.value.includes('leaflet-interactive')) {
      showPopup();
    }
  }

  public componentWillReceiveProps(newProps) {
    const { from, to, control } = newProps;

    if (!from.length) {
      control.setWaypoints([]);
    } else {
      control.setWaypoints([L.latLng(from[0], from[1]), L.latLng(to[0], to[1])]);
    }
  }
}

export default withLeaflet(Routing);
