// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Icon, Image, Input, Popup } from 'semantic-ui-react';
import icon from '../../../assets/images/Common/icn-search-grey.svg';
import icnExcluded from '../../../assets/images/icn-excluded.svg';
import icnIncluded from '../../../assets/images/icn-included.svg';
import iconArrow from '../../../assets/images/ui-dropdown.svg';
import Content from './Content';
import './styles.css';

const Trigger = ({ placeholder = '', setOpen, excludeIcn, name, dataSource, disabled = false }) => (
  <Input
    className="inputTrigger"
    onClick={setOpen}
    iconPosition="left"
    placeholder={placeholder}
    id="inputMultiply"
    name={name}
    disabled={disabled}
  >
    <Icon className="iconSearch">
      <Image src={icon} />
    </Icon>
    <input value={dataSource.text || ''} className="inputTr" autoComplete="off" />
    <div className="iconArrow" onClick={setOpen}>
      <img src={iconArrow} />
    </div>
    {excludeIcn !== undefined && (
      <img src={excludeIcn ? icnExcluded : icnIncluded} className="iconExclude" />
    )}
  </Input>
);

const SearchSelectInput = props => {
  const elem = document.getElementById('inputMultiply');
  const { name, value = {}, open, setOpen, setFieldBool, filter, filterLogs } = props;

  const [dataSource, $dataSource] = useState(value);
  const [active, $active] = useState('include');
  const [width, $width] = useState('210px');

  useEffect(() => {
    $dataSource(value);
    $active(value.text ? (value.value ? 'include' : 'exclude') : 'include');
  }, [value]);

  const onChange = item => {
    if (dataSource.text) {
      $dataSource({});
      setFieldBool(name, {});
    } else {
      const obj = { ...item, value: active === 'include' ? item.value : !item.value };
      $dataSource(obj);
      setFieldBool(name, obj);
    }
  };

  const excludeIcn = dataSource.text ? (active === 'exclude' ? true : false) : undefined;

  const setWidth = () => $width(elem ? elem.offsetWidth : '210px');

  useEffect(() => {
    window.addEventListener('resize', setWidth);
    return () => window.removeEventListener('resize', setWidth);
  });

  useEffect(() => {
    if (elem) {
      $width(elem.offsetWidth);
    }
  }, [elem]);

  const onApply = () => {
    if (dataSource.text) {
      const dataModel = { ...Object.assign({}, filter), [name]: dataSource.value };
      filterLogs(dataModel);
    }
  };

  const attr = {
    setOpen,
    excludeIcn,
    value,
    active,
    $active,
    setFieldBool,
    onApply,
    dataSource,
    $dataSource,
    onChange,
    ...props,
  };

  return (
    <Popup
      content={<Content {...attr} />}
      on="click"
      position="bottom left"
      open={name === open}
      style={{ width, maxWidth: width, minWidth: width }}
      className="popupmultiply"
      basic={true}
      trigger={<Trigger {...attr} />}
    />
  );
};

export default SearchSelectInput;
