// @ts-nocheck
import { trackPromise } from 'react-promise-tracker';
import { PaginationInfo } from '../../features/training-center/trainingCenterReducer';
import { request } from './base';
import { aws } from '../api-service';
import { getSignedUrl } from './account';
import { markFilesAsUploaded } from './file';

export interface MaterialFilter {
  activePage: number;
  pageSize?: number;
  search?: string;
  fileType?: string;
  sortByField?: string;
  sortType?: 'ASC' | 'DESC';
  noPagination?: boolean;
  folderIds?: string;
}

export const searchMaterial = (paginationInfo: PaginationInfo, data: MaterialFilter) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/material?page=${paginationInfo.activePage - 1}&pageSize=${paginationInfo.pageSize}`,
      params: {
        ...data,
      },
    }),
    'material'
  );
};

export const createMaterial = (model: any) => {
  return request({
    method: 'post',
    url: '/material',
    data: {
      ...model,
    },
  });
};

export const editMaterial = (model: any) => {
  return request({
    method: 'patch',
    url: `/material/${model.id}`,
    data: {
      ...model,
    },
  });
};

export const deleteMaterial = (ids: any) => {
  return request({
    method: 'delete',
    url: `/material`,
    data: {
      materials: ids,
    },
  });
};

export const getById = (id: any) => {
  return request({
    method: 'get',
    url: `/material/${id}`,
  });
};

export const createEmbeddedVideoMaterial = (model: {
  url: string;
  description: string;
  folderIds: string[];
  name: string;
}) => {
  return request({
    method: 'post',
    url: '/material/embedded-video',
    data: {
      url: model.url,
      name: model.name,
      description: model.description,
      folderIds: model.folderIds,
    },
  });
};

export const updateEmbeddedVideoMaterial = (
  id: number,
  model: {
    url: string;
    description: string;
    folderIds: string[];
    name: string;
  }
) => {
  return request({
    method: 'put',
    url: '/material/embedded-video/' + id,
    data: {
      url: model.url,
      description: model.description,
      folderIds: model.folderIds,
      name: model.name
    },
  });
};

export const uploadDocumentMaterial = async (model: {
  name: string;
  description: string;
  folderIds: string[];
  file: File;
}) => {
  // 1. Get signed upload URL for file
  const uploadResponse = await getSignedUrl({
    name: model.file.name,
    type: model.file.type,
    size: model.file.size,
    public: false,
  });

  console.info({
    name: model.file.name,
    type: model.file.type,
    size: model.file.size,
    public: false,
  })

  if (!uploadResponse.isSuccess) {
    return uploadResponse;
  }

  // 2. Upload the file to S3
  const fileKey = uploadResponse.data.url.fields.key;
  const awsResponse = await aws.uploadFile(
    {
      url: uploadResponse.data.url.url,
      fields: uploadResponse.data.url.fields,
    },
    { name: '', size: 0, type: '', originalFile: model.file }
  );

  if (!awsResponse.isSuccess) {
    return awsResponse;
  }

  // 3. Confirm file upload to BE
  const confirmUploadResponse = await markFilesAsUploaded([fileKey]);
  if (!confirmUploadResponse.isSuccess) {
    return confirmUploadResponse;
  }

  // 4. Create material
  return await createDocumentMaterial({
    name: model.name,
    description: model.description,
    fileId: fileKey,
    folderIds: model.folderIds,
  });
};

export const uploadEditedDocumentMaterial = async (
  id: number,
  model: {
    name: string;
    description: string;
    folderIds: string[];
    file: File;
  }
) => {
  let fileKey = '';
  if (model.file) {
    // 1. Get signed upload URL for file
    const uploadResponse = await getSignedUrl({
      name: model.file.name,
      type: model.file.type,
      size: model.file.size,
      public: false,
    });

    if (!uploadResponse.isSuccess) {
      return uploadResponse;
    }

    // 2. Upload the file to S3
    fileKey = uploadResponse.data.url.fields.key;
    const awsResponse = await aws.uploadFile(
      {
        url: uploadResponse.data.url.url,
        fields: uploadResponse.data.url.fields,
      },
      { name: '', size: 0, type: '', originalFile: model.file }
    );

    if (!awsResponse.isSuccess) {
      return awsResponse;
    }

    // 3. Confirm file upload to BE
    const confirmUploadResponse = await markFilesAsUploaded([fileKey]);
    if (!confirmUploadResponse.isSuccess) {
      return confirmUploadResponse;
    }
  }

  // 4. Create material
  const data = {
    name: model.name,
    description: model.description,
    fileId: fileKey,
    folderIds: model.folderIds,
  };

  if (fileKey) {
    data['fileKey'] = fileKey;
  }

  return await updateDocumentMaterial(id, data);
};

export const createDocumentMaterial = (model: {
  name: string;
  description: string;
  folderIds: string[];
  fileId: string;
}) => {
  return request({
    method: 'post',
    url: '/material',
    data: {
      name: model.name,
      description: model.description,
      folderIds: model.folderIds,
      fileId: model.fileId,
    },
  });
};

export const updateDocumentMaterial = (
  id: number,
  model: {
    name: string;
    description: string;
    folderIds: string[];
    fileId: string;
  }
) => {
  return request({
    method: 'put',
    url: '/material/' + id,
    data: {
      name: model.name,
      description: model.description,
      folderIds: model.folderIds,
      fileId: model.fileId,
    },
  });
};

export const searchAccess = (paginationInfo: PaginationInfo, data: MaterialFilter) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/adjuster/folder/count?page=${paginationInfo.activePage - 1}&pageSize=${paginationInfo.pageSize
        }`,
      params: {
        ...data,
      },
    }),
    'access'
  );
};
