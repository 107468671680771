// @ts-nocheck
import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import * as constants from './constants';
import * as helper from '../../utils/helper';
import _ from 'lodash';

export interface State {
  folder: Folder;
  folderList: Folder[];
  parentFolder: Folder[];
  createFolder: { showModal: boolean; formData: CreateFolderModel };
  deleteFolder: { showModal: boolean; folder: Folder };
  assignAccess: AssignAccessModel;
  material: TrainingCenterMaterial;
  uploadMaterial: UploadMaterialModal;
  editMaterial: EditMaterialModal;
}

export interface Folder {
  id: number;
  name: string;
  order: number | undefined;
  parentId: number | undefined;
  parent: Folder | undefined;
  open?: boolean;
  active?: boolean;
  childActive?: boolean;
  parentActive?: boolean;
  parentOpen?: boolean;
  children: Folder[];
  enter?: boolean;
  childEnter?: boolean;
  parentEnter?: boolean;
  isAbleToDelete?: boolean;
}

export type TrainingCenterAssignAccessTab = 'Adjusters' | 'CustomEvents';
export interface AssignAccessModel {
  folderId?: number;
  showModal: boolean;
  selectedAdjusters: Array<number>;
  selectedCustomEvents: Array<number>;
  loading: boolean;
  activeTab: TrainingCenterAssignAccessTab;
  pagingInfo: { Adjusters: PaginationInfo; CustomEvents: PaginationInfo };
}

export interface UploadMaterialModal {
  showModal: boolean;
  isUploading: boolean;
}

export interface EditMaterialModal {
  showModal: boolean;
  material: any;
  isUploading: boolean;
}

export interface PaginationInfo {
  activePage: number;
  pageSize: number;
  search: string;
  totalPages: number;
  totalRecords: number;
  records: Array<any>;
  allRecords: Array<any>;
  selected: Array<any>;
  filter?: any;
  resetSelect: boolean;
}

export interface TrainingCenterMaterial {
  selectedFolderId?: number;
  viewType: 'list' | 'grid';
  pagingInfo: PaginationInfo;
  showFilter: boolean;
}

export interface TrainingCenterAccess {
  selectedAccessId?: number;
  pagingInfo: PaginationInfo;
}

export interface MaterialModel {
  id: number;
  name: string;
  thumbnailUrl: string;
  videoUrl: string;
  description: string;
  updatedAt: number;
  file: { id: number; key: string };
  fileName: string;
  type: string;
  mimetype: string;
  admin: {
    id: number;
    avatar?: string;
    firstName: string;
    lastName: string;
    preferredName: string;
  };
  materialFolders: {
    folder: { id: number; name: string };
  }[];
}

export interface AccessModel {
  id: number;
  lastName: string;
  firstName: string;
  categoryCount: number;
  updatedAt: string;
  avatar: string;
}

export const createFolderState: { showModal: boolean; formData: CreateFolderModel } = {
  showModal: false,
  formData: { name: '', parentId: undefined },
};
export const editFolderState: { showModal: boolean; formData: EditFolderModel } = {
  showModal: false,
  formData: { name: '', id: undefined },
};
export const deleteFolderState: { showModal: boolean; folder: Folder } = {
  showModal: false,
  folder: { id: undefined, name: '' },
};
export interface CustomEvent {
  id: number;
  name: string;
}

export function assignAccessInitFolderState(): AssignAccessModel {
  return {
    folderId: undefined,
    showModal: false,
    selectedAdjusters: [],
    selectedCustomEvents: [],
    loading: false,
    activeTab: 'Adjusters',
    pagingInfo: { Adjusters: initPagingInfo(), CustomEvents: initPagingInfo() },
  };
}

export function initPagingInfo(): PaginationInfo {
  return {
    pageSize: 20,
    search: '',
    totalPages: 0,
    totalRecords: 0,
    activePage: 1,
    records: [],
    allRecords: [],
    selected: [],
    filter: {},
    resetSelect: false,
  };
}

export const assignAccessFolderState = assignAccessInitFolderState();

export function initMaterialState(): TrainingCenterMaterial {
  return {
    selectedFolderId: undefined,
    viewType: 'grid',
    pagingInfo: initPagingInfo(),
    showFilter: false,
  };
}

export function initAccessState(): TrainingCenterAccess {
  return {
    selectedAccessId: undefined,
    pagingInfo: initPagingInfo(),
  };
}

export function initCustomEventsState(): TrainingCenterAccess {
  return {
    pagingInfo: initPagingInfo(),
  };
}

export const materialState = initMaterialState();

export const accessState = initAccessState();

export const customEventState = initCustomEventsState();

export const createFolderListState: Folder[] = [];

export interface CreateFolderModel {
  name: string;
  parentId?: number;
}
export interface EditFolderModel {
  id?: number;
  name?: string;
}

export interface CreateCustomEvent {
  name: string;
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
  folderList: (state = createFolderListState, action) => {
    switch (action.type) {
      case constants.GET_FILES_LIST:
        return [];
      case constants.SET_FOLDER_LIST:
        return action.payload.list as Folder[];
      case constants.SET_FOLDER_TOGGLE:
        return state
          .map(t => {
            if (t.id === action.payload.id) {
              return { ...t, open: !t.open };
            }
            return t;
          })
          .concat();
      case constants.SET_FOLDER_ACTIVE:
        const activeFolder = action.payload.folder as Folder;
        return state
          .map(t => {
            t.active = t.id === activeFolder.id;
            t.childActive = t.id === activeFolder.parentId;
            t.children = t.children.map(child => {
              child.active = activeFolder.id === child.id;
              child.parentActive = activeFolder.id === child.parentId;
              child.parentOpen = activeFolder.parentId === child.parentId;
              return child;
            });
            return t;
          })
          .concat();
      case constants.REMOVE_FOLDER_ACTIVE:
        return state.map(t => {
          t.active = false;
          t.childActive = false;
          t.open = false;
          t.children = t.children.map(child => {
            child.active = false;
            child.parentActive = false;
            child.parentOpen = false;
            return child;
          });

          return t;
        });
      case constants.SET_FOLDER_ENTER:
        const enterFolder = action.payload.folder as Folder;
        return state
          .map(t => {
            t.enter = t.id === enterFolder.id;
            t.childEnter = t.id === enterFolder.parentId;
            t.children = t.children.map(child => {
              child.enter = enterFolder.id === child.id;
              child.parentEnter = enterFolder.id === child.parentId;
              return child;
            });
            return t;
          })
          .concat();
      case constants.SET_FOLDER_LEAVE:
        const leaveFolder = action.payload.folder as Folder;
        return state
          .map(t => {
            t.enter = t.id === leaveFolder.id ? false : t.enter;
            t.childEnter = t.id === leaveFolder.parentId ? false : t.childEnter;
            t.children = t.children.map(child => {
              child.enter = leaveFolder.id === child.id ? false : child.enter;
              child.parentEnter = leaveFolder.id === child.parentId ? false : child.parentEnter;
              return child;
            });
            return t;
          })
          .concat();

      default:
        return state;
    }
  },
  createFolder: (state = createFolderState, action) => {
    switch (action.type) {
      case constants.TOGGLE_CREATE_FOLDER_MODAL:
        return {
          showModal: !state.showModal,
          formData: { name: '', parentId: action.payload.parentId },
        };
      case constants.CREATE_FOLDER:
        return { showModal: state.showModal, formData: action.payload.data };
      default:
        return state;
    }
  },
  editFolder: (state = editFolderState, action: any) => {
    switch (action.type) {
      case constants.TOGGLE_EDIT_FOLDER_MODAL:
        return {
          showModal: !state.showModal,
          formData: action.payload.data || { name: '', id: undefined },
        };
      case constants.EDIT_FOLDER:
        return {
          showModal: state.showModal,
          formData: action.payload.data || { name: '', id: undefined },
        };
      default:
        return state;
    }
  },
  deleteFolder: (state = deleteFolderState, action: Action) => {
    switch (action.type) {
      case constants.TOGGLE_DELETE_FOLDER_MODAL:
        return {
          showModal: !state.showModal,
          folder: action.payload.data || { name: '', id: undefined },
        };
      case constants.DELETE_FOLDER:
        return {
          showModal: state.showModal,
          folder: action.payload.data || { name: '', id: undefined },
        };
      default:
        return state;
    }
  },
  assignAccess: (state = assignAccessFolderState, action: any) => {
    switch (action.type) {
      case constants.SHOW_ASSIGN_ACCESS_MODAL:
        return {
          ...assignAccessInitFolderState(),
          showModal: true,
          loading: true,
          folderId: action.payload.folderId,
        };
      case constants.HIDE_ASSIGN_ACCESS_MODAL:
        return { ...assignAccessInitFolderState(), showModal: false };
      case constants.ASSIGN_ACCESS_SET_ACTIVE_TAB:
        return { ...state, activeTab: action.payload.activeTab };
      case constants.SAVE_ASSIGN_ACCESS:
        return { ...state, loading: true };
      case constants.SET_ASSIGNED_ADJUSTERS:
        state.selectedAdjusters.push(...action.payload.data);
        const setAdjusters = new Set(state.selectedAdjusters);
        return { ...state, selectedAdjusters: Array.from(setAdjusters) };
      case constants.REMOVE_ASSIGNED_ADJUSTERS:
        const removedAdjusters = action.payload.data as Array<number>;
        const adjusters = state.selectedAdjusters
          .filter(t => {
            const currentAdjuster = removedAdjusters.find(removed => removed === t);
            if (currentAdjuster) {
              return false;
            }
            return true;
          })
          .filter(t => t);
        return { ...state, selectedAdjusters: adjusters };
      case constants.SET_ASSIGNED_CUSTOM_EVENTS:
        state.selectedCustomEvents.push(...action.payload.data);
        const setCustomEvents = new Set(state.selectedCustomEvents);
        return { ...state, selectedCustomEvents: Array.from(setCustomEvents) };
      case constants.REMOVE_ASSIGNED_CUSTOM_EVENTS:
        const removedCustomEvents = action.payload.data as Array<number>;
        const customEvents = state.selectedCustomEvents
          .filter(t => {
            const currentAdjuster = removedCustomEvents.find(removed => removed === t);
            if (currentAdjuster) {
              return false;
            }
            return true;
          })
          .filter(t => t);
        return { ...state, selectedCustomEvents: customEvents };
      case constants.ASSIGN_ACCESS_SEARCH:
        const activeTab = state.activeTab;
        const { page, pageSize, keyword } = action.payload;
        const pagingInfo = state.pagingInfo;
        pagingInfo[activeTab] = {
          ...pagingInfo[activeTab],
          pageSize: pageSize,
          activePage: page,
          search: keyword,
        };
        return { ...state, pagingInfo: { ...pagingInfo } };
      case constants.ASSIGN_ACCESS_SET_SEARCH_RESULT:
        const searchResultPagingInfo = state.pagingInfo;
        searchResultPagingInfo[state.activeTab] = {
          ...searchResultPagingInfo[state.activeTab],
          totalRecords: action.payload.total,
          records: action.payload.data,
          totalPages: helper.getTotalPage(
            action.payload.total,
            searchResultPagingInfo[state.activeTab].pageSize
          ),
        };
        return { ...state, pagingInfo: { ...searchResultPagingInfo } };
      case constants.ASSIGN_ACCESS_SET_ALL_SEARCH_RESULT:
        state.pagingInfo[state.activeTab].allRecords = action.payload.data;
        return { ...state, pagingInfo: { ...state.pagingInfo } };
      default:
        return state;
    }
  },
  material: (state = materialState, action: any) => {
    switch (action.type) {
      case constants.SET_VIEW_TYPE_LIST:
        return { ...state, viewType: 'list' };
      case constants.SET_VIEW_TYPE_GRID:
        return { ...state, viewType: 'grid' };
      case constants.CLOSE_MATERIAL_FILTER:
        return { ...state, showFilter: false };
      case constants.OPEN_MATERIAL_FILTER:
        return { ...state, showFilter: true };
      case constants.SEARCH_MATERIAL:
        return { ...state, pagingInfo: { ...state.pagingInfo, ...action.payload.pagingInfo } };
      case constants.MATERIAL_SELECT:
        const selectedMaterialId = action.payload.id;
        if (state.pagingInfo.selected.find(t => t === selectedMaterialId)) {
          state.pagingInfo.selected = state.pagingInfo.selected.filter(
            t => t !== selectedMaterialId
          );
        } else {
          state.pagingInfo.selected.push(selectedMaterialId);
        }
        return {
          ...state,
          pagingInfo: { ...state.pagingInfo, selected: state.pagingInfo.selected.concat() },
        };
      case constants.MATERIAL_SELECT_ALL:
        return {
          ...state,
          pagingInfo: { ...state.pagingInfo, selected: state.pagingInfo.records.concat() },
        };
      case constants.MATERIAL_SET_SEARCH_RESULT:
        let materialPagingInfo = state.pagingInfo;
        materialPagingInfo = {
          ...materialPagingInfo,
          totalRecords: action.payload.total,
          records: action.payload.data,
          totalPages: helper.getTotalPage(action.payload.total, materialPagingInfo.pageSize),
        };
        return { ...state, pagingInfo: materialPagingInfo };
      case constants.MATERIAL_SET_ALL_SEARCH_RESULT:
        state.pagingInfo.allRecords = action.payload.data;
        return { ...state, pagingInfo: { ...state.pagingInfo } };
      case constants.REMOVE_SELECTED_MATERIALS:
        const removedMaterials = action.payload.data as Array<number>;
        const selectedMaterials = state.pagingInfo.selected
          .filter(t => {
            const currentItem = removedMaterials.find(removed => removed === t);
            if (currentItem) {
              return false;
            }
            return true;
          })
          .filter(t => t);
        return { ...state, pagingInfo: { ...state.pagingInfo, selected: selectedMaterials } };
      case constants.REMOVE_ALL_SELECTED_MATERIALS:
        return { ...state, pagingInfo: { ...state.pagingInfo, selected: [] } };
      case constants.SET_SELECTED_MATERIALS:
        const { data } = action.payload;
        state.pagingInfo.selected.push(...data);

        const selectedData = new Set(state.pagingInfo.selected);

        return {
          ...state,
          pagingInfo: { ...state.pagingInfo, selected: Array.from(selectedData) },
        };
      default:
        return state;
    }
  },
  uploadMaterial: (
    state: UploadMaterialModal = { showModal: false, isUploading: false },
    action
  ) => {
    switch (action.type) {
      case constants.TOGGLE_UPLOAD_MATERIAL_MODAL:
        return {
          ...state,
          showModal: !state.showModal,
        };
      case constants.CREATE_EMBEDDED_VIDEO_MATERIAL:
        return {
          ...state,
          isUploading: true,
        };
      case constants.CREATE_DOCUMENT_MATERIAL:
        return {
          ...state,
          isUploading: true,
        };
      case constants.CREATE_EMBEDDED_VIDEO_MATERIAL_DONE:
        return {
          ...state,
          showModal: false,
          isUploading: false,
        };
      case constants.CREATE_DOCUMENT_MATERIAL_DONE:
        return {
          ...state,
          showModal: false,
          isUploading: false,
        };
      default:
        return state;
    }
  },
  editMaterial: (
    state: EditMaterialModal = { material: null, showModal: false, isUploading: false },
    action
  ) => {
    switch (action.type) {
      case constants.TOGGLE_EDIT_MATERIAL_MODAL:
        return {
          ...state,
          showModal: !state.showModal,
          material: action.payload.material,
        };
      case constants.EDIT_EMBEDDED_VIDEO_MATERIAL:
        return {
          ...state,
          isUploading: true,
        };
      case constants.EDIT_DOCUMENT_MATERIAL:
        return {
          ...state,
          isUploading: true,
        };
      case constants.EDIT_EMBEDDED_VIDEO_MATERIAL_DONE:
        return {
          ...state,
          showModal: false,
          isUploading: false,
          material: null,
        };
      case constants.EDIT_DOCUMENT_MATERIAL_DONE:
        return {
          ...state,
          showModal: false,
          isUploading: false,
          material: null,
        };
      default:
        return state;
    }
  },
  access: (state = accessState, action: any) => {
    switch (action.type) {
      case constants.ACCESS_SET_SEARCH_RESULT:
        let accessPagingInfo = state.pagingInfo;
        accessPagingInfo = {
          ...accessPagingInfo,
          totalRecords: action.payload.total,
          records: action.payload.data,
          totalPages: helper.getTotalPage(action.payload.total, accessPagingInfo.pageSize),
          allRecords: action.payload.data,
        };
        return { ...state, pagingInfo: accessPagingInfo };
      case constants.REMOVE_SELECTED_ACCESS:
        const removedAccess = action.payload.data as Array<number>;
        const selectedAccess = state.pagingInfo.selected
          .filter(t => {
            const currentItem = removedAccess.find(removed => removed === t);
            if (currentItem) {
              return false;
            }
            return true;
          })
          .filter(t => t);
        return { ...state, pagingInfo: { ...state.pagingInfo, selected: selectedAccess } };
      case constants.SET_SELECTED_ACCESS:
        state.pagingInfo.selected.push(...action.payload.data);
        const selectedData = new Set(state.pagingInfo.selected);
        return {
          ...state,
          pagingInfo: { ...state.pagingInfo, selected: Array.from(selectedData) },
        };
      case constants.SET_SEARCH_INPUT_ACCESS:
        return {
          ...state,
          pagingInfo: { ...state.pagingInfo, search: action.payload },
        };
      default:
        return state;
    }
  },
  customEventList: (state = customEventState, action: any) => {
    switch (action.type) {
      case constants.GET_CUSTOM_EVENT:
        return [];
      case constants.SET_CUSTOM_EVENT:
        // action.payload.list;
        return {
          ...state,
          records: action.payload.list.list,
          allRecords: action.payload.list.list,
          totalRecords: action.payload.list.count,
        };
      default:
        return state;
    }
  },
});
