// @ts-nocheck
import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { Button, Confirm, Image, Modal } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import noAvatar from '../../assets/images/no-avatar.svg';
import { AdjusterUser } from '../../models/adjuster';
import services from '../../services';
import ErrorLabel from '../error-label/ErrorLabel';
import CustomIconicButton from './../adjuster-list/CustomIconicButton';
import UploadZone from './../file-uploader/UploadZone';
import { MessageSpiner } from './../loading-spinner/MessageSpinner';

interface Props {
  user: AdjusterUser;
  updateList: () => void;
}

interface State {
  showModal: boolean;
  showConfirm: boolean;
  showFile: boolean;
  file: string | ArrayBuffer | null;
  fileName: string;
  fileType: string;
  fileError: boolean;
  fileErrorText: string;
  statesOptions: object[];
}

class UploadFinance extends React.Component<Props, State> {
  public state = {
    showModal: false,
    showConfirm: false,
    statesOptions: [],
    showFile: false,
    file: '',
    fileType: '',
    fileName: '',
    fileError: false,
    fileErrorText: '',
  };

  public fileInputRef = React.createRef();

  public componentDidMount = async () => {
    const request = await services.api.account.getSettingsData('certification');
    if (request.isSuccess) {
      const statesOptions = request.data.list.map((el: any, index: any) => {
        const element = { text: el.name, value: el.name };
        return element;
      });
      this.setState({ statesOptions });
    }
  };

  public handleFileDrop = (files: any) => {
    const file = files[0];
    if (file === undefined) {
      this.setState({ fileError: true });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.setState({
          file: file,
          showFile: true,
          fileType: file.type,
          fileError: false,
          fileName: file.name,
        });
      };
    }
  };

  public closeModal = () => {
    this.setState({
      showModal: false,
      showFile: false,
      file: '',
      fileType: '',
      fileName: '',
    });
  };

  public render() {
    const { user } = this.props;
    const trigger = (
      <Button className="add-admin-button" onClick={() => this.setState({ showModal: true })}>
        Upload
      </Button>
    );
    return (
      <Modal
        size="tiny"
        className="licenses-modal finance-upload-adjuster-modal"
        trigger={trigger}
        open={this.state.showModal}
        closeIcon={<Image src={closeIcon} className="icon" onClick={() => this.closeModal()} />}
      >
        <Modal.Header>Upload Financial Document</Modal.Header>
        <Form
          onValidSubmit={async (model: any) => {
            if (this.state.file === '') {
              this.setState({ fileErrorText: '* Finance file is required.', fileError: true });
              return;
            }

            const response = await trackPromise(
              services.api.finance.uploadFinanceFile(this.state.file, this.props.user.id),
              'upload-finance-adjuster-modal'
            );
            if (response.isSuccess) {
              this.closeModal();
              this.props.updateList();
            } else {
              console.log(response);
              toast.dismiss();
              toast.error(
                response.data.errors ? response.data.errors[0].msg : `Error ${response.status}`
              );
            }
          }}
        >
          <Modal.Content style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
            <MessageSpiner area="upload-finance-adjuster-modal" />
            <div className="input-field-container">
              <div className="input-field-label">Recipient</div>
              <div className="recipient-finance-container">
                <Image src={user.avatar ? user.avatar : noAvatar} avatar={true} />
                {user.preferredName ? user.preferredName : user.firstName} {user.lastName}
              </div>
            </div>
            <div className="input-field-container">
              <div className="input-field-label">File</div>
            </div>
            {!this.state.showFile ? (
              <div>
                <UploadZone onDrop={this.handleFileDrop} accept=".xlsx, .xls" />
                {this.state.fileError && (
                  <ErrorLabel>
                    <div style={{ paddingLeft: '1rem' }}>
                      <div>{this.state.fileErrorText}</div>
                    </div>
                  </ErrorLabel>
                )}
              </div>
            ) : (
              <div className="paperwork-uloaded-file">
                <span className="admin-add-finance-file-name">{this.state.fileName}</span>
                <span>
                  <CustomIconicButton
                    image={closeIcon}
                    popup="Remove"
                    onPress={() => this.setState({ file: '', fileName: '', showFile: false })}
                  />
                </span>
              </div>
            )}
          </Modal.Content>

          <div className="modal-button-footer">
            <Button
              type="button"
              style={{ width: '115px' }}
              className="secondary-button"
              secondary={true}
              onClick={() =>
                this.setState({
                  showModal: false,
                  showFile: false,
                  file: '',
                  fileType: '',
                })
              }
            >
              CANCEL
            </Button>
            <Button style={{ width: '115px' }} primary={true}>
              ADD
            </Button>
          </div>
        </Form>
        <Confirm
          size="tiny"
          closeIcon={
            <Image
              src={closeIcon}
              className="icon"
              onClick={() => this.setState({ showConfirm: false })}
            />
          }
          open={this.state.showConfirm}
          onCancel={() => this.setState({ showConfirm: false })}
          header="Confirm"
          content="Are you sure you want to delete this file?"
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={() =>
            this.setState({ showFile: false, file: '', fileType: '', showConfirm: false })
          }
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </Modal>
    );
  }
}

export default UploadFinance;
