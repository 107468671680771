// @ts-nocheck
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Image, Modal, Pagination, Segment } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import { adjustersActions } from '../../features/adjusters';
import { standbyActions } from '../../features/standby';
import services from '../../services';
import * as helper from '../../utils/helper';
import { LoadingSpiner } from '../loading-spinner/LoadingSpinner';
import { MessageSpiner } from '../loading-spinner/MessageSpinner';
import { NoResults } from '../no-results-placeholder/NoResults';
import SendMessageTableAdj from '../standby-layout/modals/SMTableAdj';
import SendMessageTableHeader from '../standby-layout/modals/SMTableHeader';
import SendMessageTableSelected from '../standby-layout/modals/SMTableSelected';

const initialFilter = {
  availableForClaims: '',
  catCity: '',
  catStateProvince: '',
  certification: '',
  city: '',
  commercialTeam: '',
  deployed: '',
  equipment: '',
  experience: '',
  expertise: '',
  language: '',
  largeLossTeam: '',
  milesAway: '',
  name: '',
  position: '',
  softwareSkill: '',
  state: '',
  xactnetAddress: '',
  zip: '',
  authorityLevelFrom: '',
  authorityLevelTo: '',
};

interface Props {
  show: boolean;
  showCustomEventsModal: typeof standbyActions.showCustomEventsModal;
  getInviteModalUsers: typeof standbyActions.getInviteModalUsers;
  setInviteModalTab: typeof standbyActions.setInviteModalTab;
  setActivePageAdj: typeof standbyActions.setActivePageAdj;
  setTempMsgObj: typeof standbyActions.setTempMsgObj;
  resetSelectedUsers: typeof standbyActions.resetSelectedUsers;
  setInviteMode: typeof standbyActions.setInviteMode;
  setPageSize: typeof standbyActions.setPageSize;
  getAdminAdjusters: typeof adjustersActions.getAdminAdjusters;
  adminAdjusters: object[];
  pageSize: number;
  activeStandbyTwilio: any;
  adjusters: object[];
  selectedAdjusters: object[];
  totalAdjusters: number;
  activeStandbyId: any;
  activePageAdj: number;
  standby: object | boolean;
  standbyAdjusters: object[];
  global: boolean;
  inviteModalMode: string;
  updateData: () => void;
}

interface State {
  activeTab: string;
  totalPagesAdj: number;
  adjustersFilter: any;
  searchAdjustersValue: string;
  loading: boolean;
  selectedTabs: any;
}

class CustomEventsModal extends React.Component<Props, State> {
  public state = {
    activeTab: 'Active',
    totalPagesAdj: 0,
    adjustersFilter: {
      ...initialFilter,
    },
    searchAdjustersValue: '',
    selectedTabs: [],
    loading: false,
  };

  public handleTab = (name: string) => {
    if (name === 'Selected') {
      // console.log();
    } else if (name === 'Admins') {
      this.props.setInviteModalTab('Admins');
    } else if (name === 'All') {
      this.props.setInviteModalTab('All');
    } else {
      this.props.setInviteModalTab(name);
    }
    this.setState({ activeTab: name });
  };

  public componentDidMount = () => {
    this.setState({
      totalPagesAdj: helper.getTotalPage(this.props.totalAdjusters, this.props.pageSize),
    });
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.totalAdjusters !== this.props.totalAdjusters) {
      this.setState({
        totalPagesAdj: helper.getTotalPage(this.props.totalAdjusters, this.props.pageSize),
      });
    }
    if (prevProps.pageSize !== this.props.pageSize) {
      this.setState({
        totalPagesAdj: helper.getTotalPage(this.props.totalAdjusters, this.props.pageSize),
      });
    }
  };

  public handleFilter = (filter: any) => {
    this.setState({ adjustersFilter: { ...filter } });
  };

  public handleClose = () => {
    if (this.state.loading) {
      return;
    }
    this.props.showCustomEventsModal(false, null);
    this.props.resetSelectedUsers();
    this.props.setActivePageAdj(1);
    this.props.setPageSize(15);
    this.setState({ activeTab: 'Active' }, () => this.props.setInviteModalTab('Active'));
  };

  public getFilterModel = () => {
    const dataModel: any = Object.assign({}, { ...this.state.adjustersFilter });
    const multipleFieldsFilters = [
      'state',
      'licenseStates',
      'certificationTypes',
      'experience',
      'position',
      'equipment',
      'expertise',
      'softwareSkill',
      'catStateProvince',
      'language',
      'registrationType',
    ];

    for (const key in dataModel) {
      if (dataModel[key] === '') {
        delete dataModel[key];
      } else if (dataModel[key] && multipleFieldsFilters.includes(key)) {
        dataModel[key] = dataModel[key].split(',');
      } else if (key === 'standbyListDeployments' || key === 'eventDeployments') {
        dataModel[key] = dataModel[key].map((f: any) => (f === 'exclude' ? 'exclude' : f.id));
      }
    }

    if (this.state.activeTab === 'Admins') {
      dataModel.isAdmin = true;
    } else if (this.state.activeTab !== 'All') {
      dataModel.isAdmin = false;
    }

    return dataModel;
  };

  public handlePaginationAdj = (e: any, data: any) => {
    const { standby = {} } = this.props;
    this.props.setActivePageAdj(data.activePage);
    const dataModel = this.getFilterModel();
    this.props.getInviteModalUsers(dataModel, standby.id);
  };

  public setSearchAdjustersValue = (value: string) => {
    this.setState({ searchAdjustersValue: value });
  };

  public handleSubmit = async () => {
    const { eventId, selectedAdjusters } = this.props;
    const response = await services.api.admin.deployAdjustersToEvent(
      eventId,
      selectedAdjusters.map(a => a.id)
    );
    if (response.isSuccess) {
      this.handleClose();
      toast.success('Selected adjuster(s) was successfully deployed!');
    }
  };

  public handleSelectedTabs = (selectedTabs: any) => this.setState({ selectedTabs });

  public render() {
    const { activeTab, totalPagesAdj, loading, selectedTabs, adjustersFilter } = this.state;
    const { activePageAdj } = this.props;
    return (
      <Modal
        className="standby-invite-modal"
        open={this.props.show}
        closeIcon={<Image src={closeIcon} className="icon" onClick={this.handleClose} />}
      >
        <Modal.Header>Choose Adjusters</Modal.Header>
        <Modal.Content>
          <Segment loading={loading} disabled={loading} basic={true}>
            <MessageSpiner area="message-sending-area" />
            <SendMessageTableHeader
              global={this.props.global}
              standby={this.props.standby}
              getUsers={this.props.getInviteModalUsers}
              changeFilter={this.handleFilter}
              getTab={this.handleTab}
              resetPaginationAdj={this.props.setActivePageAdj}
              selected={this.props.selectedAdjusters.length}
              inviteModalMode={this.props.inviteModalMode}
              setPageSize={this.props.setPageSize}
              pageSize={this.props.pageSize}
              searchAdjustersValue={this.setSearchAdjustersValue}
            />
            {activeTab !== 'Selected' && (
              <div className="adjusters-table-invite-modal">
                {this.props.adjusters.length === 0 ? (
                  <NoResults />
                ) : (
                  <div>
                    <LoadingSpiner area="invite-modal-users" />
                    <SendMessageTableAdj
                      activeTab={activeTab}
                      selectedTabs={selectedTabs}
                      handleSelectedTabs={this.handleSelectedTabs}
                      adjustersFilter={adjustersFilter}
                      getFilterModel={this.getFilterModel}
                      // standbyId={this.props.standby.id}
                      adjusters={this.props.adjusters}
                      selectedTab={false}
                    />
                  </div>
                )}
                {totalPagesAdj > 1 && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <Pagination
                      totalPages={totalPagesAdj}
                      boundaryRange={1}
                      activePage={activePageAdj}
                      ellipsisItem={null}
                      firstItem={null}
                      lastItem={null}
                      prevItem={
                        activePageAdj === 1
                          ? false
                          : {
                              'aria-label': 'Previous item',
                              content: 'PREVIOUS',
                            }
                      }
                      nextItem={
                        activePageAdj === totalPagesAdj
                          ? false
                          : {
                              'aria-label': 'Next item',
                              content: 'NEXT',
                            }
                      }
                      siblingRange={1}
                      onPageChange={this.handlePaginationAdj}
                    />
                  </div>
                )}
              </div>
            )}
            {activeTab === 'Selected' && (
              <SendMessageTableSelected searchAdjustersValue={this.state.searchAdjustersValue} />
            )}
          </Segment>
        </Modal.Content>
        <div className="modal-button-footer">
          <Button
            type="button"
            disabled={loading}
            style={{ width: '115px' }}
            className="secondary-button"
            secondary={true}
            onClick={this.handleClose}
          >
            CANCEL
          </Button>
          <Button
            style={{ width: '115px' }}
            form="send-message-form"
            disabled={loading}
            loading={loading}
            primary={true}
            onClick={this.handleSubmit}
          >
            DEPLOY
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  activeStandbyId: state.standby.standby.activeStandbyId,
  show: state.standby.standby.showCustomEventsModal,
  eventId: state.standby.standby.customEventModalId,
  adjusters: state.standby.inviteModal.adjusters,
  selectedAdjusters: state.standby.inviteModal.selectedAdjusters,
  totalAdjusters: state.standby.inviteModal.totalAdjusters,
  activePageAdj: state.standby.inviteModal.activePageAdj,
  standbyAdjusters: state.standby.adjusters.adjusters,
  activeStandbyTwilio: state.standby.inviteModal.activeStandbyTwilio,
  pageSize: state.standby.inviteModal.pageSize,
  inviteModalMode: state.standby.standby.inviteModalMode,
  adminAdjusters: state.adjusters.adjusters.adminAdjusters,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showCustomEventsModal: standbyActions.showCustomEventsModal,
      getInviteModalUsers: standbyActions.getInviteModalUsers,
      setInviteModalTab: standbyActions.setInviteModalTab,
      setActivePageAdj: standbyActions.setActivePageAdj,
      setTempMsgObj: standbyActions.setTempMsgObj,
      resetSelectedUsers: standbyActions.resetSelectedUsers,
      setInviteMode: standbyActions.setInviteMode,
      setPageSize: standbyActions.setPageSize,
      getAdminAdjusters: adjustersActions.getAdminAdjusters,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomEventsModal);
