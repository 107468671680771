// @ts-nocheck
import React from 'react';
import { Button, Confirm, Image, Popup } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import downloadIcon from '../../assets/images/Blue/download.svg';
import * as datesHelper from '../../utils/datesHelper';
import { sizeToHumanReadable } from '../../utils/helper';

interface Props {
  item: object;
  editResume?: (show: boolean, id?: number) => void;
  deleteResume?: (id: number) => void;
  id: number;
  download: (file: any) => void;
}

interface State {
  showConfirm: boolean;
}

class ResumeItem extends React.Component<Props, State> {
  public state = {
    showConfirm: false,
  };

  public render() {
    const { item } = this.props;
    return (
      <>
        <div className="license-item-container">
          <div className="licenses-file-preview">
            {item.file.type === 'application/pdf' ? (
              <div className="pdf-file-container">PDF</div>
            ) : (
              <div className="doc-file-container">DOC</div>
            )}
          </div>
          <div className="license-item-info">
            <div
              style={{ fontSize: '16px', fontWeight: 'bold' }}
              dangerouslySetInnerHTML={{ __html: item.name }}
            />
            <div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontSize: '13px' }}>Uploaded Date</div>
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {datesHelper.parseDateFromApi(item.createdAt)}
                </div>
              </div>
            </div>
            <div style={{ fontSize: '13px' }}>
              {sizeToHumanReadable(item.fileSize ? item.fileSize : item.file.size)}
            </div>
          </div>
          <div className="buttons-container">
            <Popup
              trigger={
                <Button
                  className="iconic-button-custom"
                  onClick={() => this.props.download(this.props.item.file)}
                >
                  <Image src={downloadIcon} />
                </Button>
              }
              size="mini"
              basic={true}
              content="Download"
            />
          </div>
        </div>
        <Confirm
          size="tiny"
          closeIcon={
            <Image
              src={closeIcon}
              className="icon"
              onClick={() => this.setState({ showConfirm: false })}
            />
          }
          open={this.state.showConfirm}
          onCancel={() => this.setState({ showConfirm: false })}
          header="Confirm"
          content={`Are you sure you want to delete this resume?`}
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={() => {
            this.props.deleteResume(item.id);
            this.setState({ showConfirm: false });
          }}
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </>
    );
  }
}

export default ResumeItem;
