// @ts-nocheck
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Image, Popup, Table } from 'semantic-ui-react';
import checkIconB from '../../../assets/images/Blue/check.svg';
import noAvatarIcon from '../../../assets/images/no-avatar.svg';
import checkIconW from '../../../assets/images/White/check.svg';
import { trainingCenterActions } from '../../../features/training-center';
import { PaginationInfo } from '../../../features/training-center/trainingCenterReducer';
import { IUser } from '../../../models';
import * as helper from '../../../utils/helper';
import statesUS from '../../../utils/statesUSbyKey';

interface Props {
  setAssignedAdjusters: typeof trainingCenterActions.setAssignedAdjusters;
  removeAssignedAdjusters: typeof trainingCenterActions.removeAssignedAdjusters;
  setAssignedCustomEvents: typeof trainingCenterActions.setAssignedCustomEvents;
  removeAssignedCustomEvents: typeof trainingCenterActions.removeAssignedCustomEvents;
  setAllSearchResult: typeof trainingCenterActions.setAllSearchResult;
  getPagingInfo: () => PaginationInfo;
  adjusters: object[];
  selectedAdjusters: number[];
  selectedCustomEvents: number[];
  user: IUser;
  activeTab: 'Adjusters' | 'CustomEvents';
}

interface State {
  adjusters: object[];
  selectedAdjusters: any[];
  prevSelectedAdjusters: { [tabName: string]: number[] };
}

class AssignAccessTableAdj extends React.Component<Props, State> {
  public state = {
    adjusters: [],
  };

  public componentDidMount = async () => {
  };
  public handleSelection = (adjuster: any) => {

    if (this.props.activeTab === 'Adjusters') {
      const isSelected = this.props.selectedAdjusters.find(
        el => el === adjuster.id
      )
      if (isSelected) {
        this.props.removeAssignedAdjusters(adjuster.id);
      }
      else {
        this.props.setAssignedAdjusters(adjuster.id);
      }
    }
    if (this.props.activeTab === 'CustomEvents') {
      const isSelected = this.props.selectedCustomEvents.find(
        el => el === adjuster.id
      )
      if (isSelected) {
        this.props.removeAssignedCustomEvents(adjuster.id);
      }
      else {
        this.props.setAssignedCustomEvents(adjuster.id);
      }
    }
  };

  public selectAll = async () => {
    const pagingInfo = this.props.getPagingInfo();
    if (!this.isSelectAll()) {
      this.assignRecords(pagingInfo.allRecords);
      return
    }
    this.removeRecords(pagingInfo.allRecords);
  };

  private assignRecords(ids: number[]) {
    const {
      setAssignedAdjusters,
      setAssignedCustomEvents,
      activeTab,
    } = this.props;
    if (activeTab === 'Adjusters') {
      setAssignedAdjusters(ids);
      return;
    }
    setAssignedCustomEvents(ids);
  }

  private removeRecords(ids: number[]) {
    const {
      removeAssignedAdjusters,
      removeAssignedCustomEvents,
      activeTab,
    } = this.props;
    if (activeTab === 'Adjusters') {
      removeAssignedAdjusters(ids);
      return;
    }
    removeAssignedCustomEvents(ids);
  }

  public isSelectAll() {
    const pagingInfo = this.props.getPagingInfo();

    const selectedRecords = this.props.activeTab === 'Adjusters' ? this.props.selectedAdjusters : this.props.selectedCustomEvents;
    if (selectedRecords.length === 0 || selectedRecords.length < pagingInfo.allRecords.length) {
      return false;
    }

    const parentRecords = selectedRecords.length > pagingInfo.allRecords.length ? selectedRecords : pagingInfo.allRecords;
    const childRecords = parentRecords === selectedRecords ? pagingInfo.allRecords : selectedRecords;

    for (const selectedId of childRecords) {
      const foundRecord = parentRecords.find(t => t === selectedId);
      if (!foundRecord) {
        return false;
      }
    }

    return true;
  }

  public renderSelectAllCheckbox = () => {
    const isAllSelected = this.isSelectAll();
    const pagingInfo = this.props.getPagingInfo();
    const { totalRecords } = pagingInfo;
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <div style={{ marginRight: '20px' }}>Total: {totalRecords}</div>
        <Popup
          trigger={
            <Button
              type="button"
              className={
                isAllSelected
                  ? 'check-button active iconic-button-custom'
                  : 'check-button iconic-button-custom'
              }
              onClick={this.selectAll}
            >
              <Image src={isAllSelected ? checkIconW : checkIconB} />
            </Button>
          }
          basic={true}
          size="mini"
          content="Select All"
        />
      </div>
    );
  };

  public renderTableHeader() {
    if (this.props.activeTab === 'Adjusters') {
      return (<Table.Header>
        <Table.Row>
          <Table.HeaderCell className="table-avatar" />
          <Table.HeaderCell>Last Name</Table.HeaderCell>
          <Table.HeaderCell>First Name</Table.HeaderCell>
          <Table.HeaderCell>City</Table.HeaderCell>
          <Table.HeaderCell>State</Table.HeaderCell>
          <Table.HeaderCell>Mobile</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Compass Email</Table.HeaderCell>
          <Table.HeaderCell>Last Updated On</Table.HeaderCell>
          <Table.HeaderCell style={{ width: 100 }}>
            {this.renderSelectAllCheckbox()}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>);
    }
    return (<Table.Header>
      <Table.Row>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell style={{ width: 100 }}>
          {this.renderSelectAllCheckbox()}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>);
  }

  public renderAdjusterTable(records: Array<any>) {
    const { selectedAdjusters } = this.props;
    return (<Table.Body>
      {records &&
        records.map((adjuster: any, i: number) => (
          <Table.Row key={adjuster.id}>
            <Table.Cell className="table-avatar">
              <Image
                circular={true}
                src={adjuster.avatar ? adjuster.avatar : noAvatarIcon}
                avatar={true}
              />
            </Table.Cell>
            <Table.Cell className="table-name">{adjuster.lastName}</Table.Cell>
            <Table.Cell className="table-name">
              {adjuster.preferredName ? adjuster.preferredName : adjuster.firstName}
            </Table.Cell>
            <Table.Cell className="table-city">{adjuster.city}</Table.Cell>
            <Table.Cell className="table-city">{statesUS[adjuster.state]}</Table.Cell>
            <Table.Cell className="table-phone">
              {helper.formatPhone(adjuster.mobilePhone, '(###) ###-####')}
            </Table.Cell>
            <Table.Cell className="table-email">{adjuster.email}</Table.Cell>
            <Table.Cell className="table-email">{adjuster.compassEmail}</Table.Cell>
            <Table.Cell className="table-date">
              {moment(adjuster.updatedAt).format('MM/DD/YYYY, hh:mm A')}
            </Table.Cell>
            <Table.Cell className="table-action">
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Popup
                  trigger={
                    <Button
                      type="button"
                      className={
                        selectedAdjusters.find(
                          el => el === adjuster.id
                        ) ? 'check-button active iconic-button-custom' : 'check-button iconic-button-custom'
                      }
                      onClick={() => this.handleSelection(adjuster)}
                    >
                      <Image
                        src={
                          selectedAdjusters.find(
                            el => el === adjuster.id
                          )
                            ? checkIconW
                            : checkIconB
                        }
                      />
                    </Button>
                  }
                  basic={true}
                  size="mini"
                  content={
                    selectedAdjusters.find(
                      el => el === adjuster.id
                    )
                      ? "Deselect"
                      : "Select"
                  }
                />
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
    </Table.Body>);
  }

  public renderCustomEventTable(records: Array<any>) {
    const { selectedCustomEvents } = this.props;
    return (<Table.Body>
      {records &&
        records.map((item: any, i: number) => (
          <Table.Row key={item.id}>
            <Table.Cell className="table-name">{item.name}</Table.Cell>
            <Table.Cell className="table-action">
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Popup
                  trigger={
                    <Button
                      type="button"
                      className={
                        selectedCustomEvents.find(
                          el => el === item.id
                        ) ? 'check-button active iconic-button-custom' : 'check-button iconic-button-custom'
                      }
                      onClick={() => this.handleSelection(item)}
                    >
                      <Image
                        src={
                          selectedCustomEvents.find(
                            el => el === item.id
                          )
                            ? checkIconW
                            : checkIconB
                        }
                      />
                    </Button>
                  }
                  basic={true}
                  size="mini"
                  content={
                    selectedCustomEvents.find(
                      el => el === item.id
                    )
                      ? "Deselect"
                      : "Select"
                  }
                />
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
    </Table.Body>);
  }
  public renderTableBody(records: Array<any> = []) {
    if (this.props.activeTab === 'Adjusters') {
      return this.renderAdjusterTable(records);
    }
    if (this.props.activeTab === 'CustomEvents') {
      return this.renderCustomEventTable(records);
    }
  }

  public render() {
    const pagingInfo = this.props.getPagingInfo();
    const { records } = pagingInfo;
    return (
      <Table
        compact={true}
        striped={true}
        stackable={true}
        singleLine={true}
        className="data-table adjusters-table"
      >
        {this.renderTableHeader()}
        {this.renderTableBody(records)}
      </Table>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  selectedAdjusters: state.trainingCenter.assignAccess.selectedAdjusters,
  selectedCustomEvents: state.trainingCenter.assignAccess.selectedCustomEvents,
  adjustersCount: state.standby.inviteModal.totalAdjusters,
  activeTab: state.trainingCenter.assignAccess.activeTab,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setAssignedAdjusters: trainingCenterActions.setAssignedAdjusters,
      removeAssignedAdjusters: trainingCenterActions.removeAssignedAdjusters,
      setAssignedCustomEvents: trainingCenterActions.setAssignedCustomEvents,
      removeAssignedCustomEvents: trainingCenterActions.removeAssignedCustomEvents,
      setAllSearchResult: trainingCenterActions.setAllSearchResult,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignAccessTableAdj);
