// @ts-nocheck
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Image, Modal } from 'semantic-ui-react';
import closeIcon from '../../../assets/images/Blue/cancel.svg';
import messageIcon from '../../../assets/images/Other/message-img.svg';
import { standbyActions } from '../../../features/standby';

interface Props {
  showInviteMessage: boolean;
  showInviteMessageFunc: typeof standbyActions.showInviteMessage;
  setInviteMode: typeof standbyActions.setInviteMode;
  showInviteModal: typeof standbyActions.showInviteModal;
  standby: object;
}

interface State {}

class StandbyCreatedMessage extends React.Component<Props, State> {
  public state = {};

  public render() {
    return (
      <Modal
        size="tiny"
        className="standby-created-message"
        open={this.props.showInviteMessage}
        closeIcon={
          <Image
            src={closeIcon}
            className="icon"
            onClick={() => this.props.showInviteMessageFunc(false)}
          />
        }
      >
        <Modal.Header>Send Message</Modal.Header>
        <Modal.Content>
          <div className="sbl-created-modal-content">
            <div className="sbl-modal-icon-part">
              <Image src={messageIcon} />
            </div>
            <div className="sbl-modal-text-part">
              Standby list {this.props.standby.title} was successfully created. Please invite
              adjusters.
              <Button
                primary={true}
                onClick={() => {
                  this.props.showInviteMessageFunc(false);
                  this.props.showInviteModal(true, 'create');
                }}
              >
                Invite Adjusters
              </Button>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  showInviteMessage: state.standby.standby.showInviteMessage,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setInviteMode: standbyActions.setInviteMode,
      showInviteMessageFunc: standbyActions.showInviteMessage,
      showInviteModal: standbyActions.showInviteModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandbyCreatedMessage);
