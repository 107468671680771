/* eslint-disable */
// @ts-nocheck
import moment from 'moment';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Image, Label, Popup, Table } from 'semantic-ui-react';
import noteIcon from '../../assets/images/Blue/add-note.svg';
import arrowIcon from '../../assets/images/Blue/arrow-r.svg';
import gearIcon from '../../assets/images/Blue/gear.svg';
import messageIcon from '../../assets/images/Blue/message.svg';
import approvedPaperworkIcon from '../../assets/images/icn-paperwork-approved.svg';
import pendingPaperworkIcon from '../../assets/images/icn-paperwork-pending.svg';
import noAvatarIcon from '../../assets/images/no-avatar.svg';
import { accountActions } from '../../features/account';
import { IUser } from '../../models';
import services from '../../services';
import * as helper from '../../utils/helper';
import statesUS from '../../utils/statesUSbyKey';
import SetEmailModal from '../adjuster-profile/SetEmailModal';
import ViewGuestAdjusterModal from '../standby-layout/modals/ViewGuestAdjuster';
import AddNoteModal from './AddNoteModal';
import CustomIconicButton from './CustomIconicButton';
import StatusActions from './StatusActions';

interface Props {
  fetchAdjusterDataAttempt: typeof accountActions.fetchAdjusterDataAttempt;
  fetchList: (params?: any) => void;
  filter: any;
  adjusters: object[];
  user: IUser;
  page: number;
  adjustersCount: number;
  activeTab: string;
}

interface State {
  adjusters: object[];
  showNoteModal: boolean;
  showDeleteConfirm: boolean;
  activeUserId: any;
  showEmailModal: boolean;
  showInfoModal: boolean;
  selectedAdjuster: any;
}

class AdjustersListTable extends React.Component<Props, State> {
  public state = {
    adjusters: [],
    showNoteModal: false,
    activeUserId: 0,
    showEmailModal: false,
    showDeleteConfirm: false,
    showInfoModal: false,
    selectedAdjuster: null,
  };

  public componentDidMount = async () => {
    this.setState({ adjusters: this.props.adjusters });
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.adjusters !== this.props.adjusters) {
      this.setState({ adjusters: [] }, () => this.setState({ adjusters: this.props.adjusters }));
    }
  };

  public handleViewProfile = () => {
    const path = `/adjusters/${this.state.adjusters[this.state.activeUserId].id}${window.location.hash}`;
    services.router.gotoWithState(path, { activePage: this.props.page });
  };

  public handleStatus = async (status: number, adjusterId: any, name: string) => {
    if (status === 1) {
      const response = await services.api.adjuster.changeAdjusterStatus(adjusterId, 'Active');
      if (response.isSuccess) {
        this.setState({ showEmailModal: false });
        this.props.fetchList(this.props.filter);
        toast.dismiss();
        toast.success(`Adjuster ${name} was successfully activated!`);
      } else {
        toast.dismiss();
        toast.error(response.data ? response.data.errors[0].msg : `Error ${response.status}`);
      }
    }
  };

  public showNoteModal = (status: boolean) => {
    this.setState({ showNoteModal: status });
  };

  public showEmailModal = (status: boolean) => {
    this.setState({ showEmailModal: status });
  };

  public showInfoModal = (status: boolean, selectedAdjuster: any) => {
    const { adjusters } = this.state;
    this.setState({ showInfoModal: status, selectedAdjuster });
  };

  public renderActions = (adjuster: any, i: number) => {
    const { adjusters = [], activeUserId } = this.state;
    const { user, activeTab, itemsPerPageCount = 20 } = this.props;

    console.log({ activeUserId, user, adjusters, adjuster });

    if (activeTab === 'QuickApplied') {
      adjuster.status = 'Quick';
      return (
        <Table.Cell className="table-action">
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {user.role !== 'Read Only Office Admin' && (
              <StatusActions
                trigger={
                  <CustomIconicButton
                    onPress={() => this.setState({ activeUserId: adjuster.id })}
                    popup={'Actions'}
                    image={gearIcon}
                  />
                }
                user={adjuster}
                showEmailModal={this.showEmailModal}
                callback={this.props.fetchList}
                activate={this.handleStatus}
                filter={this.props.filter}
              />
            )}
            <CustomIconicButton
              onPress={() => this.showInfoModal(true, adjusters[i])}
              popup={'View Profile'}
              image={arrowIcon}
            />
          </div>
        </Table.Cell>
      );
    } else {
      return (
        <Table.Cell className="table-action">
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Popup
              trigger={
                <Image
                  src={
                    adjuster.allPaperworkCompleted ? approvedPaperworkIcon : pendingPaperworkIcon
                  }
                  style={{ marginRight: '1rem' }}
                />
              }
              basic={true}
              size="mini"
              content={`Paperwork ${adjuster.allPaperworkCompleted ? 'Completed' : 'Pending'}`}
            />
            {user.role !== 'Read Only Office Admin' && (
              <StatusActions
                trigger={
                  <CustomIconicButton
                    onPress={() => this.setState({ activeUserId: i })}
                    popup={'Actions'}
                    image={gearIcon}
                  />
                }
                user={adjusters[activeUserId]}
                showEmailModal={this.showEmailModal}
                callback={this.props.fetchList}
                activate={this.handleStatus}
                filter={this.props.filter}
              />
            )}
            {user.role !== 'Read Only Office Admin' && (
              <CustomIconicButton
                onPress={() => this.setState({ showNoteModal: true, activeUserId: i })}
                popup={'Add Note'}
                image={noteIcon}
              />
            )}
            {user.role !== 'Read Only Office Admin' && (
              <div className="message-avatar-container">
                <CustomIconicButton
                  popup={'Send Message'}
                  image={messageIcon}
                  link={{
                    hash: window.location.hash,
                    pathname: `/messages/room/${adjuster.id}`,
                    state: {
                      activePage: this.props.page,
                      itemsPerPageCount,
                      prevPath: '/adjusters',
                    },
                  }}
                />
                {adjuster.hasUnreadMessages && (
                  <Label color="red" floating={true} circular={true} empty={true} />
                )}
              </div>
            )}
            <CustomIconicButton
              onPress={() => this.setState({ activeUserId: i }, this.handleViewProfile)}
              popup={'View Profile'}
              image={arrowIcon}
              link={{
                hash: window.location.hash,
                pathname: `/adjusters/${adjuster.id}`,
                state: { activePage: this.props.page, itemsPerPageCount },
              }}
            />
          </div>
        </Table.Cell>
      );
    }
  };
  public render() {
    const { adjusters = [], activeUserId } = this.state;
    const { adjustersCount, activeTab } = this.props;
    const isQuick = activeTab === 'QuickApplied';

    console.log('adjusters', adjusters);

    return (
      <Table
        compact={true}
        striped={true}
        stackable={true}
        singleLine={true}
        className="data-table adjusters-table"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="table-avatar" />
            <Table.HeaderCell style={{ width: '60px' }}>First Name</Table.HeaderCell>
            {isQuick && <Table.HeaderCell>Middle Name</Table.HeaderCell>}
            <Table.HeaderCell style={{ width: '150px' }}>Last Name</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '150px' }}>City</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '100px' }}>State</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '120px' }}>Mobile</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '250px' }}>Email</Table.HeaderCell>
            {!isQuick && (
              <Table.HeaderCell style={{ width: '200px' }}>Compass Email</Table.HeaderCell>
            )}
            <Table.HeaderCell>Last Updated On</Table.HeaderCell>
            <Table.HeaderCell style={{ paddingLeft: 20 }}>Total: {adjustersCount}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {adjusters &&
            adjusters.map((adjuster: any, i: number) => (
              <Table.Row key={adjuster.id}>
                <Table.Cell className="table-avatar">
                  <Image
                    circular={true}
                    src={adjuster.avatar ? adjuster.avatar : noAvatarIcon}
                    avatar={true}
                  />
                </Table.Cell>
                <Table.Cell>
                  {/* {adjuster.firstName} */}
                  {helper.truncateString(adjuster.firstName, 20)}
                </Table.Cell>
                {isQuick && (
                  <Table.Cell className="table-middle">
                    {helper.truncateString(adjuster.middleName, 20)}
                  </Table.Cell>
                )}
                <Table.Cell>{helper.truncateString(adjuster.lastName, 20)}</Table.Cell>

                <Table.Cell className="table-city">{adjuster.city}</Table.Cell>
                <Table.Cell className="table-state">
                  {statesUS[adjuster.state] || adjuster.state}
                </Table.Cell>
                <Table.Cell className="table-phone">
                  {helper.formatPhone(adjuster.mobilePhone || adjuster.phone, '(###) ###-####')}
                </Table.Cell>
                <Table.Cell className="table-email ">{adjuster.email}</Table.Cell>
                {!isQuick && (
                  <Table.Cell className="table-email">{adjuster.compassEmail}</Table.Cell>
                )}
                {isQuick ? (
                  <Table.Cell className="table-date">
                    {moment(adjuster.updatedAtSigned).format('MM/DD/YYYY, hh:mm A')}
                  </Table.Cell>
                ) : (
                  <Table.Cell className="table-date">
                    {moment(adjuster.updatedAt).format('MM/DD/YYYY, hh:mm A')}
                  </Table.Cell>
                )}

                {this.renderActions(adjuster, i)}
              </Table.Row>
            ))}
          <ViewGuestAdjusterModal
            show={this.state.showInfoModal}
            adjuster={this.state.selectedAdjuster}
            onClose={() => this.setState({ showInfoModal: false, selectedAdjuster: null })}
          />
          <AddNoteModal
            id={adjusters && adjusters[activeUserId] ? adjusters[activeUserId].id : 0}
            avatar={
              adjusters && adjusters[activeUserId] ? adjusters[activeUserId].avatar : noAvatarIcon
            }
            username={
              adjusters && adjusters[activeUserId]
                ? `${adjusters[activeUserId].firstName} ${adjusters[activeUserId].lastName}`
                : ''
            }
            show={this.state.showNoteModal}
            showNoteModal={this.showNoteModal}
            mode="list"
            update={(id: any) => console.log()}
          />
          <SetEmailModal
            name={adjusters && adjusters[activeUserId] ? adjusters[activeUserId].firstName : ''}
            show={this.state.showEmailModal}
            showModal={this.showEmailModal}
            handleStatus={this.handleStatus}
            id={adjusters && adjusters[activeUserId] ? adjusters[activeUserId].id : 0}
          />
        </Table.Body>
      </Table>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  adjustersCount: state.adjusters.adjusters.count,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchAdjusterDataAttempt: accountActions.fetchAdjusterDataAttempt,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdjustersListTable);
