import { Button, Grid, GridColumn, GridRow, Image, Modal, Select } from "semantic-ui-react";
import closeIcon from '../../../assets/images/Blue/cancel.svg';
import { useEffect, useMemo, useState } from "react";
import { LoadingSpiner } from "../../loading-spinner/LoadingSpinner";
import { updateStandbyListAdjusterAdditionalData } from "../../../services/api/standby";
import { toast } from "react-toastify";

type Data = {
  orientation: string,
  orientationAttended: number,
  licenseConfirmation: boolean,
  xactimateQualificationStatus: boolean,
  profilesInstalled: boolean,
  firstReviewCompleted: boolean,
}

function InfoModal({ id, onModalClose, intialData }: { id: number, onModalClose: (newData?: Data) => {}, intialData: Data }) {
  const [orientation, setOrientation] = useState<string>('');
  const [orientationAttended, setOrientationAttended] = useState<number>(0);
  const [licenseConfirmation, setLicenseConfirmation] = useState<boolean>(false);
  const [xactimateQualificationStatus, setXactimateQualificationStatus] = useState<boolean>(false);
  const [profilesInstalled, setProfilesInstalled] = useState<boolean>(false);
  const [firstReviewCompleted, setFirstReviewCompleted] = useState<boolean>(false);

  const changed = useMemo(() => {
    const modifiedData: Data = {
      orientation,
      orientationAttended,
      licenseConfirmation,
      xactimateQualificationStatus,
      profilesInstalled,
      firstReviewCompleted,
    }

    const check = Object.entries(modifiedData).map(([key, value]) => {
      return intialData[key as keyof Data] === value
    })

    console.log('check', intialData, modifiedData, check)

    return check.includes(false)
  }, [
    intialData,
    orientation,
    orientationAttended,
    licenseConfirmation,
    xactimateQualificationStatus,
    profilesInstalled,
    firstReviewCompleted,
  ])

  const show = Boolean(id)

  function resetData() {
    setOrientation(intialData.orientation)
    setOrientationAttended(intialData.orientationAttended)
    setLicenseConfirmation(intialData.licenseConfirmation)
    setXactimateQualificationStatus(intialData.xactimateQualificationStatus)
    setProfilesInstalled(intialData.profilesInstalled)
    setFirstReviewCompleted(intialData.firstReviewCompleted)
  }

  useEffect(() => {
    resetData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intialData, show])

  async function save() {
    const modifiedData: Data = {
      orientation,
      orientationAttended,
      licenseConfirmation,
      xactimateQualificationStatus,
      profilesInstalled,
      firstReviewCompleted,
    }

    const data = await updateStandbyListAdjusterAdditionalData(id, modifiedData)

     if (data.isError) {
      toast.error(`Failed to update adjuster`);
      return
    }
    onModalClose(modifiedData)
  }

  function onCancel() {
    resetData();
  }

  return (
    <Modal
      className="additional-modal"
      open={show}
      closeIcon={<Image src={closeIcon} className="icon" onClick={() => onModalClose()} />}
      size="small"
    >
      <Modal.Header>Additional Attributes</Modal.Header>
      <Modal.Content>
        <LoadingSpiner area="standby-list-additional-data" />
        <div>
          <Grid verticalAlign='middle' className="gap-1">
            <GridRow>
              <GridColumn width={8}>
                <label>Orientation</label>
              </GridColumn>
              <GridColumn width={8}>
                <Select
                  className="min-height-unset"
                  placeholder='Select orientation'
                  options={Array(15).fill('').map((f, i) => ({ key: `orientation ${i + 1}`, value: `orientation ${i + 1}`, text: `Orientation ${i + 1}` }))}
                  value={orientation}
                  onChange={(e, { value }) => setOrientation(value as string)}
                />
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={8}>
                <label>Orientation Attended</label>
              </GridColumn>
              <GridColumn width={8}>
                <div className="toggle-block adjuster-active" style={{display:  'flex'}}>
                  <Button
                    style={{flex: '1 1 auto'}}
                    type="button"
                    className={orientationAttended === 1 ? 'yes-btn': 'no-btn'}
                    onClick={() => setOrientationAttended(1)}
                  >
                    Yes
                  </Button>
                  <Button
                    style={{flex: '1 1 auto'}}
                    type="button"
                    className={orientationAttended === 0 ? 'yes-btn': 'no-btn'}
                    onClick={() => setOrientationAttended(0)}
                  >
                    No
                  </Button>
                  <Button
                    style={{flex: '1 1 auto'}}
                    type="button"
                    className={orientationAttended === 2 ? 'yes-btn': 'no-btn'}
                    onClick={() => setOrientationAttended(2)}
                  >
                    Exempt
                  </Button>
                </div>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={8}>
                <label>License Confirmation</label>
              </GridColumn>
              <GridColumn width={8}>
                <div className="toggle-block adjuster-active" style={{display:  'flex'}}>
                  <Button
                    style={{flex: '1 1 auto'}}
                    type="button"
                    className={licenseConfirmation ? 'yes-btn': 'no-btn'}
                    onClick={() => setLicenseConfirmation(true)}
                  >
                    Yes
                  </Button>
                  <Button
                    style={{flex: '1 1 auto'}}
                    type="button"
                    className={!licenseConfirmation ? 'yes-btn': 'no-btn'}
                    onClick={() => setLicenseConfirmation(false)}
                  >
                    No
                  </Button>
                </div>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={8}>
                <label>Xactimate Qualification Status</label>
              </GridColumn>
              <GridColumn width={8}>
                <div className="toggle-block adjuster-active" style={{display:  'flex'}}>
                  <Button
                    style={{flex: '1 1 auto'}}
                    type="button"
                    className={xactimateQualificationStatus ? 'yes-btn': 'no-btn'}
                    onClick={() => setXactimateQualificationStatus(true)}
                  >
                    Yes
                  </Button>
                  <Button
                    style={{flex: '1 1 auto'}}
                    type="button"
                    className={!xactimateQualificationStatus ? 'yes-btn': 'no-btn'}
                    onClick={() => setXactimateQualificationStatus(false)}
                  >
                    No
                  </Button>
                </div>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={8}>
                <label>Profiles Installed</label>
              </GridColumn>
              <GridColumn width={8}>
                <div className="toggle-block adjuster-active" style={{display:  'flex'}}>
                  <Button
                    style={{flex: '1 1 auto'}}
                    type="button"
                    className={profilesInstalled ? 'yes-btn': 'no-btn'}
                    onClick={() => setProfilesInstalled(true)}
                  >
                    Yes
                  </Button>
                  <Button
                    style={{flex: '1 1 auto'}}
                    type="button"
                    className={!profilesInstalled ? 'yes-btn': 'no-btn'}
                    onClick={() => setProfilesInstalled(false)}
                  >
                    No
                  </Button>
                </div>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={8}>
                <label>First Review Completed</label>
              </GridColumn>
              <GridColumn width={8}>
                <div className="toggle-block adjuster-active" style={{display:  'flex'}}>
                  <Button
                    style={{flex: '1 1 auto'}}
                    type="button"
                    className={firstReviewCompleted ? 'yes-btn': 'no-btn'}
                    onClick={() => setFirstReviewCompleted(true)}
                  >
                    Yes
                  </Button>
                  <Button
                    style={{flex: '1 1 auto'}}
                    type="button"
                    className={!firstReviewCompleted ? 'yes-btn': 'no-btn'}
                    onClick={() => setFirstReviewCompleted(false)}
                  >
                    No
                  </Button>
                </div>
              </GridColumn>
            </GridRow>
          </Grid>
        </div>
      </Modal.Content>
      <div className="modal-button-footer" style={{ justifyContent: 'space-between' }}>
        {changed ? (
          <>
            <Button
              type="button"
              style={{ width: '115px', height: '32px' }}
              className="secondary-button"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="button"
              style={{ width: '115px', height: '32px' }}
              primary={true}
              onClick={save}
            >
              Save
            </Button>
          </>
        ): (
          <>
            <Button
              type="button"
              style={{ width: '115px', height: '32px' }}
              className="secondary-button"
              onClick={() => onModalClose()}
            >
              Close
            </Button>
          </>
        )}
      </div>
    </Modal>
  )
}

export default InfoModal;