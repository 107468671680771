// @ts-nocheck
import React from 'react';
import Control from 'react-leaflet-control';
import { Button, Checkbox, Form, Image, Input, Popup } from 'semantic-ui-react';
import zipIcon from '../../assets/images/arrow-right-circle.svg';

interface Props {
  zip: string;
  changeCoords: (value: object) => void;
  showRadius: boolean;
  toggleRadius: (show: boolean) => void;
  findCoords: (value: any) => void;
  handleZip: (value: string) => void;
}

class MapZoom extends React.Component<Props> {
  public render() {
    const { toggleRadius, findCoords, handleZip, zip, showRadius } = this.props;
    return (
      <Control position="topright">
        <div>
          <Form onSubmit={findCoords} className="zoom-input-container">
            <Input
              name="zip"
              placeholder="Zoom to Zip Code"
              type="number"
              value={zip}
              onChange={(e: any, { value }: any) => handleZip(value)}
            />
            <Popup
              trigger={
                <Button
                  type="button"
                  disabled={!zip}
                  className="iconic-button-custom"
                  onClick={findCoords}
                  style={{ alignSelf: 'center' }}
                >
                  <Image src={zipIcon} />
                </Button>
              }
              size="mini"
              basic={true}
              content="Zoom to Zip Code"
            />
          </Form>
        </div>
      </Control>
    );
  }
}

export default MapZoom;
