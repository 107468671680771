// @ts-nocheck
import moment from 'moment';
import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { accountActions } from '../../features/account';
import MyTypes from 'MyTypes';
import { standbyActions } from '../../features/standby';
import { connect } from 'react-redux';
import { IUser } from '../../models';
import { deleteNote, deleteStandbyNote } from '../../services/api/standby';
import { Button, Modal, Confirm } from 'semantic-ui-react';
import '../../assets/styles/Staff.css';

interface Props {
  notes: object[];
  adjuster: any;
  handleClose: () => {};
  user: IUser;
  standbyId: number;
  getStandbyAdjusters: any;
}

interface State {
  open: boolean;
  indexId: number | null;
}

class NotePopup extends React.Component<Props, State> {
  state = {
    open: false,
    indexId: null,
  };

  popupRef = React.createRef();

  handleClose = () => {
    this.setState({ open: false });
  };

  handleConfirm = async () => {
    const { indexId } = this.state;
    if (indexId !== null) {
      await deleteStandbyNote(this.props.standbyId, this.props.adjuster.id, indexId);
      const { filter } = JSON.parse(decodeURI(window.location.hash.slice(1) || '{}'));

      this.props.getStandbyAdjusters(
        this.props.standbyId,
        Object.assign({ id: this.props.user.id }, filter)
      );
    }

    this.props.handleClose();

    this.handleClose();
  };

  handleDelete = async (indexId: number) => {
    this.setState({ indexId });
    this.setState({ open: true });
  };

  handleOutsideClick = (e: any) => {
    if (this.state.open) return;
    if (this.popupRef.current && !this.popupRef.current.contains(e.target)) {
      this.props.handleClose();
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  render() {
    const { notes, adjuster } = this.props;

    return (
      <div ref={this.popupRef} className="internal-note-block note-popup">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            width: '100%',
            paddingBottom: 10,
            padding: 5,
          }}
        >
          <Button
            type="button"
            className="iconic-button-custom"
            onClick={() => this.props.handleClose()}
            style={{
              width: '20%',
            }}
          >
            <svg
              width="10px"
              height="10px"
              viewBox="0 0 16 16"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Development"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <g
                  id="Assets"
                  transform="translate(-332.000000, -2946.000000)"
                  stroke="#2259A1"
                  stroke-width="2"
                >
                  <g id="Button-Icons/btn-cancel" transform="translate(329.000000, 2943.000000)">
                    <path d="M4,4 L18,18" id="Path-8"></path>
                    <path d="M18,4 L4,18 L18,4 Z" id="Path-8-Copy"></path>
                  </g>
                </g>
              </g>
            </svg>
          </Button>
        </div>
        {notes.map((el: any, index: any) => (
          <div key={index}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <p className="note-name">{adjuster.firstName + ' ' + adjuster.lastName}</p>
              <div>
                <p className="note-date">{moment(el.date).format('MM/DD/YYYY, hh:mm A')}</p>
                <p className="note-author">{el.author}</p>
              </div>
            </div>
            <div className="note-description">{el.note}</div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
              <Button
                type="button"
                className="iconic-button-custom"
                onClick={() => this.handleDelete(index)}
                style={{
                  width: '20%',
                }}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.93112 19L7.07398 7H14.926L14.0689 19H7.93112Z"
                    stroke="#2259A1"
                    stroke-width="2"
                  />
                  <path d="M4.13281 6H18.1417" stroke="#2259A1" stroke-width="2" />
                  <path d="M7.99891 3H13.9989" stroke="#2259A1" stroke-width="2" />
                </svg>
              </Button>
            </div>
            {index !== notes.length - 1 && <hr />}
          </div>
        ))}
        {/* confirmation modal */}
        <Confirm
          open={this.state.open}
          onCancel={this.handleClose}
          onConfirm={this.handleConfirm}
          header="Confirm"
          content="Are you sure you want to delete this note?"
          cancelButton="NO"
          confirmButton="YES"
          closeOnDimmerClick={false}
          size="tiny"
          className="confirm-modal-custom"
        />
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchAdjusterDataAttempt: accountActions.fetchAdjusterDataAttempt,
      setActiveAdjuster: standbyActions.setActiveAdjuster,
      getStandbyAdjusters: standbyActions.getStandbyAdjusters,
      setActiveStandby: standbyActions.setActiveStandby,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NotePopup);
