// @ts-nocheck
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Table, Image, Popup, Button } from 'semantic-ui-react';
import {
  MaterialModel,
  PaginationInfo,
} from '../../../features/training-center/trainingCenterReducer';
import { IUser } from '../../../models';
import moment from 'moment';
import noAvatarIcon from '../../../assets/images/no-avatar.svg';
import checkIconW from '../../../assets/images/White/check.svg';
import checkIconB from '../../../assets/images/Blue/check.svg';
import CustomIconicButton from '../../adjuster-list/CustomIconicButton';
import downloadIcon from '../../../assets/images/Blue/download.svg';
import editIcon from '../../../assets/images/Blue/edit.svg';
import trashIcon from '../../../assets/images/Blue/trash.svg';
import assignIcon from '../../../assets/images/Blue/assign.svg';
import { uploads } from '../../../config';
import { isNull } from 'lodash';
import './MaterialListView.css';
import { trackPromise } from 'react-promise-tracker';
import services from '../../../services';
import { truncateString } from '../../../utils/helper';

interface Props {
  user: IUser;
  pagingInfo: PaginationInfo;
  selectedMaterials: Array<number>;
  selectMaterial: (material: MaterialModel) => void;
  downloadMaterial: (material: MaterialModel) => void;
  editMaterial: (material: MaterialModel) => void;
  deleteMaterial: (material: MaterialModel) => void;
  assignAccessMaterial: (material: MaterialModel) => void;
  onMaterialClick: (material: MaterialModel) => void;
}

interface State {}

class MaterialListView extends React.Component<Props, State> {
  public state = {};
  public renderSelectCheckBox = (material: MaterialModel) => {
    const { selectedMaterials } = this.props;
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Popup
          trigger={
            <Button
              type="button"
              className={
                selectedMaterials.find(el => el === material.id)
                  ? 'check-button active iconic-button-custom'
                  : 'check-button iconic-button-custom'
              }
              onClick={event => {
                event.stopPropagation();
                this.props.selectMaterial(material);
              }}
            >
              <Image
                src={selectedMaterials.find(el => el === material.id) ? checkIconW : checkIconB}
              />
            </Button>
          }
          basic={true}
          size="mini"
          content={selectedMaterials.find(el => el === material.id) ? 'Deselect' : 'Select'}
        />
      </div>
    );
  };

  public renderTableHeader() {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ width: 50 }} />
          <Table.HeaderCell>Created Date</Table.HeaderCell>
          <Table.HeaderCell>File Name</Table.HeaderCell>
          <Table.HeaderCell>Creator</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );
  }

  public renderTableActions(material: MaterialModel) {
    const { user } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        {!user.isReadOnlyAdmin && (
          <div>
            <CustomIconicButton
              popup={'Delete File'}
              image={trashIcon}
              onPress={(event: any) => {
                event.stopPropagation();
                this.props.deleteMaterial(material);
              }}
            />
          </div>
        )}
        {!user.isReadOnlyAdmin && (
          <div>
            <CustomIconicButton
              popup={'Edit'}
              image={editIcon}
              onPress={(event: any) => {
                event.stopPropagation();
                this.props.editMaterial(material);
              }}
            />
          </div>
        )}
        {material.type === 'document' && (
          <div>
            <CustomIconicButton
              popup={'Download'}
              image={downloadIcon}
              onPress={(event: any) => {
                event.stopPropagation();
                this.props.downloadMaterial(material);
              }}
            />
          </div>
        )}
      </div>
    );
  }

  public renderTableBody(records: Array<MaterialModel> = []) {
    return (
      <Table.Body>
        {records.map((material, i: number) => (
          <Table.Row key={material.id}>
            <Table.Cell
              className="table-date"
              style={{ width: 50 }}
              onClick={() => this.props.onMaterialClick(material)}
            >
              {this.renderSelectCheckBox(material)}
            </Table.Cell>
            <Table.Cell className="table-date" onClick={() => this.props.onMaterialClick(material)}>
              {moment(material.updatedAt).format('MM/DD/YYYY, hh:mm A')}
            </Table.Cell>
            <Table.Cell style={{ width: '60%' }} className="table-email">
              {material.type === 'document' ? (
                <a
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={e => {
                    // e.stopPropagation()
                    this.openFile(material);
                  }}
                >
                  {truncateString(material.name, 80)}
                </a>
              ) : (
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.viewVideoUrl(material.videoUrl);
                  }}
                >
                  {truncateString(material.name, 20)}
                </a>
              )}
            </Table.Cell>
            <Table.Cell onClick={() => this.props.onMaterialClick(material)}>
              <Image
                circular={true}
                src={
                  isNull(material.admin)
                    ? noAvatarIcon
                    : uploads.s3BucketAddress + material.admin.avatar
                }
                avatar={true}
              />
              {!isNull(material.admin)
                ? material.admin.preferredName
                  ? material.admin.preferredName
                  : material.admin.firstName
                : ''}
            </Table.Cell>
            <Table.Cell
              style={{ width: 250 }}
              className="table-actions"
              onClick={() => this.props.onMaterialClick(material)}
            >
              {this.renderTableActions(material)}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    );
  }

  public async openFile(material: MaterialModel) {
    const url = await services.api.file.getDownloadFileUrl(material.file.key);
    if (material.mimetype.includes('image')) {
      // location.assign(url);
      window.open(url, '_blank');
    } else {
      const previewLink = `https://docs.google.com/gview?url=${url}&embedded=true`;
      window.open(previewLink, '_blank');

      // location.assign(previewLink);
    }

    // const previewLink = `https://docs.google.com/gview?url=${url}&embedded=true`;
    // const msviewer= 'https://view.officeapps.live.com/op/embed.aspx?src='+ url
    // window.open(`/preview/?url=${url}&filename=${material.fileName}&mimetype=${material.mimetype}`, '_blank').focus();
    // window.open(previewLink, '_blank').focus();
    // window.open(msviewer, '_blank').focus();
  }

  public viewVideoUrl = (url: string) => {
    window.open(url, '_blank');
  };

  public render() {
    const { pagingInfo } = this.props;
    const { records } = pagingInfo;
    if (!records || records.length === 0) {
      return null;
    }
    return (
      <Table
        compact={true}
        striped={true}
        stackable={true}
        singleLine={true}
        className="data-table materials-table"
      >
        {this.renderTableHeader()}
        {this.renderTableBody(records)}
      </Table>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  pagingInfo: state.trainingCenter.material.pagingInfo,
  selectedMaterials: state.trainingCenter.material.pagingInfo.selected,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MaterialListView);
